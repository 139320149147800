import React from "react";
import Menu from "../components/Menu";
import "../css/urbanist.css";
import "../css/styleHome.css";
import "../css/plugins.css";
import {Helmet} from 'react-helmet';
import Footer from "../components/Footer";

export default function Huissiers() {

    /*return (
        <div>
            <Helmet>
                <title>Découvrez nos huissiers sur facturesimpayees.com</title>
                <meta name="description" content="Rencontrez nos huissiers sur facturesimpayees.com pour gérer efficacement vos factures impayées. Découvrez leur profil et leurs qualifications pour choisir celui qui convient le mieux à votre situation."/>
                <meta name="keywords" content="huissiers, factures impayées, plateforme, profil, qualifications, choix, facturesimpayees.com"/>
            </Helmet>
            <Menu>
                <section className="py-5 text-center container">
                    <div className="row">
                        <div className="d-flex flex-column px-3">
                            <h1 className="hero-main-text mb-2 fs-60">Il ne reste plus qu'à<br /> récupérer votre argent !</h1>
                            <div className={"hero-sub-text mb-2 fs-20"}>Choisissez un huissier dans la liste de nos partenaires.
                            </div>
                        </div>
                    </div>
                </section>
            </Menu>
            <main className={"Main"}>





                <section className="wrapper bg-gradient-primary">
                    <div className="container pt-12 pt-lg-8 pb-14 pb-md-17">
                        <div className="row text-center">
                            <div className="col-lg-8 offset-lg-2">
                                <h2 className="fs-16 text-uppercase text-violet mb-3">Un dernier petit effort !</h2>
                                <h3 className="display-3 mb-10 px-xxl-10">Contactez nos huissiers de justice pour finaliser votre démarche.</h3>
                            </div>

                        </div>

                        <div className="grid">
                            <div className="row isotope gy-6">
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2"></span>
                                            <blockquote className="border-0 mb-0">
                                                <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                                                    auctor. Vestibulum id ligula porta felis euismod semper. Cras justo
                                                    odio dapibus facilisis sociis natoque penatibus.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12"
                                                         src="/assets/img/home/te1.jpg"
                                                         srcSet="/assets/img/home/te1@2x.jpg 2x" alt=""/>
                                                    <div className="info">
                                                        <h5 className="mb-1">Coriss Ambady</h5>
                                                        <p className="mb-0">Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>

                                </div>

                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2"></span>
                                            <blockquote className="border-0 mb-0">
                                                <p>“Fusce dapibus, tellus ac cursus tortor mauris condimentum fermentum
                                                    massa justo sit amet. Vivamus sagittis lacus vel augue laoreet
                                                    rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet
                                                    fermentum. Aenean lacinia bibendum nulla sed.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12"
                                                         src="/assets/img/home/te2.jpg"
                                                         srcSet="/assets/img/home/te2@2x.jpg 2x" alt=""/>
                                                    <div className="info">
                                                        <h5 className="mb-1">Cory Zamora</h5>
                                                        <p className="mb-0">Marketing Specialist</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>

                                </div>

                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2"></span>
                                            <blockquote className="border-0 mb-0">
                                                <p>“Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue
                                                    laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta
                                                    ac consectetur vestibulum. Donec sed odio dui consectetur adipiscing
                                                    elit.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12"
                                                         src="/assets/img/home/te3.jpg"
                                                         srcSet="/assets/img/home/te3@2x.jpg 2x" alt=""/>
                                                    <div className="info">
                                                        <h5 className="mb-1">Nikolas Brooten</h5>
                                                        <p className="mb-0">Sales Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>

                                </div>

                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2"></span>
                                            <blockquote className="border-0 mb-0">
                                                <p>“Etiam adipiscing tincidunt elit convallis felis suscipit ut.
                                                    Phasellus rhoncus tincidunt auctor. Nullam eu sagittis mauris. Donec
                                                    non dolor ac elit aliquam tincidunt at at sapien. Aenean tortor
                                                    libero condimentum ac laoreet vitae.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12"
                                                         src="/assets/img/home/te4.jpg"
                                                         srcSet="/assets/img/home/te4@2x.jpg 2x" alt=""/>
                                                    <div className="info">
                                                        <h5 className="mb-1">Coriss Ambady</h5>
                                                        <p className="mb-0">Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>

                                </div>

                                <div className="item col-md-6 col-xl-4 mt-8">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2"></span>
                                            <blockquote className="border-0 mb-0">
                                                <p>“Maecenas sed diam eget risus varius blandit sit amet non magna. Cum
                                                    sociis natoque penatibus magnis dis montes, nascetur ridiculus mus.
                                                    Donec sed odio dui. Nulla vitae elit libero.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12"
                                                         src="/assets/img/home/te5.jpg"
                                                         srcSet="/assets/img/home/te5@2x.jpg 2x" alt=""/>
                                                    <div className="info">
                                                        <h5 className="mb-1">Jackie Sanders</h5>
                                                        <p className="mb-0">Investment Planner</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>

                                </div>

                                <div className="item col-md-6 col-xl-4 mt-2">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2"></span>
                                            <blockquote className="border-0 mb-0">
                                                <p>“Donec id elit non mi porta gravida at eget metus. Nulla vitae elit
                                                    libero, a pharetra augue. Cum sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12"
                                                         src="/assets/img/home/te6.jpg"
                                                         srcSet="/assets/img/home/te6@2x.jpg 2x" alt=""/>
                                                    <div className="info">
                                                        <h5 className="mb-1">Laura Widerski</h5>
                                                        <p className="mb-0">Sales Specialist</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>







            </main>
            <Footer/>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
                </svg>
            </div>
        </div>
    );*/

    return (
        <div>
            <Helmet>
                <title>Découvrez nos huissiers sur facturesimpayees.com</title>
                <meta name="description"
                      content="Rencontrez nos huissiers sur facturesimpayees.com pour gérer efficacement vos factures impayées. Découvrez leur profil et leurs qualifications pour choisir celui qui convient le mieux à votre situation."/>
                <meta name="keywords"
                      content="huissiers, factures impayées, plateforme, profil, qualifications, choix, facturesimpayees.com"/>
            </Helmet>
            <Menu>
                <section className="py-5 text-center container">
                    <div className="row">
                        <div className="d-flex flex-column px-3">
                            <h1 className="hero-main-text mb-2 fs-60">Il ne reste plus qu'à<br/> récupérer votre argent
                                !</h1>
                        </div>
                    </div>
                </section>
            </Menu>
            <main className={"Main"}>
                <section className="wrapper bg-gradient-primary">
                    <div className="container pt-12 pt-lg-8 pb-14 pb-md-17">
                        <div className="row">
                            <div className="col-lg-12">
                                J’ai reçu mon ordonnance qui condamne mon débiteur à payer ma créance.
                                Mon débiteur n’est pas encore au courant.<br />
                                <br />
                                Je dois donc lui signifier cette décision par huissier (commissaire de justice).<br />
                                <br />
                                Vous ne connaissez pas de commissaires de justice ? Cliquez sur ce lien : <a href={"https://commissaire-justice.fr/"} target={"_blank"} rel={"noreferrer"}>https://commissaire-justice.fr</a><br /><br />
                                Votre débiteur disposera d’un délai d’un mois, à compter de la réception de sa
                                condamnation par huissier, pour contester cette décision.<br />
                                <br />
                                S’il conteste, vous serez convoqués tous les 2 par le tribunal qui tranchera votre
                                litige.<br />
                                <br />
                                S’il ne conteste pas dans le délai d’1 mois, la décision du Tribunal sera définitive et
                                il ne vous restera plus qu’à récupérer votre argent (par l’intermédiaire de l’huissier
                                de justice)<br />
                                <br />
                                Pour plus d’information, <a href={"/#faq"}>cliquez sur ce lien</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </div>
    )
}
