import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {getRequeteCountFromUserId, setRequete, updateRequete} from "../../class/Requete";
import {getCurrentUser, getUserFromUid, isAbonne, updateUser} from "../../class/User";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {Helmet} from 'react-helmet';
import {stripeIsAbonnePersonnalise} from "../../class/Abonnement";

export default function NouvelleRequete(props) {

    const [totaux, setTotaux] = useState({});

    const canCreateDossierTest = async (uid, endLoading) => {
        let user = await getUserFromUid(uid);
        if (user?.requete_test) {
            openModal(document.getElementById("abonnement"));
            endLoading();
        } else {
            await updateUser(uid, {requete_test: true});
            let requete = await setRequete(uid);
            await updateRequete(requete.id, {test: true});
            document.location.href = "/dashboard/requete/" + requete.id
            endLoading();
        }
    }
    const createRequete = async (endLoading) => {
        let user = await getCurrentUser();
        let userInfo = await getUserFromUid(user.uid);
        if (userInfo?.affiliation_user) {
            let user_parent=await getUserFromUid(userInfo.affiliation_user);
            let abonnement= await stripeIsAbonnePersonnalise(user_parent.stripe_customer_id);
            if(abonnement){
                let dossiers=user_parent.dossier;
                if (dossiers > 0) {
                    if (userInfo?.SIREN) {
                        await updateUser(userInfo.affiliation_user, {dossier: dossiers - 1});
                        let requete = await setRequete(user.uid);
                        document.location.href = "/dashboard/requete/" + requete.id
                    } else {
                        openModal(document.getElementById("siren"));
                        endLoading();
                    }
                } else {
                    openModal(document.getElementById("dossier"));
                    endLoading();
                }
            }else{
                alert("Le compte associé n'est plus abonné. Veuillez le contacter pour régulariser la situation.")
            }
            endLoading();
        } else {
            let abonnement = await isAbonne(user.uid);
            let currentUser = await getUserFromUid(user.uid);
            if (abonnement.status === "active" || userInfo.bypassAbonnement) {
                if (currentUser.dossier > 0) {
                    if (currentUser?.SIREN) {
                        await updateUser(user.uid, {dossier: currentUser.dossier - 1});
                        let requete = await setRequete(user.uid);
                        document.location.href = "/dashboard/requete/" + requete.id
                    } else {
                        openModal(document.getElementById("siren"));
                        endLoading();
                    }
                } else {
                    openModal(document.getElementById("dossier"));
                    endLoading();
                }
            } else {
                if (currentUser?.SIREN) {
                    await canCreateDossierTest(user.uid, endLoading);
                } else {
                    openModal(document.getElementById("siren"));
                    endLoading();
                }
            }
        }

    }

    useEffect(() => {
        const getData = async () => {
            let user = await getCurrentUser();
            let result = await getRequeteCountFromUserId(user.uid);
            setTotaux(result);
        }
        getData();
    }, []);


    return (
        <div className={"container-fluid justify-content-center align-items-center d-flex flex-column"}>
            <Helmet>
                <title>Démarrez une demande de paiement sur facturesimpayees.com</title>
                <meta name="description"
                      content="Initiatez une demande de paiement sur facturesimpayees.com pour recevoir rapidement le paiement de vos factures impayées. Utilisez notre formulaire pour soumettre votre requête et suivez l'évolution de votre demande."/>
                <meta name="keywords"
                      content="demande de paiement, factures impayées, plateforme, formulaire, requête, suivi, facturesimpayees.com"/>
            </Helmet>
            <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 mb-14 align-items-center">
                <div className="col-lg-7 order-lg-2">
                    <figure><img className="img-auto" src="/assets/img/start.svg" alt=""/></figure>
                </div>
                <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                    {totaux.en_cours > 0 ?
                        <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">Constituer un dossier
                            <br
                                className="d-md-none"/> <br className="d-md-none"/><span
                                className="rotator-fade text-violet"> supplémentaire</span></h1> :
                        <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">Créez votre premier <br
                            className="d-md-none"/> <br className="d-md-none"/><span
                            className="rotator-fade text-violet">dossier</span></h1>}
                    <p
                        className="lead fs-lg mb-7">Vous
                        avez {totaux.total} {totaux.total === 1 ? "dossier" : "dossiers"} dont <span
                            className={"badge-vert"}>{totaux.en_cours}</span> qui {totaux.en_cours === 1 ? "attend" : "attendent"} une
                        action de votre part. {totaux.action > 0 ?
                            <a href={"/dashboard/requete-en-cours"} className={"text-violet"}>Effectuer les actions
                                necessaires</a> : null}</p>
                    <Btn className={"mb-5"} iconeDroite={"fleche"} texte={"Créer un nouveau dossier"}
                         onClick={createRequete} loading={true}/>
                </div>
            </div>


            <Modal id={"abonnement"} titre={"Vous n'êtes pas abonné"}
                   body={"Vous devez être abonné pour pouvoir créer un nouveau dossier."}>
                <Btn texte="Je m'abonne" onClick={() => {
                    document.location.href = "/dashboard/mon-abonnement";
                }}/>
                <Btn texte="Annuler" onClick={() => {
                    closeModal(document.getElementById("abonnement"))
                }}/>
            </Modal>

            <Modal id={"dossier"} titre={"Vous n'avez plus de dossiers"}
                   body={"Vous n'avez plus de dossiers. Si vous voulez créer d'autres dossiers vous devez passer à un abonnement supérieur. Il peut s'écouler quelques minutes avant que vos dossiers mensuels soient crédités."}>
                <Btn texte="Changer mon abonnement" onClick={() => {
                    document.location.href = "/dashboard/mon-abonnement";
                }}/>
                <Btn texte="Annuler" onClick={() => {
                    closeModal(document.getElementById("dossier"))
                }}/>
            </Modal>

            <Modal id={"siren"} titre={"Complétez les informations de votre société."}
                   body={"Vous devez compléter les informations de votre société pour créer une premier dossier."}>
                <Btn texte="Compléter" onClick={() => {
                    document.location.href = "/dashboard/ma-societe";
                }}/>
                <Btn texte="Annuler" onClick={() => {
                    closeModal(document.getElementById("siren"))
                }}/>
            </Modal>
        </div>


    );
}
