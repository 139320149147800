import {urlFunction} from "./Config";
import {getCurrentUser, getUserFromUid, updateUser} from "./User";
import {addFacture, setRequete, updateRequete} from "./Requete";

const updateRelance = async (data, id) => {
    let user = await getCurrentUser();
    let uid = user.uid;
    let response = await fetch(urlFunction + "/updateRelance", {
        method: "POST",
        body: JSON.stringify({
            data: data,
            uid: uid,
            id: id
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        return {error: ""};
    }
};

const createRelance = async (data) => {
    let user = await getCurrentUser();
    let uid = user.uid;
    let response = await fetch(urlFunction + "/createRelance", {
        method: "POST",
        body: JSON.stringify({
            data: data,
            uid: uid
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        return {error: ""};
    }
};

const getRelances = async () => {
    let user = await getCurrentUser();
    let uid = user.uid;
    let response = await fetch(urlFunction + "/getRelances?uid=" + uid);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const getRelance = async (id) => {
    let response = await fetch(urlFunction + "/getRelance?id=" + id);
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const deleteRelance = async (id) => {
    let user = await getCurrentUser();
    let uid = user.uid;
    let response = await fetch(urlFunction + "/deleteRelances", {
        method: "POST",
        body: JSON.stringify({
            id: id,
            uid: uid
        })
    });
    if (response.ok) {
        let json = await response.json();
        return json;
    }
}

const creerDossierInjonction = async (facture) => {
    let relance=await getRelance(facture.id);
    if(relance.requeteId){
        return relance.requeteId;
    }else {
        let user = await getCurrentUser();
        let currentUser = await getUserFromUid(user.uid);
        let uid = user.uid;
        await updateUser(uid, {dossier: currentUser.dossier - 1});
        let requete = await setRequete(uid);
        facture.requeteId = requete.id;
        await updateRelance(facture, facture.id);
        let data={};
        //receiver_address > AdresseDebiteur
        if(facture?.ia?.global?.receiver_address?.mentionText){
            data.AdresseDebiteur=facture?.ia?.global?.receiver_address?.mentionText;
            //extraire le code postal
            let codePostal=facture?.ia?.global?.receiver_address?.mentionText.match(/\d{5}/);
            if(codePostal){
                data.CodePostalDebiteur=codePostal[0];
            }
        }
        //NomDebiteur
        if(facture?.ia?.global?.receiver_name?.mentionText){
            data.NomDebiteur=facture?.ia?.global?.receiver_name?.mentionText;
        }
        await updateRequete(requete.id, data);
        await addFacture(requete.id,facture.url,facture.type);
        return requete.id;
    }
}


export {
    updateRelance,
    createRelance,
    getRelances,
    deleteRelance,
    getRelance,
    creerDossierInjonction
}