import React from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet';

export default function Erreur404() {
    return (
        <div>
            <Helmet>
                <title>Erreur 404 - Page non trouvée sur facturesimpayees.com</title>
                <meta name="description" content="Désolé, la page que vous recherchez n'a pas été trouvée sur facturesimpayees.com. Veuillez vérifier l'adresse saisie ou utilisez les liens de navigation pour retrouver l'information recherchée."/>
                <meta name="keywords" content="erreur 404, page non trouvée, facturesimpayees.com"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className="error-container">
                    <h1 className="error-title">Erreur 404 - Page non trouvée</h1>
                    <img src={"/assets/img/404.png"} className="w-25 mb-7" alt={"404"} />
                    <p className="error-subtitle">Désolé, la page que vous recherchez n'a pas été trouvée sur facturesimpayees.com.</p>
                    <p className="error-subtitle">Il se peut qu'elle ait été supprimée ou que l'adresse que vous avez saisie soit incorrecte.</p>
                    <div className="error-actions">
                        <a href="https://facturesimpayees.com/" className="error-action-link">Retourner à l'accueil</a>
                        <a href="https://facturesimpayees.com/contact" className="error-action-link">Contacter le support</a>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
