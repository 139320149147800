import React from "react";
import Btn from "../../components/Btn";
import {Modal, openModal, closeModal} from "../../components/Modal";
import {getCurrentUser, changeEmailCurrentUser, changePasswordCurrentUser} from "../../class/User";
import {Helmet} from 'react-helmet';

class MonCompte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: "",
            Password: "",
            PasswordConfirm: "",
            PasswordPopup:""
        }
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changePasswordConfirm = this.changePasswordConfirm.bind(this);
        this.changePassWordPopup = this.changePassWordPopup.bind(this);

        this.updateEmail = this.updateEmail.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updatePasswordPassword = this.updatePasswordPassword.bind(this);


    }

    async componentDidMount() {
        let user = await getCurrentUser();
        if (user) {
            this.setState({
                Email: user.email
            })
        }

    }


    changeEmail(event) {
        this.setState({Email: event.target.value});
    }

    changePassword(event) {
        this.setState({Password: event.target.value});
    }

    changePasswordConfirm(event) {
        this.setState({PasswordConfirm: event.target.value});
    }

    changePassWordPopup(event){
        this.setState({PasswordPopup: event.target.value});
    }

    confirmPassword() {
        let form = document.getElementById("formEmail");
        if (!form.checkValidity()) {

        } else {
            openModal(document.getElementById("modal_password"));
        }
        form.classList.add('was-validated');
    }

    async updateEmail() {
        const emailStatut=await changeEmailCurrentUser(this.state.Email, this.state.PasswordPopup);
        if(emailStatut){
            openModal(document.getElementById("modal_password_ok"));
        }else{
            openModal(document.getElementById("modal_password_error"));
        }
    }

    async updatePassword(){
        let form = document.getElementById("formPassword");
        if (!form.checkValidity()) {

        } else {
            if(this.state.Password!==this.state.PasswordConfirm){
                openModal(document.getElementById("modal_modif_password_error"));
            }else {
                openModal(document.getElementById("modal_password_password"));
            }
        }
        form.classList.add('was-validated');
    }

    async updatePasswordPassword(){
        const passwordStatut = await changePasswordCurrentUser(this.state.PasswordPopup,this.state.Password);
        if (passwordStatut) {
            openModal(document.getElementById("modal_modif_password_ok"));
        } else {
            openModal(document.getElementById("modal_modif_password_error"));
        }
    }


    render() {
        return (
            <div className={"container-fluid"}>
                <Helmet>
                    <title>Gérez votre compte sur facturesimpayees.com</title>
                    <meta name="description" content="Accédez à votre espace compte sur facturesimpayees.com pour gérer vos informations personnelles. Mettez à jour vos données et paramétrez vos préférences pour gérer efficacement vos factures impayées."/>
                    <meta name="keywords" content="compte, gestion, informations personnelles, factures impayées, plateforme, préférences, facturesimpayees.com"/>
                </Helmet>
                <h2 className={"dashboard-title"}>Mon compte</h2>
                <form className={"form-default needs-validation"} id={"formEmail"} name={"formEmail"}
                      noValidate={true}>
                    <div className={"col-12"}>
                        <input type={"email"} className={"form-control"} id={"Email"}
                               placeholder={"Email"} value={this.state.Email}
                               onChange={this.changeEmail} required/>
                        <div className="invalid-feedback">
                            L'email n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12 mt-3"}>
                        <div className={"d-flex flex-column flex-md-row"}>
                            <Btn className={this.props.type=="commissaireDeJustice"?"btn-vert ":""||this.props.type=="avocat"?"btn_avocat ":""} texte={"Modifier l'email"} iconeDroite={"fleche"} onClick={this.confirmPassword}/>
                        </div>
                    </div>
                </form>


                <form className={"form-default needs-validation"} id={"formPassword"} name={"formPassword"}
                      noValidate={true}>
                    <div className={"col-12 mt-5"}>
                        <input type={"password"} className={"form-control"} id={"Password"}
                               placeholder={"Mot de passe"} value={this.state.Password}
                               onChange={this.changePassword} required/>
                        <div className="invalid-feedback">
                            Le mot de passe n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12 mt-3"}>
                        <input type={"password"} className={"form-control"} id={"PasswordConfirm"}
                               placeholder={"Confirmation mot de passe"} value={this.state.PasswordConfirm}
                               onChange={this.changePasswordConfirm} required/>
                        <div className="invalid-feedback">
                            La confirmation du mot de passe n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12 mt-3"}>
                        <div className={"d-flex flex-column flex-md-row"}>
                            <Btn className={this.props.type=="commissaireDeJustice"?"btn-vert ":""||this.props.type=="avocat"?"btn_avocat ":""} texte={"Modifier le mot de passe"} iconeDroite={"fleche"}
                                 onClick={this.updatePassword}/>
                        </div>
                    </div>
                </form>


                <Modal id={"modal_password"} titre={"Mise à jour email"}
                       body={<div>
                           <div className={"mb-2"}>Veuillez confirmer votre mot de passe :</div>
                           <input type={"password"} id={"password"} value={this.state.PasswordPopup} onChange={this.changePassWordPopup} />
                       </div>}>
                    <Btn texte="Confirmer" onClick={async () => {
                        closeModal(document.getElementById("modal_password"));
                        await this.updateEmail();
                    }}/>
                </Modal>
                <Modal id={"modal_password_error"} titre={"Mise à jour email"}
                       body={"Le mot de passe n'est pas correct ou l'email est déjà utilisé par un autre compte."}>
                    <Btn texte="Ok" onClick={async () => {
                        closeModal(document.getElementById("modal_password_error"));
                    }}/>
                </Modal>
                <Modal id={"modal_password_ok"} titre={"Mise à jour email"}
                       body={"L'email a bien été modifié."}>
                    <Btn texte="Ok" onClick={async () => {
                        closeModal(document.getElementById("modal_password_ok"));
                    }}/>
                </Modal>
                <Modal id={"modal_modif_password_error"} titre={"Mise à jour mot de passe"}
                       body={"Vos mots de passe ne correspondent pas, ne sont pas assez sécurisés ou vous avez mal saisi votre mot de passe actuel."}>
                    <Btn texte="Ok" onClick={async () => {
                        closeModal(document.getElementById("modal_modif_password_error"));
                    }}/>
                </Modal>
                <Modal id={"modal_modif_password_ok"} titre={"Mise à jour mot de passe"}
                       body={"Le mot de passe a été modifié."}>
                    <Btn texte="Ok" onClick={async () => {
                        closeModal(document.getElementById("modal_modif_password_ok"));
                    }}/>
                </Modal>
                <Modal id={"modal_password_password"} titre={"Mise à jour email"}
                       body={<div>
                           <div className={"mb-2"}>Veuillez confirmer votre mot de passe :</div>
                           <input type={"password"} id={"password"} value={this.state.PasswordPopup} onChange={this.changePassWordPopup} />
                       </div>}>
                    <Btn texte="Confirmer" onClick={async () => {
                        closeModal(document.getElementById("modal_password_password"));
                        await this.updatePasswordPassword();
                    }}/>
                </Modal>


            </div>

        );
    }
}

export default MonCompte;
