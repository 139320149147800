import React, {useEffect, useState} from "react";
import {getCurrentUser, disconnect, getUserFromUid} from "../class/User";

export default function MenuCommissaireDeJustice(props) {

    const [uid, setUid] = useState();
    const [loading, setLoading] = useState(true);
    const [dossiers, setDossiers] = useState(0);
    useEffect(() => {

        const getData = async () => {
            try {
                let user = await getCurrentUser();
                if (user) {
                    let societe = await getUserFromUid(user.uid);
                    if (societe?.affiliation_user) {
                        let affilie = await getUserFromUid(societe.affiliation_user);
                        if (affilie) {
                            setDossiers(affilie.dossier);
                        }
                    } else {
                        setDossiers(societe.dossier);
                    }
                    if (user) {
                        setUid(user.uid);
                    }
                }
                setLoading(false);
            } catch (error) {
                console.log("Hors ligne Users");
            }
        };
        getData();

    }, []);

    const deconnexion = () => {
        disconnect();
    }


    return (
        <header className={"header-menu commissairedejustice"}>
            <nav className="navbar navbar-expand-xxl">
                <div className="container-fluid">
                    <div className={"d-flex flex-column"}>
                        <a href="https://facturesimpayees.com"
                           className="navbar-brand d-flex align-items-center text-dark text-decoration-none logo mx-0">
                            <img src={"/assets/img/logo.png"} alt={"Logo factures impayées"} width={44} height={60}/>
                            <span className="texte-logo">Facturesimpayees.com<span
                                className={"version"}></span></span>

                                <div className={"dashboard fs-20 d-xl-flex d-none text-white"}>Commissaire de justice</div>
                        </a>

                        {uid && dossiers ?
                            <div className={"credits d-flex"}><img src={"/assets/img/credits.png"} width={"15"}
                                                                   height={"15"} alt={"crédits"}
                                                                   className={"icone-credits me-2"}/> Crédit{dossiers > 1 ? "s" : ""}
                                : {dossiers}
                                &nbsp;dossier(s)</div> : null}
                    </div>
                    {loading ? null :
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarsMain" aria-controls="navbarsMain" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>}

                    <div className="collapse navbar-collapse justify-content-end" id="navbarsMain">
                        {!loading ?
                            <ul className="nav nav-pills navbar-nav align-items-center menu-principal gap-xxl-2 gap-0">
                                {uid ?
                                    <li className="nav-item mise-en-avant align-items-center d-flex justify-content-center">
                                        <a
                                            href="/dashboard" className="nav-link element-menu">Tableau de bord</a>
                                    </li> :
                                    <li className="nav-item mise-en-avant align-items-center d-flex justify-content-center">
                                        <a
                                            href="/inscription" className="nav-link element-menu">Inscription</a>
                                    </li>}
                                {uid ? <li className="nav-item"><a onClick={deconnexion} href="/"
                                                                   className="nav-link element-menu">Déconnexion</a>
                                </li> : <li className="nav-item"><a href="/connexion"
                                                                    className="nav-link element-menu">Connexion</a>
                                </li>}
                            </ul> : null}
                    </div>
                </div>
            </nav>
            {props.children}
        </header>
    );
}
