import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {PDFDocument, StandardFonts, rgb, degrees} from 'pdf-lib';
import {urlSite} from "../class/Config";
import {
    getFacturesFromRequete,
    getRequete,
    getTDC,
    getTJ,
    getTotal,
    isDebiteurSocieteCivile,
    updateRequete
} from "../class/Requete";
import {getCurrentUser, getUserFromUid} from "../class/User";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {firebaseApp} from "../components/Firebase";

import * as PDFJS from "pdfjs-dist/build/pdf";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";



export default function PdfInjonction() {
    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    let {id} = useParams();
    const [urlPdf, setUrlPdf] = useState("");

    const maxCharactersPerLine = 40; // Ajustez cette valeur en fonction de votre mise en page

    const convertDateFormat = (date) => {
        // Check for the correct input format
        const re = /^\d{4}-\d{2}-\d{2}$/;
        if (!date.match(re)) {
            return date;
            //throw new Error('Invalid date format. Please use YYYY-MM-DD.');
        }

        // Split the date by hyphen to get the individual parts
        const parts = date.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Return the date in DD/MM/YYYY format
        return `${day}/${month}/${year}`;
    }

    function drawTextWithLineBreaks(page, text, options) {
        let lines = [];
        let start = 0;

        while (start < text.length) {
            let end = start + maxCharactersPerLine <= text.length ? start + maxCharactersPerLine : text.length;
            // Si le caractère à la position 'end' n'est pas un espace, retrouver le dernier espace
            if (end !== text.length && text[end] !== ' ') {
                while (end > start && text[end] !== ' ') {
                    end--;
                }
            }
            let line = text.slice(start, end);
            // Vérifie si la ligne peut être étendue sans dépasser maxCharactersPerLine
            while (end + 1 < text.length && line.length + text[end + 1].length <= maxCharactersPerLine) {
                end++;
                line += text[end];
            }
            lines.push(line);
            start = end + 1;
        }

        const lineHeight = options.size * 0.8; // Ajustez cette valeur en fonction de vos besoins

        lines.forEach((line, index) => {
            page.drawText(line, {
                ...options,
                y: options.y - (index * lineHeight),
            });
        });

        // Renvoie le nombre de lignes
        return lines.length;
    }


    const addPage = async (url, type, mergedPdf) => {
        function dataURLToArrayBuffer(dataURL) {
            const binaryString = atob(dataURL.split(',')[1]);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        }


        if (type === "image/jpeg" || type === "image/png") {
            const page = mergedPdf.addPage();
            let imageBytes;

            if (url.startsWith('data:')) {
                imageBytes = dataURLToArrayBuffer(url);
            } else {
                imageBytes = await fetch(url).then((res) => res.arrayBuffer());
            }

            let image;
            if (type === "image/jpeg") {
                image = await mergedPdf.embedJpg(imageBytes);
            } else if (type === "image/png") {
                image = await mergedPdf.embedPng(imageBytes);
            }

            const imageDims = image.scale(1);
            let width = page.getWidth();
            let height = page.getWidth() / (imageDims.width / imageDims.height);

            page.drawImage(image, {
                x: 0,
                y: page.getHeight() - height,
                width: width,
                height: height
            });

        }
        if (type === "application/pdf") {
            const factureUrl = url;
            const factureBytes = await fetch(factureUrl).then(res => res.arrayBuffer())
            const factureDoc = await PDFDocument.load(factureBytes, {ignoreEncryption: true})

            const copiedPagesB = await mergedPdf.copyPages(factureDoc, factureDoc.getPageIndices());
            copiedPagesB.forEach((page) => mergedPdf.addPage(page));
        }
        if (type === "image/webp") {

        }
        if (type === "image/bmp") {

        }
        if (type === "image/tiff") {

        }
        if (type === "image/gif") {

        }
    }

    useEffect(() => {
        const createPdfEntreprise = async () => {


            let requete = await getRequete(id);
            let societe = await getUserFromUid(requete.uid);
            let total = await getTotal(id);
            let tdc = await getTDC(requete.CodePostalDebiteur);

            const url = urlSite + '/assets/pdf/65_Demandeeninjonctiondepayer.pdf';
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes)


            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            // Get the first page of the document
            const pages = pdfDoc.getPages()
            const firstPage = pages[0]
            const secondPage = pages[1]


            // Draw a string of text diagonally across the first page
            /*firstPage.drawText("Numero du tribunal", {
                x: 119,
                y: 576,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })*/
            if (tdc) {
                firstPage.drawText(tdc.fields.code_greffe, {
                    x: 113,
                    y: 564,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }

            if(requete.fiche_identite_crediteur) {
                societe.fiche_identite=requete.fiche_identite_crediteur;
            }

            firstPage.drawText(societe.fiche_identite.Data.LibelleFormeJuridique + " - " + societe.fiche_identite.Data.Denomination, {
                x: 50,
                y: 490,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            if(requete.Adresse_crediteur) {
                societe.Adresse=requete.Adresse_crediteur;
            }

            if(requete.CodePostal_crediteur) {
                societe.CodePostal=requete.CodePostal_crediteur;
            }

            if(requete.Ville_crediteur) {
                societe.Ville=requete.Ville_crediteur;
            }


            firstPage.drawText(societe.Adresse + " " + societe.CodePostal + " " + societe.Ville, {
                x: 50,
                y: 475,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            //mandataire
            if(requete.Adresse_crediteur) {
                let societe_mandataire = await getUserFromUid(requete.uid);
                firstPage.drawText(societe_mandataire.fiche_identite.Data.LibelleFormeJuridique + " - " + societe_mandataire.fiche_identite.Data.Denomination, {
                    x: 50,
                    y: 350,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })

                firstPage.drawText(societe_mandataire.Adresse + " " + societe_mandataire.CodePostal + " " + societe_mandataire.Ville, {
                    x: 50,
                    y: 335,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })

                firstPage.drawText(societe_mandataire.Prenom+" "+societe_mandataire.Nom+" - "+societe_mandataire.Qualite, {
                    x: 50,
                    y: 320,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }

            firstPage.drawText(requete.ficheIdentiteDebiteur.Data.LibelleFormeJuridique + " - " + requete.ficheIdentiteDebiteur.Data.Denomination, {
                x: 50,
                y: 225,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            firstPage.drawText(requete.AdresseDebiteur + " - " + requete.CodePostalDebiteur + " - " + requete.VilleDebiteur, {
                x: 50,
                y: 210,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })



            firstPage.drawText("X", {
                x: 45,
                y: 83,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            if (tdc) {
                firstPage.drawText("Tribunal du commerce de " + tdc.fields.greffe, {
                    x: 270,
                    y: 73,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }
            secondPage.drawText(total.totalRestant.toString() + " €", {
                x: 210,
                y: 770,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);

            secondPage.drawText(today.toLocaleDateString(), {
                x: 80,
                y: 522,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            if (requete.signature) {
                const jpgUrl = requete.signature;

                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.2)


                secondPage.drawImage(jpgImage, {
                    x: 125,
                    y: 490,
                    width: jpgDims.width,
                    height: jpgDims.height
                })
            }


            const pageDeGarde = await PDFDocument.create();
            const helveticaFont2 = await pageDeGarde.embedFont(StandardFonts.Helvetica)

            // Crée une nouvelle page
            const coverPage = pageDeGarde.addPage()

// Ajoute le texte à la page de garde
            coverPage.drawText("Le " + today.toLocaleDateString(), {
                x: 50,
                y: coverPage.getHeight() - 50,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            if(requete.RaisonSociale_crediteur) {
                societe.RaisonSociale=requete.RaisonSociale_crediteur;
            }
            coverPage.drawText(societe.RaisonSociale, {
                x: 50,
                y: coverPage.getHeight() - 70,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })


            if(requete.Adresse_crediteur) {
                societe.Adresse=requete.Adresse_crediteur;
            }
            coverPage.drawText(societe.Adresse, {
                x: 50,
                y: coverPage.getHeight() - 90,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })
            if(requete.CodePostal_crediteur) {
                societe.CodePostal=requete.CodePostal_crediteur;
            }
            if(requete.Ville_crediteur) {
                societe.Ville=requete.Ville_crediteur;
            }
            coverPage.drawText(societe.CodePostal + " " + societe.Ville, {
                x: 50,
                y: coverPage.getHeight() - 110,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            if (tdc) {
                coverPage.drawText("Tribunal du commerce de " + tdc.fields.greffe, {
                    x: 300,
                    y: coverPage.getHeight() - 150,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                })
            } else {
                coverPage.drawText("Tribunal du commerce de _______________", {
                    x: 300,
                    y: coverPage.getHeight() - 150,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                })
            }

            coverPage.drawText("Service des requêtes en injonctions de payer", {
                x: 300,
                y: coverPage.getHeight() - 170,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })
            let tdcAdresse = "";
            let lineCount;
            if (tdc) {
                tdcAdresse = ((tdc.fields.numero_voie ? tdc.fields.numero_voie + " " : "") +
                    (tdc.fields.type_voie ? tdc.fields.type_voie + " " : "") +
                    (tdc.fields.voie ? tdc.fields.voie : "")).trim();
                lineCount = drawTextWithLineBreaks(coverPage, tdcAdresse, {
                    x: 300,
                    y: coverPage.getHeight() - 183,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                });
            } else {
                lineCount = drawTextWithLineBreaks(coverPage, "__________________________", {
                    x: 300,
                    y: coverPage.getHeight() - 183,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                });
            }


            if (tdc) {
                coverPage.drawText(tdc.fields.code_postal + " " + tdc.fields.bureau_distributeur, {
                    x: 300,
                    y: coverPage.getHeight() - 183 - lineCount * 20 * 0.8,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                })
            } else {
                coverPage.drawText("__________________________", {
                    x: 300,
                    y: coverPage.getHeight() - 183 - lineCount * 20 * 0.8,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                })
            }

            coverPage.drawText('Objet: Requête en injonction de payer', {
                x: 50,
                y: coverPage.getHeight() - 240,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            if(requete.Prenom_crediteur) {
                societe.Prenom=requete.Prenom_crediteur;
            }

            if(requete.Nom_crediteur) {
                societe.Nom=requete.Nom_crediteur;
            }

            coverPage.drawText(`Madame, Monsieur le Greffier,

Veuillez trouver ci-après:
 
- Requête en injonction de payer
- Pièces justificatives
- Chèque

Dans l’attente du traitement par vos services, 
Veuillez agréer, Madame, Monsieur le Greffier, l’expression de ma parfaite considération.

Pour la société: ${societe.RaisonSociale}
Son représentant légal:  ${societe.Prenom + " " + societe.Nom} ${societe.Type==="Avocat"? "en qualité d'"+societe.Type:""} ${societe.Type==="Commissaire de justice"? "en qualité de "+societe.Type:""}
`, {
                x: 50,
                y: coverPage.getHeight() - 260,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

// Ajoute la signature s'il y en a une
            if (requete.signature) {
                const jpgUrl = requete.signature;
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
                const jpgImage = await pageDeGarde.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.2)


                coverPage.drawImage(jpgImage, {
                    x: 0,
                    y: coverPage.getHeight() - 720,
                    width: jpgDims.width,
                    height: jpgDims.height
                })
            }

            const mergedPdf = await PDFDocument.create();

            // Ensuite, vous devrez copier la page de garde à votre document final
            const copiedCoverPage = await mergedPdf.copyPages(pageDeGarde, [0])
            mergedPdf.insertPage(0, copiedCoverPage[0])

            let bordereau = mergedPdf.insertPage(1);

            bordereau.drawText("Bordereau récapitulatif des pièces jointes", {
                x: 50,
                y: bordereau.getHeight() - 100,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            bordereau.drawText("- Pièces justifiants la créance : ", {
                x: 50,
                y: bordereau.getHeight() - 190,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })
            let factures_liste = await getFacturesFromRequete(id);
            factures_liste.map((facture, i) => {
                bordereau.drawText("        - N°" + facture.invoice_id + " en date du " + convertDateFormat(facture.invoice_date) + " d’un montant de " + Math.round(facture.total_amount*100)/100 + " € TTC", {
                    x: 50,
                    y: bordereau.getHeight() - (190 + ((i+1) * 15)),
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                return facture;
            })

            bordereau.drawText("- Lettre de mise en demeure de payer", {
                x: 50,
                y: bordereau.getHeight() - (215 + (factures_liste.length * 15)),
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            bordereau.drawText("- Accusé de reception de la lettre de mise en demeure de payer", {
                x: 50,
                y: bordereau.getHeight() - (240 + (factures_liste.length * 15)),
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            bordereau.drawText("- KBIS du débiteur", {
                x: 50,
                y: bordereau.getHeight() - (265+ (factures_liste.length * 15)),
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            mergedPdf.insertPage(1);
            mergedPdf.insertPage(3);




            const copiedPagesA = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            copiedPagesA.forEach((page) => mergedPdf.addPage(page));

            let factures = await getFacturesFromRequete(id);

            for (let i = 0; i < factures.length; i++) {
                await addPage(factures[i].url, factures[i].type, mergedPdf);
            }
            if(requete.miseEnDemeure) {
                await addPage(requete.miseEnDemeure.url, requete.miseEnDemeure.type, mergedPdf);
            }
            await addPage(requete.accuseReception.url, requete.accuseReception.type, mergedPdf);

            if(requete.KBIS_debiteur) {
                let url_kbis = requete.KBIS_debiteur.url;
                if(requete.KBIS_debiteur.type==="application/pdf") {

                    const pdfDocPromise = PDFJS.getDocument({url: url_kbis});
                    const pdfDoc_kbis = await pdfDocPromise.promise;

                    const page = await pdfDoc_kbis.getPage(1);
                    const viewport = page.getViewport({scale: 2});

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    await page.render(renderContext).promise;

                    const jpegImageBase64 = canvas.toDataURL('image/jpeg');


                    await addPage(jpegImageBase64, "image/jpeg", mergedPdf);
                }else if(requete.KBIS_debiteur.type==="image/jpeg" || requete.KBIS_debiteur.type==="image/png"){
                    await addPage(requete.KBIS_debiteur.url, requete.KBIS_debiteur.type, mergedPdf);
                }
            }

            if(requete.test) {
                const helveticaFontBold = await mergedPdf.embedFont(StandardFonts.HelveticaBold);
                mergedPdf.getPages().forEach((page, i) => {
                    //if(requete.test) {
                    page.drawText("SPECIMEN", {
                        x: 100,
                        y: 180,
                        size: 120,
                        font: helveticaFontBold,
                        color: rgb(1, 0, 0),
                        opacity: 0.5,
                        rotate: degrees(45)
                    });
                    //}
                });
            }

            // Serialize the PDFDocument to bytes (a Uint8Array)

            const pdfBytes = await mergedPdf.save()
            await updateRequete(id, {
                nbrPagesRequete: mergedPdf.getPages().length
            });
            await uploadPDF(pdfBytes);


            let blob = new Blob([pdfBytes], {type: "application/pdf"});
            let urlBlob = URL.createObjectURL(blob);
            setUrlPdf(urlBlob);
        }

        const createPdfParticulier = async (societeCivile) => {


            let requete = await getRequete(id);
            let societe = await getUserFromUid(requete.uid);
            let total = await getTotal(id);
            let TJ = await getTJ(requete.CodePostalDebiteur);


            const url = urlSite + '/assets/pdf/cerfa_12948-06.pdf';
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes)


            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            // Get the first page of the document
            const pages = pdfDoc.getPages()
            const firstPage = pages[0]
            const secondPage = pages[1]
            const thirdPage = pages[2]
            const fourthPage = pages[3]

            if(requete.fiche_identite_crediteur) {
                societe.fiche_identite=requete.fiche_identite_crediteur;
            }



            // Draw a string of text diagonally across the first page
            firstPage.drawText(societe.fiche_identite.Data.LibelleFormeJuridique, {
                x: 295,
                y: 262,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            firstPage.drawText(societe.fiche_identite.Data.Denomination, {
                x: 122,
                y: 242,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            if(requete.Prenom_crediteur) {
                societe.Prenom=requete.Prenom_crediteur;
            }

            if(requete.Nom_crediteur) {
                societe.Nom=requete.Nom_crediteur;
            }

            firstPage.drawText(societe.Prenom + " " + societe.Nom, {
                x: 138,
                y: 222,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            if(requete.Adresse_crediteur) {
                societe.Adresse=requete.Adresse_crediteur;
            }

            firstPage.drawText(societe.Adresse, {
                x: 172,
                y: 202,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            if(requete.CodePostal_crediteur) {
                societe.CodePostal=requete.CodePostal_crediteur;
            }
            for (let i = 0; i < 5; i++) {
                firstPage.drawText(societe.CodePostal.charAt(i), {
                    x: 117 + (i * 14),
                    y: 182,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }

            if(requete.Ville_crediteur) {
                societe.Ville=requete.Ville_crediteur;
            }

            firstPage.drawText(societe.Ville, {
                x: 250,
                y: 182,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            if(!requete.SIREN_crediteur) {


                firstPage.drawText(societe.email.split("@")[0], {
                    x: 160,
                    y: 162,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })


                firstPage.drawText(societe.email.split("@")[1], {
                    x: 375,
                    y: 162,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }


            if(requete.Telephone_crediteur) {
                societe.Telephone=requete.Telephone_crediteur;
            }

            for (let i = 0; i < 10; i++) {
                firstPage.drawText(societe.Telephone.charAt(i), {
                    x: 169 + (14 * i),
                    y: 142,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
            }
            if (societeCivile && requete.type === "entreprise") {
                secondPage.drawText(requete.formeJuridiqueDebiteur, {
                    x: 300,
                    y: 310,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                secondPage.drawText(requete.raisonSocialeDebiteur, {
                    x: 130,
                    y: 290,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                secondPage.drawText(requete.PrenomDebiteur + " " + requete.NomDebiteur, {
                    x: 140,
                    y: 270,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                secondPage.drawText(requete.AdresseDebiteur, {
                    x: 180,
                    y: 250,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                for (let i = 0; i < 5; i++) {
                    secondPage.drawText(requete.CodePostalDebiteur.charAt(i), {
                        x: 118 + (i * 14),
                        y: 230,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
                secondPage.drawText(requete.VilleDebiteur, {
                    x: 250,
                    y: 230,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                if (requete.EmailDebiteur) {
                    secondPage.drawText(requete.EmailDebiteur.split("@")[0], {
                        x: 160,
                        y: 210,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                    secondPage.drawText(requete.EmailDebiteur.split("@")[1], {
                        x: 370,
                        y: 210,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
                if (requete.telephoneDebiteur) {
                    for (let i = 0; i < 10; i++) {
                        secondPage.drawText(requete.telephoneDebiteur.charAt(i), {
                            x: 170 + (i * 14),
                            y: 190,
                            size: 10,
                            font: helveticaFont,
                            color: rgb(0, 0, 0),
                        })
                    }
                }
            } else {
                if(requete.Nom_crediteur) {
                    secondPage.drawText(societe.Nom, {
                        x: 100,
                        y: 680,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }

                if(requete.Nom_crediteur) {
                    secondPage.drawText(societe.Prenom, {
                        x: 120,
                        y: 665,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }

                if(requete.Nom_crediteur) {
                    secondPage.drawText(societe.Qualite, {
                        x: 42,
                        y: 640,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }



                if (requete.TitreDebiteur === "Madame") {
                    secondPage.drawText('X', {
                        x: 45,
                        y: 530,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
                if (requete.TitreDebiteur === "Monsieur") {
                    secondPage.drawText('X', {
                        x: 187,
                        y: 530,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }

                secondPage.drawText(requete.NomDebiteur, {
                    x: 252,
                    y: 510,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                if (requete && requete.NomEpouxDebiteur && requete.NomEpouxDebiteur.length) {
                    secondPage.drawText(requete.NomEpouxDebiteur, {
                        x: 326,
                        y: 490,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }

                secondPage.drawText(requete.PrenomDebiteur, {
                    x: 117,
                    y: 470,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })

                secondPage.drawText(requete.AdresseDebiteur, {
                    x: 112,
                    y: 450,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })

                /*secondPage.drawText('Complement Adresse', {
                    x: 169,
                    y: 430,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0,0,0),
                })*/

                for (let i = 0; i < 5; i++) {
                    secondPage.drawText(requete.CodePostalDebiteur.charAt(i), {
                        x: 119 + (i * 14),
                        y: 410,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
                if (requete.VilleDebiteur) {
                    secondPage.drawText(requete.VilleDebiteur, {
                        x: 264,
                        y: 410,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }

                secondPage.drawText(requete.PaysDebiteurFR, {
                    x: 73,
                    y: 390,
                    size: 10,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                })
                if (requete.EmailDebiteur) {
                    secondPage.drawText(requete.EmailDebiteur.split("@")[0], {
                        x: 159,
                        y: 370,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })

                    secondPage.drawText(requete.EmailDebiteur.split("@")[1], {
                        x: 371,
                        y: 370,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
            }


            thirdPage.drawText('X', {
                x: 45,
                y: 274,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            thirdPage.drawText('X', {
                x: 45,
                y: 246,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            if (TJ) {
                if (TJ.nom) {
                    thirdPage.drawText(TJ.nom, {
                        x: 277,
                        y: 232,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    })
                }
            }

            fourthPage.drawText(total.totalRestant.toString() + " €", {
                x: 215,
                y: 728,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })

            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);

            fourthPage.drawText(today.toLocaleDateString(), {
                x: 75,
                y: 458,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            if (requete.signature) {
                const jpgUrl = requete.signature;
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
                const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.2)


                fourthPage.drawImage(jpgImage, {
                    x: 125,
                    y: 400,
                    width: jpgDims.width,
                    height: jpgDims.height
                })
            }

            const pageDeGarde = await PDFDocument.create();
            const helveticaFont2 = await pageDeGarde.embedFont(StandardFonts.Helvetica)

            // Crée une nouvelle page
            const coverPage = pageDeGarde.addPage()

// Ajoute le texte à la page de garde
            coverPage.drawText("Le " + today.toLocaleDateString(), {
                x: 50,
                y: coverPage.getHeight() - 50,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })



            if(requete.RaisonSociale_crediteur) {
                societe.RaisonSociale=requete.RaisonSociale_crediteur;
            }


            coverPage.drawText(societe.RaisonSociale, {
                x: 50,
                y: coverPage.getHeight() - 70,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            if(requete.Adresse_crediteur) {
                societe.Adresse=requete.Adresse_crediteur;
            }

            coverPage.drawText(societe.Adresse, {
                x: 50,
                y: coverPage.getHeight() - 90,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            if(requete.CodePostal_crediteur) {
                societe.CodePostal=requete.CodePostal_crediteur;
            }
            if(requete.Ville_crediteur) {
                societe.Ville=requete.Ville_crediteur;
            }

            coverPage.drawText(societe.CodePostal + " " + societe.Ville, {
                x: 50,
                y: coverPage.getHeight() - 110,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })
            if (TJ) {
                if (TJ.nom) {
                    coverPage.drawText(TJ.nom, {
                        x: 300,
                        y: coverPage.getHeight() - 150,
                        size: 12,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    })
                }
            }

            coverPage.drawText("Service des requêtes en injonctions de payer", {
                x: 300,
                y: coverPage.getHeight() - 165,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })
            let lineCount = 1;
            if (TJ) {
                if (TJ.adresse) {
                    TJ.adresse = TJ.adresse.replace(/\n/g, " ");
                    lineCount = drawTextWithLineBreaks(coverPage, TJ.adresse, {
                        x: 300,
                        y: coverPage.getHeight() - 183,
                        size: 12,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    coverPage.drawText("_____________________________", {
                        x: 300,
                        y: coverPage.getHeight() - 183,
                        size: 12,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    });
                }
                if (TJ.codepostal && TJ.ville) {
                    coverPage.drawText(TJ.codepostal + " " + TJ.ville, {
                        x: 300,
                        y: coverPage.getHeight() - 183 - lineCount * 20 * 0.8, // Ici, je suppose que l'espace entre les lignes est de 0.8 fois la taille de la police.
                        size: 12,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    coverPage.drawText("_____________________________", {
                        x: 300,
                        y: coverPage.getHeight() - 183 - lineCount * 20 * 0.8, // Ici, je suppose que l'espace entre les lignes est de 0.8 fois la taille de la police.
                        size: 12,
                        font: helveticaFont2,
                        color: rgb(0, 0, 0),
                    });
                }
            } else {
                coverPage.drawText("_____________________________", {
                    x: 300,
                    y: coverPage.getHeight() - 183,
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                });
                coverPage.drawText("_____________________________", {
                    x: 300,
                    y: coverPage.getHeight() - 183 - lineCount * 20 * 0.8, // Ici, je suppose que l'espace entre les lignes est de 0.8 fois la taille de la police.
                    size: 12,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                });
            }

            coverPage.drawText('Objet: Requête en injonction de payer', {
                x: 50,
                y: coverPage.getHeight() - 240,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            if(requete.Prenom_crediteur) {
                societe.Prenom=requete.Prenom_crediteur;
            }

            if(requete.Nom_crediteur) {
                societe.Nom=requete.Nom_crediteur;
            }

            coverPage.drawText(`Madame, Monsieur le Greffier,

Veuillez trouver ci-après:
 
- Requête en injonction de payer
- Pièces justificatives

Dans l’attente du traitement par vos services, 
Veuillez agréer, Madame, Monsieur le Greffier, l’expression de ma parfaite considération.

Pour la société: ${societe.RaisonSociale}
Son représentant légal: ${societe.Prenom + " " + societe.Nom} ${societe.Type==="Avocat"? "en qualité d'"+societe.Type:""} ${societe.Type==="Commissaire de justice"? "en qualité de "+societe.Type:""}
`, {
                x: 50,
                y: coverPage.getHeight() - 300,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

// Ajoute la signature s'il y en a une
            if (requete.signature) {
                const jpgUrl = requete.signature;
                const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
                const jpgImage = await pageDeGarde.embedPng(jpgImageBytes)
                const jpgDims = jpgImage.scale(0.2)


                coverPage.drawImage(jpgImage, {
                    x: 30,
                    y: coverPage.getHeight() - 700,
                    width: jpgDims.width,
                    height: jpgDims.height
                })
            }

            const mergedPdf = await PDFDocument.create();

            // Ensuite, vous devrez copier la page de garde à votre document final
            const copiedCoverPage = await mergedPdf.copyPages(pageDeGarde, [0])
            mergedPdf.insertPage(0, copiedCoverPage[0])


            let bordereau = mergedPdf.insertPage(1);

            bordereau.drawText("Bordereau récapitulatif des pièces jointes à la requête : ", {
                x: 50,
                y: bordereau.getHeight() - 100,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })


            bordereau.drawText("- Pièces justifiants la créance :", {
                x: 50,
                y: bordereau.getHeight() - 190,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            let factures_liste = await getFacturesFromRequete(id);
            factures_liste.map((facture, i) => {
                bordereau.drawText("        - N°" + facture.invoice_id + " en date du " + facture.invoice_date + " d’un montant de " + Math.round(facture.total_amount*100)/100 + " € TTC", {
                    x: 50,
                    y: bordereau.getHeight() - (190 + ((i+1) * 15)),
                    size: 12,
                    font: helveticaFont,
                    color: rgb(0, 0, 0),
                });
                return facture;
            })

            bordereau.drawText("- Lettre de mise en demeure de payer", {
                x: 50,
                y: bordereau.getHeight() - (215 + (factures_liste.length * 15)),
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            bordereau.drawText("- Accusé de reception de la lettre de mise en demeure de payer", {
                x: 50,
                y: bordereau.getHeight() - (240 + (factures_liste.length * 15)),
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            })

            mergedPdf.insertPage(1);
            mergedPdf.insertPage(3);


            const copiedPagesA = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            copiedPagesA.forEach((page) => mergedPdf.addPage(page));

            mergedPdf.insertPage(9);


            let factures = await getFacturesFromRequete(id);

            for (let i = 0; i < factures.length; i++) {
                await addPage(factures[i].url, factures[i].type, mergedPdf);
            }
            if(requete.miseEnDemeure) {
                await addPage(requete.miseEnDemeure.url, requete.miseEnDemeure.type, mergedPdf);
            }
            if(requete.accuseReception) {
                await addPage(requete.accuseReception.url, requete.accuseReception.type, mergedPdf);
            }
            if(requete.test) {
                const helveticaFontBold = await mergedPdf.embedFont(StandardFonts.HelveticaBold);
                mergedPdf.getPages().forEach((page, i) => {
                    //if(requete.test) {
                    page.drawText("SPECIMEN", {
                        x: 100,
                        y: 180,
                        size: 120,
                        font: helveticaFontBold,
                        color: rgb(1, 0, 0),
                        opacity: 0.5,
                        rotate: degrees(45)
                    });
                    //}
                });
            }



            const pdfBytes = await mergedPdf.save();
            await updateRequete(id, {
                nbrPagesRequete: mergedPdf.getPages().length
            });
            await uploadPDF(pdfBytes);


            let blob = new Blob([pdfBytes], {type: "application/pdf"});
            let urlBlob = URL.createObjectURL(blob);
            setUrlPdf(urlBlob);
        }


        const uploadPDF = (file) => {
            const storage = getStorage(firebaseApp);
            const storageRef = ref(storage, 'requetes/requete-' + id + '.pdf');
            const uploadTask = uploadBytesResumable(storageRef, file);
            let progress;
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        updateRequete(id, {
                            requete_pdf: downloadURL
                        })
                    });
                }
            );
        }

        async function createPdf() {
            let user = await getCurrentUser();
            let requete = await getRequete(id);
            let civile = await isDebiteurSocieteCivile(id);
            console.log(civile);
            if (requete.type === "particulier" || civile) {
                createPdfParticulier(civile);
            } else {
                createPdfEntreprise();
            }

        }

        createPdf();
    }, [id])
    return (
        <div className={"container-fluid d-flex justify-content-center align-content-center p-0 m-0 flex-column"}>
            <iframe src={urlPdf} className={"iframePdf"} title={"PDF"}/>

        </div>
    );
}
