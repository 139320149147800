import React from "react";
import Menu from "../../components/Menu";
import Btn from "../../components/Btn";
import Footer from "../../components/Footer";
import {firebaseApp} from "../../components/Firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {Helmet} from 'react-helmet';
import MenuCommissaireDeJustice from "../../components/MenuCommissaireDeJustice";
import MenuAvocat from "../../components/MenuAvocat";
const auth = getAuth(firebaseApp);


class MotDePasseOublieAvocat extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            email:'',
            error:false,
            send:false
        }
        this.changeEmail=this.changeEmail.bind(this);
        this.submitForm=this.submitForm.bind(this);
    }

    changeEmail(event){
        this.setState({email: event.target.value},function(){
            this.checkValidity();
        });

    }


    checkValidity(){
        return false;
    }

    submitForm(event){
        let error=this.checkValidity();
        if(!error){

            sendPasswordResetEmail(auth, this.state.email)
                .then(() => {
                    this.setState({send:true});
                    this.setState({error:false});
                })
                .catch((error) => {
                    /*const errorCode = error.code;
                    const errorMessage = error.message;*/
                    this.setState({error:true});
                    this.setState({send:false});
                });
        }
        event.preventDefault();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Mot de passe oublié sur facturesimpayees.com</title>
                    <meta name="description" content="Vous avez oublié votre mot de passe sur facturesimpayees.com? Utilisez notre formulaire pour réinitialiser votre mot de passe et accéder à nouveau à votre compte pour gérer vos factures impayées."/>
                    <meta name="keywords" content="mot de passe oublié, compte, factures impayées, plateforme, réinitialiser, facturesimpayees.com"/>
                </Helmet>
                <MenuAvocat />
                <main className={"Main"}>
                    <div className={"container py-5 px-5"}>
                        <h1 className={"titre justify-content-center align-items-center"}>Mot de passe oublié</h1>
                        <div className={"baseline"}>Entrez votre adresse email. Un mail vous sera envoyé avec les instructions pour récupérer votre mot de passe.</div>
                        {this.state.error?<div className={"alert alert-danger mt-2"}>Il n'existe pas de compte pour votre email ou vous avez fait trop de demandes.</div>:null}
                        {this.state.send?<div className={"alert alert-primary mt-2"}>Un mail vient de vous être envoyé avec un lien pour réinitialiser votre mot de passe.</div>:null}
                        <form className={"mt-4 form-default"} id={"formInscription"} onSubmit={this.submitForm} name={"formInscription"}>
                            <div className={"row g-3"}>
                                <div className={"col-12"}>
                                    <input type={"email"} className={"form-control"} id={"email"}
                                           placeholder={"Adresse email"} value={this.state.email} onChange={this.changeEmail} required />
                                </div>
                                <div className={"col-12"}>
                                    <div className={"d-flex flex-column flex-md-row"}>
                                        <Btn className={"btn_avocat "} texte={"Valider"} iconeDroite={"fleche"} type={"submit"} />
                                        <a href={"/connexion"} className={"lien-secondaire d-flex justify-content-center align-items-center ps-0 ps-md-4 pt-3 pt-md-0"}>Connexion</a>
                                        <a href={"/inscription"} className={"lien-secondaire d-flex justify-content-center align-items-center ps-0 ps-md-4 pt-3 pt-md-0"}>Inscription</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default MotDePasseOublieAvocat;
