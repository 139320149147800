import React, {useEffect, useState} from "react";
import {Helmet} from 'react-helmet';
import DataTable from 'react-data-table-component';
import {ButtonGroup, Button} from "react-bootstrap";
import {DebounceInput} from "react-debounce-input";
import {
    accepterProposition,
    getRequetesCommissairedeJusticeEnAttente,
    getTJ,
    refuserProposition, validerAchatProposition
} from "../../class/Requete";
import {getCurrentUser, getUserFromUid} from "../../class/User";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {getElement} from "bootstrap/js/src/util";
import Btn from "../../components/Btn";
import {stripeDirectGetSession} from "../../class/Abonnement";
import {urlSite} from "../../class/Config";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};


const columns = [
    {
        name: 'Identifiant',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Forme juridique créancier',
        selector: row => row.formeJuridiqueCreancier,
        sortable: true,
    },
    {
        name: 'Forme juridique débiteur',
        selector: row => row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleFormeJuridique : capitalizeFirstLetter(row.type),
        sortable: true,
    },
    {
        name: 'Tribunal',
        selector: row => row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleGreffe : row.Tribunal,
        sortable: true,
    },
    {
        name: 'Montant de la créance',
        selector: row => row.totalRestant.toFixed(2) + ' €',
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => (
            console.log(row.dateCreation),
                <><ButtonGroup>
                    <Button variant="success" onClick={() => {
                        openModal(document.getElementById("validation_accepter" + row.id));
                    }}>Accepter</Button>
                    <Button variant="danger" onClick={() => {
                        openModal(document.getElementById("validation_refuser" + row.id));
                    }}>Refuser</Button>
                    <Button variant="primary" onClick={() => {
                        openModal(document.getElementById("modal_voir_proposition_" + row.id));
                    }}>
                        <img src="/assets/img/eye.svg" alt="Voir" width="25" height="25"/>
                    </Button>
                </ButtonGroup>
                    <Modal id={"modal_voir_proposition_" + row.id} titre={"Proposition"}
                           body={<div>
                               <ul>
                                   <li>Identifiant du dossier : {row.id}</li>
                                   <li>Forme juridique du créancier : {row.formeJuridiqueCreancier}</li>
                                   <li>Forme juridique du débiteur
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleFormeJuridique : capitalizeFirstLetter(row.type)}</li>
                                   <li>Tribunal délivrant l’injonction de payer
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleGreffe : row.Tribunal}</li>
                                   <li>Date de la demande : {formatDate(row.dateCreation._seconds)}</li>
                               </ul>
                               <strong>Montant de la créance : {row.totalRestant.toFixed(2)} €</strong>
                           </div>}>
                    </Modal>
                    <Modal id={"validation_accepter" + row.id} titre={"Validation"}
                           body={<div>
                               <p>Êtes-vous sûr de vouloir accepter cette proposition ?</p>
                               <strong>L'acceptation vous engage à prendre contact avec le client dans les 48h.</strong><br/><br/>
                               <ul>
                                   <li>Identifiant du dossier : {row.id}</li>
                                   <li>Forme juridique du créancier : {row.formeJuridiqueCreancier}</li>
                                   <li>Forme juridique du débiteur
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleFormeJuridique : capitalizeFirstLetter(row.type)}</li>
                                   <li>Tribunal délivrant l’injonction de payer
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleGreffe : row.Tribunal}</li>
                                   <li>Date de la demande : {formatDate(row.dateCreation._seconds)}</li>
                               </ul>
                               <strong>Montant de la créance : {row.totalRestant.toFixed(2)} €</strong>
                           </div>}>
                        <Btn className={"btn-vert "} texte="Accepter" onClick={async () => {
                            closeModal(document.getElementById("validation_accepter" + row.id));
                            let user = await getCurrentUser();
                            let userInfo = await getUserFromUid(user.uid);
                            console.log(userInfo.Nom);
                            if (userInfo.Prenom !== "" && userInfo.Nom) {
                                let checkout_session = await accepterProposition(row.id);
                                document.location.href = checkout_session.url;
                            } else {
                                openModal(document.getElementById("modal_completer_infos"));
                            }


                            /*if(message.message==="Proposition déjà acceptée") {
                                alert("La proposition a déjà été acceptée par un commissaire de justice");
                                document.location.reload();
                            }else{
                                document.location.href = "/dashboard/mes-dossiers";
                            }*/
                        }}/>
                        <Btn texte="Annuler" className={"btn-vert "} onClick={async () => {
                            closeModal(document.getElementById("validation_accepter" + row.id));
                        }}/>
                    </Modal>
                    <Modal id={"validation_refuser" + row.id} titre={"Refus"}
                           body={<div>
                               <p>Êtes-vous sûr de vouloir refuser cette proposition ?</p>
                               <strong>Cette action est irreversible, vous ne pourrez plus avoir accès à cette
                                   proposition.</strong><br/><br/>
                               <ul>
                                   <li>Identifiant du dossier : {row.id}</li>
                                   <li>Forme juridique du créancier : {row.formeJuridiqueCreancier}</li>
                                   <li>Forme juridique du débiteur
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleFormeJuridique : capitalizeFirstLetter(row.type)}</li>
                                   <li>Tribunal délivrant l’injonction de payer
                                       : {row.type === 'entreprise' ? row.ficheIdentiteDebiteur.Data.LibelleGreffe : row.Tribunal}</li>
                                   <li>Date de la demande : {formatDate(row.dateCreation._seconds)}</li>
                               </ul>
                               <strong>Montant de la créance : {row.totalRestant.toFixed(2)} €</strong>
                           </div>}>
                        <Btn texte="Accepter" onClick={async () => {
                            closeModal(document.getElementById("validation_refuser" + row.id));
                            refuserProposition(row.id).then(() => {
                                document.location.reload();
                            });
                        }}/>
                        <Btn texte="Annuler" onClick={async () => {
                            closeModal(document.getElementById("validation_refuser" + row.id));
                        }}/>
                    </Modal>
                    <Modal id={"modal_completer_infos"} titre={"Compléter les informations."}
                           body={<div>
                               <p>Vous devez compléter les informations de votre société pour accepter une
                                   proposition.</p>
                           </div>}>
                        <Btn className={"btn-vert "} texte="Compléter les informations" onClick={async () => {
                            document.location.href = "/dashboard/ma-societe";
                        }}/>
                    </Modal>
                </>
        ),
    },
];

const customStyles = {
    table: {
        style: {
            padding: 0,
            margin: 0,
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            font: 'bold 1.7em Inter',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            font: 'normal 1.3em Inter',
        },
    },
};

export default function Propositions() {
    const [filterText, setFilterText] = useState('');
    const [pending, setPending] = useState(true);
    const [propositions, setPropositions] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {

        //get url params
        const urlParams = new URLSearchParams(window.location.search);
        const session_id = urlParams.get('session_id');
        if (session_id) {
            await validerAchatProposition(session_id);
        }


        let data = await getRequetesCommissairedeJusticeEnAttente();

        if (data.length > 0) {

            data = await Promise.all(data.map(async (element) => {
                if (element.type !== 'entreprise') {
                    let tj = await getTJ(element.CodePostalDebiteur);
                    element.Tribunal = tj.ville;
                }
                return element;

            }));
        }


        setPropositions(data);
        setLoading(false);
        setPending(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filterText !== "") {
            const filteredData = propositions.filter(
                item => item.raisonSocialeDebiteur?.toLowerCase().includes(filterText.toLowerCase()) ||
                    item.PrenomDebiteur?.toLowerCase().includes(filterText.toLowerCase()) ||
                    item.NomDebiteur?.toLowerCase().includes(filterText.toLowerCase()) ||
                    item.VilleDebiteur?.toLowerCase().includes(filterText.toLowerCase()) ||
                    item.totalRestant?.toString().includes(filterText)
            );
            setPropositions(filteredData);
        } else {
            fetchData();
        }
    }, [filterText]);

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className={"texte-standard input-search-tabs"}>
                Rechercher :&nbsp;
                <DebounceInput
                    debounceTimeout={1000}
                    className={""}
                    type={"search"}
                    onChange={e => setFilterText(e.target.value)}
                    value={filterText}
                />
            </div>
        );
    }, [filterText]);

    return (
        <div className="container-fluid justify-content-start align-items-center d-flex flex-column">
            <Helmet>
                <title>Commissaire de justice - Propositions</title>
                <meta name="description"
                      content="Retrouvez ici toutes les propositions de dossiers que vous avez reçues sur facturesimpayees.com"/>
                <meta name="keywords" content="commissaire de justice, propositions"/>
            </Helmet>
            {loading?<img alt={"Chargement en cours ..."} width={50} src={urlSite+"/assets/img/spinner_gris.svg"}/>:<div className={"justify-content-start align-items-start w-100"}>
                <h2>Propositions</h2>
                <p>Rendez-vous dans "<a href={"/dashboard/mes-dossiers"}>Mes dossiers</a>" pour visualiser les dossiers que vous avez acheté.</p>
                {propositions.length>0?<DataTable
                    columns={columns}
                    data={propositions}
                    defaultSortFieldId={1}
                    customStyles={customStyles}
                    pagination
                    progressPending={pending}
                    progressComponent={<div className="spinner-border" role="status"></div>}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent={"Aucune proposition en attente"}
                />:<p>Aucune proposition disponible pour le moment</p>}
            </div>}
        </div>
    );
}
