import React from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet';

export default function PolitiqueViePrivee() {
    return (
        <div>
            <Helmet>
                <title>Politique vie privée - facturesimpayees.com</title>
                <meta name="description"
                      content="Politique vie privée - facturesimpayees.com"/>
                <meta name="keywords" content="Politique vie privée, facturesimpayees.com"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container mt-10"}>
                    <h1 className="text-center mb-10">Politique de vie privée</h1>
                    <div className={"content-text-justify"}>
                        <p className="">La présente politique de confidentialité a pour objet de décrire les modalités
                            selon lesquelles la société Factures Impayées, ci-après dénommée "le Site", collecte,
                            utilise et protège les données personnelles des utilisateurs de son site internet,
                            accessible à l'adresse URL suivante : facturesimpayees.com.</p>

                        <p className="">Le Site s'engage à respecter la confidentialité des informations fournies par
                            les utilisateurs. Les informations recueillies sur le Site sont destinées à l'usage exclusif
                            du Site.</p>

                        <p className=""> Les données personnelles collectées sur le Site sont les suivantes : nom,
                            prénom, adresse postale, adresse électronique, numéro de téléphone.</p>

                        <p className=""> Les données personnelles collectées sur le Site sont utilisées dans le but de
                            fournir les services proposés par le Site, de gérer les demandes d'informations ou de
                            contact et pour l'envoi d'informations commerciales.</p>

                        <p className=""> Le Site s'engage à ne pas vendre, louer ni céder les données personnelles des
                            utilisateurs à des tiers.</p>

                        <p className=""> Les utilisateurs disposent d'un droit d'accès, de modification, de
                            rectification et de suppression des données les concernant. Ils peuvent exercer ce droit en
                            envoyant un courrier électronique à l'adresse suivante : contact@facturesimpayees.com.</p>

                        <p className=""> Le Site s'engage à prendre toutes les mesures raisonnables pour protéger les
                            données personnelles des utilisateurs contre toute destruction, perte, altération,
                            divulgation, attaque, accès non autorisé ou toute autre forme de mauvaise utilisation.</p>


                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
