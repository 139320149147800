import { urlFunction, urlSite} from "./Config";
import { render } from '@react-email/render';
import { Button } from '@react-email/button';
import { Container } from '@react-email/container';
import { Head } from '@react-email/head';
import { Hr } from '@react-email/hr';
import { Html } from '@react-email/html';
import { Img } from '@react-email/img';
import { Preview } from '@react-email/preview';
import { Section } from '@react-email/section';
import { Text } from '@react-email/text';
import * as React from 'react';

const fontFamily =
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';

const main = {
    backgroundColor: '#ffffff',
};

const container = {
    margin: '0 auto',
    padding: '20px 0 48px',
};

const logo = {
    margin: '0 auto',
};

const paragraph = {
    fontFamily,
    fontSize: '16px',
    lineHeight: '26px',
};

const btnContainer = {
    textAlign: 'center'
};

const button = {
    fontFamily,
    backgroundColor: '#5F51E8',
    borderRadius: '3px',
    color: '#fff',
    fontSize: '16px',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'block',
};

const hr = {
    borderColor: '#cccccc',
    margin: '20px 0',
};

const footer = {
    fontFamily,
    color: '#8898aa',
    fontSize: '12px',
};

const Email=(props)=>{
    return (
        <Html>
            <Head />
            <Preview>
                {props.preview}
            </Preview>
            <Section style={main}>
                <Container style={container}>
                    <Img
                        src={urlSite+"assets/img/logo.png"}
                        width="170"
                        height="50"
                        alt="Factures impayées"
                        style={logo}
                    />
                    <Text style={paragraph}>Bonjour {props.prenom},</Text>
                    <Text style={paragraph}>
                        {props.contenu}
                    </Text>
                    {props.isButton?<Section style={btnContainer}>
                        <Button pX={12} pY={12} style={button} href={props.url}>
                            Get started
                        </Button>
                    </Section>:null}
                    <Text style={paragraph}>
                        Cordialement,
                        <br />
                        L'équipe facturesimpayees.com
                    </Text>
                    <Hr style={hr} />
                    <Text style={footer}>408 Warren Rd - San Mateo, CA 94402</Text>
                </Container>
            </Section>
        </Html>
    );
}

const sendEmailTest=async (emailFrom,email,subject,preview,prenom,contenu,isButton,url)=>{
    const emailHtml = render(<Email />, {
        emailFrom:emailFrom,
        email:email,
        subject:subject,
        preview:preview,
        prenom:prenom,
        contenu:contenu,
        isButton:isButton,
        url:url
    });
    await sendEmail(emailFrom,email,subject,emailHtml);
    console.log(emailHtml);
}

const sendEmail = async (from,to,subject,html) => {
    let response=await fetch(urlFunction + "/sendMail", {
        method: "POST",
        body: JSON.stringify({
            from: from,
            to:to,
            subject:subject,
            html:html
        })
    });
    if (response.ok) {
        let json=await response.json();
        //window.open(json.url);
        return json;
    }else{
        return false;
    }

}

const addEmailWait = async (email) => {
    let response=await fetch(urlFunction + "/addEmailWait", {
        method: "POST",
        body: JSON.stringify({
            email: email,
        })
    });
    if (response.ok) {
        let json=await response.json();
        return json;
    }else{
        return false;
    }

}


export {
    sendEmail,
    sendEmailTest,
    addEmailWait
};
