import React, {useEffect, useState} from "react";

import {useParams} from "react-router-dom";
import {addFacture, getFacturesFromRequete, getRequete} from "../class/Requete";
import Btn from "../components/Btn";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {firebaseApp} from "../components/Firebase";
import {PDFDocument} from 'pdf-lib';

export default function Upload() {
    let {id} = useParams();
    const [canUpload, setCanUpload] = useState(false);
    const [factures, setFactures] = useState([]);
    const [listFactures, setListFactures] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    async function uploadFactures(endLoading) {
        const storage = getStorage(firebaseApp);

        // Fusionner les factures en PDF si nécessaire
        const pdfsFusionnes = await fusionnerFacturesEnPDF(listFactures); // Utilisez la fonction de fusion ici

        let progress = new Array(pdfsFusionnes.length).fill(0);

        pdfsFusionnes.forEach((pdfBlob, index) => {
            const storageRef = ref(storage, `factures/${Date.now().toString(36) + Math.random().toString(36).substring(2)}.pdf`);
            const uploadTask = uploadBytesResumable(storageRef, pdfBlob);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Mise à jour de la progression pour ce fichier
                    progress[index] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    let totalProgress = progress.reduce((a, b) => a + b, 0) / pdfsFusionnes.length;
                    setUploadProgress(totalProgress);
                    if (totalProgress === 100) {
                        setUploadProgress(0);
                    }

                    console.log(`Upload is ${totalProgress}% done`);
                },
                (error) => {
                    // Gérer les erreurs ici
                    console.error("Error uploading file:", error);
                },
                async () => {
                    // Upload complété avec succès, obtenir l'URL de téléchargement
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    console.log('File available at', downloadURL);
                    await addFacture(id, downloadURL, "application/pdf");
                }
            );
        });
        endLoading();
        // Nettoyer les fichiers temporaires
        listFactures.forEach((facture) => {
            facture.forEach((page) => {
                URL.revokeObjectURL(page);
            });
        });
        setListFactures([]);
    }

    async function fusionnerFacturesEnPDF(listFactures) {
        const pdfsFusionnes = [];

        for (const facture of listFactures) {
            const pdfDoc = await PDFDocument.create();

            for (const pageFile of facture) {
                if (pageFile.type === 'application/pdf') {
                    // Charge le document PDF existant
                    const existingPdfBytes = await pageFile.arrayBuffer();
                    const existingPdfDoc = await PDFDocument.load(existingPdfBytes);

                    // Copie toutes les pages du document existant dans le nouveau document
                    const numPages = existingPdfDoc.getPageCount();
                    for (let i = 0; i < numPages; i++) {
                        const [copiedPage] = await pdfDoc.copyPages(existingPdfDoc, [i]);
                        pdfDoc.addPage(copiedPage);
                    }
                } else {
                    // Traite les images ici comme précédemment montré
                    const pageBytes = await pageFile.arrayBuffer();
                    let pageImage;

                    switch (pageFile.type) {
                        case 'image/jpeg':
                            pageImage = await pdfDoc.embedJpg(pageBytes);
                            break;
                        case 'image/png':
                            pageImage = await pdfDoc.embedPng(pageBytes);
                            break;
                        default:
                            throw new Error(`Le format de fichier ${pageFile.type} n'est pas supporté.`);
                    }

                    const page = pdfDoc.addPage([pageImage.width, pageImage.height]);
                    page.drawImage(pageImage, {x: 0, y: 0, width: pageImage.width, height: pageImage.height});
                }
            }

            const pdfBytes = await pdfDoc.save();
            pdfsFusionnes.push(new Blob([pdfBytes], {type: 'application/pdf'}));
        }

        return pdfsFusionnes;
    }

    useEffect(() => {
        const getData = async () => {
            let facturesElements = await getFacturesFromRequete(id);
            setFactures(facturesElements);

            getRequete(id).then((requete) => {
                console.log(requete);
                setCanUpload(true);
            }).catch((error) => {
                console.log(error);
            });

        }
        getData();
    }, [id]);
    return (canUpload && (factures.length < 30 || factures.length === undefined) ?
            <div className={"container-fluid d-flex justify-content-center align-content-center flex-column"}>
                <div className={"container-fluid d-flex justify-content-center align-content-center p-4 flex-column"}>
                    {uploadProgress > 0 ? <div style={{width: "100%", backgroundColor: "#ccc"}}>
                        <div style={{height: "20px", backgroundColor: "#4CAF50", width: `${uploadProgress}%`}}></div>
                    </div> : null}
                    <Btn texte={"Ajouter une nouvelle facture"} onClick={() => {
                        setListFactures([...listFactures, []]);
                    }}/>

                    {listFactures.map((facture, indexFacture) => {
                        return (
                            <div key={indexFacture}
                                 className={"container-fluid d-flex gap-2 border-2 border p-2 m-1 flex-column"}>
                                {facture.map((page, indexPage) => {
                                    const isPDF = page.type === "application/pdf";
                                    const pageURL = isPDF ? "" : URL.createObjectURL(page); // Crée l'URL uniquement pour les images

                                    return (
                                        <div key={indexPage} className="d-flex align-items-center mb-2">
                                            {isPDF ? (
                                                <div style={{marginRight: "10px"}}>
                                                    <a href={URL.createObjectURL(page)} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <img src="/assets/img/pdf.png" alt={`PDF ${indexPage + 1}`}
                                                             style={{width: "100px", height: "auto"}}/>
                                                    </a>
                                                </div>
                                            ) : (
                                                <img src={pageURL} alt={`Page ${indexPage + 1}`}
                                                     style={{width: "100px", height: "auto", marginRight: "10px"}}/>
                                            )}

                                            <Btn texte={"Supprimer"} onClick={() => {
                                                const updatedFactures = listFactures.map((facture, fIndex) => {
                                                    if (fIndex === indexFacture) {
                                                        return facture.filter((_, pIndex) => pIndex !== indexPage);
                                                    }
                                                    return facture;
                                                });
                                                setListFactures(updatedFactures);
                                                if (!isPDF) {
                                                    URL.revokeObjectURL(pageURL); // Nettoyer l'URL créée pour les images
                                                }
                                            }}/>
                                        </div>
                                    );
                                })}
                                <div className={"container-fluid d-flex flex-row gap-2 justify-content-center flex-column"}>
                                    <Btn texte={"Ajouter une page"} onClick={() => {
                                        document.getElementById("facture" + indexFacture).click();
                                    }}/>
                                    {/* Ajoutez ce bouton pour supprimer toute la facture */}
                                    <Btn texte={"Supprimer cette facture"} onClick={() => {
                                        const updatedFactures = listFactures.filter((_, fIndex) => fIndex !== indexFacture);
                                        setListFactures(updatedFactures);
                                    }}/>
                                </div>
                                <input
                                    type="file"
                                    accept="image/jpeg, image/png, application/pdf"
                                    id={"facture" + indexFacture}
                                    className={"d-none"}
                                    onChange={(event) => {
                                        const newPage = event.target.files[0]; // La nouvelle page à ajouter
                                        if (newPage && newPage.size > 10 * 1024 * 1024) {
                                            alert("Le fichier est trop volumineux. La taille maximale est de 10 Mo.");
                                            return; // Ne pas ajouter le fichier s'il est trop volumineux
                                        }
                                        const updatedFactures = listFactures.map((facture, fIndex) => {
                                            if (fIndex === indexFacture) {
                                                return facture ? [...facture, newPage] : [newPage];
                                            }
                                            return facture;
                                        });

                                        setListFactures(updatedFactures);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                {listFactures.length>0?<Btn loading={true} texte={"Envoyer les factures"} onClick={async (endLoading) => {
                    uploadFactures(endLoading);
                }}/>:null}
            </div> : null
    );
}
