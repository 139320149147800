import React, {useEffect, useState} from "react";
import Footer from "../../components/Footer";
import {Helmet} from 'react-helmet';
import Propositions from "./Propositions";
import MenuCommissaireDeJustice from "../../components/MenuCommissaireDeJustice";
import {getCurrentUser, getUserFromUid} from "../../class/User";
import {getAbonnementPersonnaliseFromUid, getPortalURL} from "../../class/Abonnement";
import {getRequetesFromUserId} from "../../class/Requete";
import MesDossiers from "./MesDossiers";
import MonCompte from "../Dashboard/MonCompte";
import MaSociete from "../Dashboard/MaSociete";
import {urlSite, urlSiteCommissaireDeJustice} from "../../class/Config";


export default function DashboardCommissaireDeJustice(props) {
    const [isConnected, setIsConnected] = useState(false);
    const [userInfo, setUserInfo] = useState(false);
    useEffect(() => {
        const getData = async () => {

            let user = await getCurrentUser();

            if (user !== null) {
                setUserInfo(await getUserFromUid(user.uid));
                setIsConnected(true);
            } else {

                setIsConnected(false);
                document.location.href = "/connexion";
            }
        }
        getData();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Tableau de bord sur facturesimpayees.com</title>
                <meta name="description"
                      content="Accédez à toutes les fonctionnalités de facturesimpayees.com depuis votre tableau de bord."/>
                                      <meta name="keywords"
                      content="tableau de bord, fonctionnalités, factures impayées, plateforme, paiements, facturesimpayees.com"/>
            </Helmet>
            <MenuCommissaireDeJustice dashboard={true}/>
            <main className={"Main dashboard-zone"}>
                <div className={"d-flex flex-lg-row flex-column dashboard-main container-fluid"}>
                    <nav className={"nav-dashboard"}>
                        <ul className={"gap-3 d-flex flex-lg-column flex-md-row ul-dashboard-menu"}>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/propositions"}
                                                                     className={"lienMenuDashboard " + (props.page === "propositions" ? " active-lien" : null)}>Propositions</a>
                            </li>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/mes-dossiers"}
                                                                     className={"lienMenuDashboard " + (props.page === "mes-dossiers" ? " active-lien" : null)}>Mes
                                dossiers</a>
                            </li>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/mon-compte"}
                                                                     className={"lienMenuDashboard " + (props.page === "mon-compte" ? " active-lien" : null)}>Mon
                                compte</a>
                            </li>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/ma-societe"}
                                                                     className={"lienMenuDashboard " + (props.page === "ma-societe" ? " active-lien" : null)}>Ma
                                société</a>
                            </li>
                            <li className={"lienMenuDashboardLi"}><span onClick={() => {
                                getPortalURL(userInfo.stripe_customer_id, urlSiteCommissaireDeJustice + "/dashboard/");
                            }}
                                                                     className={"lienMenuDashboard " + (props.page === "mes-factures" ? " active-lien" : null)}>Mes
                                factures</span>
                            </li>
                            <li className={"lienMenuDashboardLi"}><span onClick={() => {
                                getPortalURL(userInfo.stripe_customer_id, urlSiteCommissaireDeJustice + "/dashboard/");
                            }}
                                                                     className={"lienMenuDashboard " + (props.page === "mes-moyens-de-paiement" ? " active-lien" : null)}>Mes moyens de paiement</span>
                            </li>

                        </ul>
                    </nav>
                    <div className={"py-2 px-2 py-lg-5 px-lg-5 d-flex container-fluid max_width_dashboard"}>
                        {isConnected && props.page === "propositions" ? <Propositions/> : null}
                        {isConnected && props.page === "mes-dossiers" ? <MesDossiers /> : null}
                        {isConnected && props.page === "mon-compte" ? <MonCompte type={"commissaireDeJustice"} /> : null}
                        {isConnected && props.page === "ma-societe" ? <MaSociete type={"commissaireDeJustice"} /> : null}
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
