import React from "react";

const closeModal=(modal)=>{
    const backdrop = document.querySelector('.modal-backdrop.fade.show');
    modal.setAttribute('aria-hidden', 'true');
    if (backdrop !== null) {
        backdrop.classList.remove('show');
    }
    // We want to remove the show class from the modal outside of the regular DOM thread so that
    // transitions can take effect
    setTimeout(() => {
        if (modal !== null) {
            modal.classList.remove('show');
        }
    });

    // We want to set the display style back to none and remove the backdrop div from the body
    // with a delay of 500ms in order to give their transition/animations time to complete
    // before totally hiding and removing them.
    setTimeout(() => {
        modal.style.display = 'none';
        if (backdrop !== null) {
            backdrop.remove();
        }
    }, 500); // this time we specified a delay
}

const openModal=(modal)=>{
    const backdrop = document.createElement('div');
    // Remove the show from the initial classList, we will add it in the timeout
    //
    // backdrop.classList.add('modal-backdrop', 'fade', 'show');
    backdrop.classList.add('modal-backdrop', 'fade');
    document.body.classList.add('modal-open');
    document.body.appendChild(backdrop);
    modal.style.display = 'block';
    modal.setAttribute('aria-hidden', 'false', 'show');

    // We don't need to specify the milliseconds in this timeout, since we don't want a delay,
    // we just want the changes to be done outside of the normal DOM thread.
    setTimeout(() => {
        // Move adding the show class to inside this setTimeout
        modal.classList.add('show');
        // Add the show class to the backdrop in this setTimeout
        backdrop.classList.add('show');
    });
}

class Modal extends React.Component {
    /*constructor(props) {
        super(props);
    }*/
    render(){
        return(<div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ModalLongTitle">{this.props.titre}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{closeModal(document.getElementById(this.props.id))}}>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.body}
                            </div>
                            <div className="modal-footer">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>);
    }
}

export{
    Modal,
    openModal,
    closeModal
};

