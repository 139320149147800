import React, {useEffect, useState} from "react";
import {
    getAbonnementPersonnaliseFromUid,
    stripeAbonnementPersonnalise,
    stripeIsAbonnePersonnalise
} from "../../class/Abonnement";
import {addUserAffilie, getCurrentUser, getUserFromEmail, getUserFromUid, removeUserAffilie} from "../../class/User";
import Btn from "../../components/Btn";
import {Button} from "react-bootstrap";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {forEach} from "react-bootstrap/ElementChildren";


export default function MonAbonnementPersonnalise() {
    const [loading, setLoading] = useState(true);
    const [abonnement, setAbonnement] = useState({});
    const [isAbonne, setIsAbonne] = useState({});
    const [usersList, setUsers] = useState({});
    const [affilie, setAffilie] = useState("");
    const [modalTitre, setModalTitre] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [updateUsers, setUpdateUsers] = useState(false);


    const openModalMessage = (titre, message) => {
        setModalTitre(titre);
        setModalMessage(message);
        openModal(document.getElementById("erreur_email"));
    }
    const ajouterAffilie = (endLoading) => {
        /*If affilie is email*/
        if (affilie.indexOf("@") !== -1) {
            getUserFromEmail(affilie).then((data) => {
                if (data.error) {
                    openModalMessage("Erreur d'email", "Cet email n'existe pas !");
                    endLoading();
                } else {
                    addUserAffilie(data.id, abonnement.id).then((result) => {
                        if (result.error) {
                            console.log(result.error);
                            openModalMessage("Erreur", result.message);
                        } else {
                            openModalMessage("Email ajouté", "L'email a bien été ajouté.");
                        }
                    }).then(() => {
                        setTimeout(()=>{
                            setUpdateUsers(!updateUsers);
                            endLoading();
                        },1000);
                    });

                }
            });
        } else {
            openModalMessage("Erreur d'email", "Merci d'entrer une adresse email correcte.");
            endLoading();
        }
    }

    const removeAffilie = (uid) => {
        removeUserAffilie(uid, abonnement.id).then((result) => {
            if (result.error) {
                openModalMessage("Erreur", result.message);
            } else {
                openModalMessage("Email supprimé", "L'email a bien été supprimé.");
                setUpdateUsers(!updateUsers);
            }
        });
    }

    useEffect(() => {
        const getData = async () => {
            console.log("get data");
            let user = await getCurrentUser();
            let userData = await getUserFromUid(user.uid);
            let data = await getAbonnementPersonnaliseFromUid(user.uid);
            let users = {};

            for(let i=0;i<data?.utilisateurs?.length;i++){
                users[data.utilisateurs[i]] = await getUserFromUid(data.utilisateurs[i]);
            }
            setUsers(users);

            setAbonnement(data);
            let dataStripe = stripeIsAbonnePersonnalise(userData.stripe_customer_id);
            setIsAbonne(dataStripe);
            setLoading(false);
        }
        getData();
    }, [updateUsers])


    return (loading ? <div className={"container d-flex justify-content-center align-content-center"}>
                <div className="spinner-border" role="status"></div>
            </div> :
            <div className={"container-fluid justify-content-center align-items-center d-flex flex-column"}>
                <h1>Votre abonnement personnalisé</h1>
                <div>Nombre de dossiers mensuel : {abonnement.dossiers_mensuel}</div>
                <div>Tarif mensuel : {abonnement.montant_mensuel_ht} € HT</div>
                {isAbonne ? <div className={"alert alert-success mt-4"}>Statut : Votre abonnement est actif.</div> :
                    <div className={"alert alert-danger mt-4"}>Statut : Inactif en attente de paiement.</div>}
                {!isAbonne ? <Btn className={"mb-3 mt-5"} iconeDroite={"fleche"} texte={"S'abonner"}
                                  onClick={() => {
                                      stripeAbonnementPersonnalise(abonnement.montant_mensuel_ht);
                                  }} loading={true}/> : null}

                <div className={"mt-5"}>
                    <h1>Liste des comptes affiliés :</h1>
                    <div>
                        <table>
                            <thead>
                            <tr>
                                <td>Email</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(usersList).map((uid) => {
                                return <tr key={uid}>
                                    <td>{usersList[uid].email}</td>
                                    <td><Button variant={"danger"} onClick={() => {
                                        removeAffilie(uid);
                                    }}>Supprimer</Button></td>
                                </tr>
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className={"pe-5"}>
                                    <input value={affilie} onChange={(event) => {
                                        setAffilie(event.target.value);
                                    }} className={"form-control"} type={"email"} placeholder={"Email"}/>
                                </td>
                                <td>
                                    <Btn loading={true} iconeGauche={"plus"} texte={"Ajouter un compte affilié"} onClick={(endLoading) => {
                                        ajouterAffilie(endLoading);
                                    }}/>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        <Modal id={"erreur_email"} titre={modalTitre}
                               body={modalMessage}>
                            <Btn texte="OK" onClick={() => {
                                closeModal(document.getElementById("erreur_email"))
                            }}/>
                        </Modal>

                    </div>
                </div>
            </div>


    )
        ;
}
