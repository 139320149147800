const urlFunction=process.env.REACT_APP_URL_FUNCTIONS;
const urlSite=process.env.REACT_APP_URL_SITE;
const urlSiteCommissaireDeJustice=process.env.REACT_APP_URL_SITE_COMMISSAIRE_DE_JUSTICE;
const urlSiteAvocat=process.env.REACT_APP_URL_SITE_AVOCAT;
const mode=process.env.REACT_APP_MODE_ENV;

const emailFrom="raphael@atlantismultimedia.fr";

export{
    urlSiteAvocat,
    urlSiteCommissaireDeJustice,
    urlFunction,
    urlSite,
    emailFrom,
    mode
}
