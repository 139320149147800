import React, {useState} from "react";
import Menu from "../components/Menu";
import Btn from "../components/Btn";
import Footer from "../components/Footer";
import {firebaseApp} from "../components/Firebase";
import {getAuth, signInWithCustomToken} from "firebase/auth";
import {Helmet} from 'react-helmet';

const auth = getAuth(firebaseApp);

export default function Token() {
    const [token, setToken] = useState('');
    const [error, setError] = useState(false);

    const checkValidity = () => {
        return false;
    }

    const submitForm = async (event) => {
        event.preventDefault();
        let isValid = checkValidity();
        if (!isValid) {
            await signInWithCustomToken(auth, token).then(() => {
                setError(false);
                document.location.href = "/dashboard";
            })
                .catch((reason) => {
                    console.log(reason);
                });
        }

    }

    return (
        <div>
            <Helmet>
                <title>Connexion à facturesimpayees.com</title>
                <meta name="description"
                      content="Accédez à votre compte sur facturesimpayees.com pour gérer vos factures impayées en toute simplicité. Utilisez notre formulaire de connexion sécurisé pour vous connecter."/>
                <meta name="keywords"
                      content="connexion, compte, factures impayées, plateforme, sécurisé, facturesimpayees.com"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container py-5 px-5"}>
                    <h1 className={"titre justify-content-center align-items-center"}><img alt={"Inscription"}
                                                                                           src={"assets/img/connexion.png"}
                                                                                           className={"icone-titre"}
                                                                                           height={60}/> Connexion</h1>
                    <div className={"baseline"}>Connectez vous à votre compte utilisateur grâce à un token
                    </div>
                    {error && <div className={"alert alert-danger mt-2"}>Votre token n'est pas
                        correct.</div>}
                    <form className={"mt-4 form-default"} onSubmit={submitForm}>
                        <div className={"row g-3"}>
                            <div className={"col-12"}>
                                <input
                                    type={"text"}
                                    className={"form-control"}
                                    id={"token"}
                                    placeholder={"Token"}
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={"col-12"}>
                                <div className={"d-flex flex-column flex-md-row"}>
                                    <Btn texte={"Connexion"} iconeDroite={"fleche"} type={"submit"}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
