import React from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet';

export default function CGU() {
    return (
        <div>
            <Helmet>
                <title>CGU - facturesimpayees.com</title>
                <meta name="description"
                      content="CGU - facturesimpayees.com"/>
                <meta name="keywords" content="CGU"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container mt-10"}>
                    <h1 className="text-center mb-10">Conditions générales d'utilisations de facturesimpayees.com</h1>
                    <div className={"content-text-justify"}>
                        <h2>Article 1 - Champ d'application</h2>
                        <p>Les présentes conditions générales d’utilisation (ci-après les "CGU") ont pour objet de définir les modalités et conditions de mise à disposition des Services proposés par MAC2, ainsi que de définir les droits et obligations des parties dans ce cadre.</p>

                        <h2>Article 2 - Acceptation</h2>
                        <p>Tout Utilisateur reconnaît avoir pris connaissance et accepté expressément et sans réserve les présentes CGU dès lors qu’il accède au Site et utilise les Services. Les CGU sont susceptibles d'être modifiées à tout moment par MAC2. Les conditions applicables sont celles en vigueur au jour de la connexion et de l’utilisation du Site par l’Utilisateur.
                        </p>
                        <h2>Article 3 - Description des services</h2>
                        <p>Conformément à l'article 1 de la présentation du Site, MAC2 propose divers Services accessibles aux Utilisateurs professionnels, visant à faciliter la récupération de créances impayées.
                        </p>
                        <h2>Article 4 - Accès et disponibilité des services</h2>
                        <p>L’accès aux Services est possible 24h/24, 7j/7, sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement du Site et des matériels. MAC2 ne saurait être tenu responsable d’une non-disponibilité des Services pour des raisons techniques imputables à un tiers.
                        </p>
                        <h2>Article 5 - Tarification</h2>
                        <p>L’Utilisateur s’engage à payer le prix des Services conformément aux tarifs en vigueur sur le Site, et suivant les modalités précisées dans la section "formules d'abonnement".
                        </p>
                        <h2>Article 6 - Obligations et responsabilités de MAC2</h2>
                        <p>MAC2 s’engage à mettre en œuvre tous les moyens nécessaires pour garantir la meilleure qualité de service possible à l’Utilisateur. Toutefois, sa responsabilité ne pourra être engagée en cas de dommage résultant d’une utilisation incorrecte du Service par l’Utilisateur.
                        </p>
                        <h2>Article 7 - Obligations et responsabilités de l’Utilisateur</h2>
                        <p>L’Utilisateur s’engage à fournir des informations exactes lors de l’utilisation des Services. Il est responsable de l’usage qui est fait des Services et doit s'assurer du respect des dispositions légales et réglementaires en vigueur.
                        </p>
                        <h2>Article 8 - Données personnelles</h2>
                        <p>MAC2 s’engage à garantir la confidentialité des données personnelles fournies par l’Utilisateur et à les traiter dans le respect des dispositions de la législation applicable en matière de protection des données personnelles.
                        </p>
                        <h2>Article 9 - Propriété intellectuelle</h2>
                        <p>Le contenu du Site (logos, textes, éléments graphiques, vidéos, etc.) est protégé par le droit d’auteur et demeure la propriété exclusive de MAC2. Toute reproduction, copie ou utilisation sans autorisation expresse de MAC2 est interdite.
                        </p>
                        <h2>Article 10 - Résiliation</h2>
                        <p>Chaque Utilisateur peut résilier son abonnement en ligne selon les conditions spécifiées sur le Site. MAC2 se réserve le droit de suspendre ou de résilier l’accès aux Services en cas de non-respect des présentes CGU par l’Utilisateur.
                        </p>
                        <h2>Article 11 - Loi applicable et juridiction compétente</h2>
                        <p>Les présentes CGU sont régies par la loi française. Tout litige relatif à leur interprétation et/ou à leur exécution relève des tribunaux français.
                        </p>
                        <h2>Article 12 - Dispositions diverses</h2>
                        <p>Si une ou plusieurs stipulations des présentes CGU étaient déclarées non valides en application d'une loi, d'un règlement ou à la suite d'une décision devenue définitive d'une juridiction compétente, les autres stipulations garderaient toute leur force et leur portée.
                        </p>
                        <p>Les présentes CGU constituent l’intégralité de l’accord conclu entre l’Utilisateur et MAC2 concernant l’utilisation des Services. Elles annulent et remplacent tout accord antérieur relatif au même objet.</p>

                        <p>La non-revendication par MAC2 de l'une quelconque des dispositions des présentes ne saurait être interprétée comme une renonciation par celle-ci à se prévaloir à l'avenir de ladite disposition.</p>

                        <h2>Article 13 - Contact</h2>
                        <p>Pour toute question ou réclamation concernant le Site ou les Services, vous pouvez contacter MAC2 aux coordonnées indiquées dans la section "Qui sommes-nous".</p>


                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
