import React, {useEffect, useState} from "react";
import {
    getAbonnement, getAbonnementFromPriceId,
    stripeAbonnement,
    stripeAnnuleAbonnement, stripeProrataAbonnement,
    stripeRecupererAbonnement
} from "../class/Abonnement";
import Btn from "./Btn";
import {closeModal, openModal, Modal} from "./Modal";

export default function Abonnement(props) {
    const [abonnement, setAbonnement] = useState({});
    const [canRender, setCanRender] = useState(false);
    const [subscription, setSubscription] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentAbonnementId, setCurrentAbonnementId] = useState({});
    useEffect(() => {
        const getData = async () => {
            setAbonnement(await getAbonnement(props.id));
            setCanRender(true);
            setLoading(false);
            let currentAbonnement = await getAbonnementFromPriceId(props.current);
            setCurrentAbonnementId(currentAbonnement.id);
        }
        getData();
    }, [props.id, props.customer, props]);

    useEffect(() => {
        setSubscription(props.subscription);
    }, [props.subscription]);

    const abonnementStart = async () => {
        closeModal(document.getElementById("confirmation" + props.id));
        await stripeProrataAbonnement(props.currentSubscription, abonnement.stripe_id);
        props.onChange();
        //openModal(document.getElementById("confirmation_ok"+props.id));
    }

    if(loading){
        return (<div></div>)
    }else {
        return (
            <div className={"abonnement d-flex justify-content-between flex-column"}>
                <div className={"abonnementHeader"}>
                    Abonnement {abonnement.nom}
                </div>
                <div className={"abonnementOptions d-flex flex-column"}>
                    <ul className={"d-flex gap-2 flex-column justify-content-start align-items-start ps-1"}>
                        <li>Dossiers mensuels : {abonnement.dossiers_mensuels}<br /></li>
                        {canRender ? abonnement.options.map((option, i) => {
                            return (
                                <li key={i}>{option}</li>
                            )
                        }) : null}
                    </ul>
                    <Btn className={"my-4"} texte={"En savoir plus"} iconeDroite={"fleche"} onClick={() => {
                        document.location.href = "https://facturesimpayees.com/#tarifs";
                    }}/>
                </div>
                <div className={"abonnementBottom d-flex flex-column align-items-center"}>
                    <div>
                        Tarif :
                    </div>
                    <div className={"d-flex"}>
                    <span
                        className={"abonnementTarif"}>{abonnement.tarif ? abonnement.tarif.toFixed(2) : null}</span> €HT/mois
                    </div>
                    {currentAbonnementId <= props.id || !currentAbonnementId ? (abonnement.stripe_id !== props.current ? (props.current === "" ?
                        <Btn className={"my-4"} texte={"S'abonner"} iconeDroite={"fleche"} onClick={async () => {
                            props.onChange();
                            await stripeAbonnement(abonnement.stripe_id)
                            //await createPaymentLink(abonnement.stripe_id);
                        }} loading={true}/> :
                        <Btn className={"my-4"} texte={"Modifier l'abonnement"} iconeDroite={"fleche"}
                             onClick={async (endLoading) => {
                                 openModal(document.getElementById("confirmation" + props.id));
                             }} loading={true}/>) : (subscription?.cancel_at_period_end ?
                        <div className={"d-flex flex-column"}>
                            <Btn className={"my-4 btn_rouge"} texte={"Se ré-abonner"} iconeDroite={"fleche"}
                                 onClick={async (endLoading) => {
                                     props.onChange();
                                     await stripeRecupererAbonnement(props.currentSubscription, endLoading);
                                 }} loading={true}/>
                            <div
                                className={"messageLikeBtn d-flex justify-content-center align-item-center my-3"}>L'abonnement
                                a été résilié et prendra fin
                                le {new Date(subscription.current_period_end * 1000).toLocaleString()}</div>
                        </div> :
                        <Btn className={"my-4 btn_rouge"} texte={"Annuler l'abonnement"} iconeDroite={"fleche"}
                             onClick={async (endLoading) => {
                                 props.onChange();
                                 await stripeAnnuleAbonnement(props.currentSubscription, endLoading);
                             }} loading={true}/>)) : (loading ? null : <div
                        className={"messageLikeBtn d-flex justify-content-center align-item-center my-3"}>Vous ne pouvez
                        pas diminuer<br/> votre abonnement pendant le mois en cours. Annulez d'abord votre abonnement et
                        réabonnez vous ensuite.</div>)}
                </div>
                <Modal id={"confirmation" + props.id} titre={"Confirmer le changement"}
                       body={<div>Êtes vous sur de vouloir changer d'abonnement ?</div>}>
                    <Btn texte={"OK"} onClick={async () => {
                        await abonnementStart();
                    }}/>
                    <Btn texte={"Annuler"} onClick={() => {
                        closeModal(document.getElementById("confirmation" + props.id));
                    }}/>
                </Modal>

                <Modal id={"confirmation_ok" + props.id} titre={"Confirmation"}
                       body={<div>Votre abonnement a bien été modifié.</div>}>
                    <Btn texte={"OK"} onClick={async () => {
                        closeModal(document.getElementById("confirmation_ok" + props.id));
                    }}/>
                </Modal>
            </div>
        );
    }
}
