import React from "react";
import Menu from "../components/Menu";
import "../css/urbanist.css";
import "../css/styleHome.css";
import "../css/plugins.css";
import {Helmet} from 'react-helmet';
import Footer from "../components/Footer";

export default function Logo() {
    return (
        <div>
            <Helmet>
                <title>Télécharger les Logos - facturesimpayees.com</title>
                <meta name="description"
                      content="Téléchargez les logos pour vos factures et mettez en garde les mauvais payeurs sur facturesimpayees.com pour gérer efficacement vos factures impayées. Découvrez leur profil et leurs qualifications pour choisir celui qui convient le mieux à votre situation."/>
                <meta name="keywords"
                      content="huissiers, factures impayées, plateforme, profil, qualifications, choix, facturesimpayees.com"/>
            </Helmet>
            <Menu>
                <section className="py-5 text-center container">
                    <div className="row">
                        <div className="d-flex flex-column px-3">
                            <h1 className="hero-main-text mb-2 fs-60">Téléchargez nos Logos</h1>
                        </div>
                    </div>
                </section>
            </Menu>
            <main className={"Main"}>
                <section className="wrapper bg-gradient-primary">
                    <div className="container pt-12 pt-lg-8 pb-14 pb-md-17">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="download-logo-section">
                                    <h2>Téléchargez nos Logos</h2>
                                    <p>Utilisez nos logos sur vos factures pour avertir les mauvais payeurs. Cliquez sur
                                        le bouton ci-dessous pour télécharger.</p>
                                    <div className={"d-flex justify-content-center"}>
                                        <img src={"./assets/img/logo partenariat.png"} width={"500"}
                                             align={"logo factures impayées"}/>
                                    </div>
                                    <div className={"d-flex justify-content-center"}>
                                        <a href="./assets/img/logo partenariat.png" download="facturesimpayees-logo">
                                            <button className={"btn-custom btn-violet"}>Télécharger le Logo</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </div>
    )
}