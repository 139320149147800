import { getAuth } from "firebase/auth";

export const fetchWithAuth = async (url, options = {}) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        throw new Error("User is not authenticated");
    }

    const idToken = await user.getIdToken();
    const headers = {
        ...options.headers,
        'Authorization': 'Bearer ' + idToken,
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    if (!response.ok) {
        throw new Error("Failed to fetch data");
    }

    return response.json();
};

export const fetchWithAuthNotJSON = async (url, options = {}) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        throw new Error("User is not authenticated");
    }

    const idToken = await user.getIdToken();
    const headers = {
        ...options.headers,
        'Authorization': 'Bearer ' + idToken,
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    if (!response.ok) {
        throw new Error("Failed to fetch data");
    }

    return response;
};