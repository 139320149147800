import React, {useState} from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Btn from "../components/Btn";
import {sendEmail} from "../class/Email";
import {emailFrom} from "../class/Config";
import {closeModal, Modal, openModal} from "../components/Modal";
import {Helmet} from 'react-helmet';

export default function Contact() {

    const [email, setEmail] = useState("");
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [telephone, setTelephone] = useState("");
    const [objet, setObjet] = useState("");
    const [message, setMessage] = useState("");

    const submitForm = async (event) => {
        event.preventDefault();
        let html = "<div>Nouveau message envoyé depuis le formulaire de contact.<br/>" +
            "Objet : " + objet + "<br/>" +
            "Prénom : " + prenom + "<br/>" +
            "Nom : " + nom + "<br />" +
            "Téléphone : " + telephone + "<br />" +
            "Email : " + email + "<br /><br />" +
            message +
            "</div>";
        await sendEmail('"' + prenom + ' ' + nom + '" <' + email + '>', emailFrom, "Factures impayées - "+objet, html);
        openModal(document.getElementById("message_envoye"));
        setEmail("");
        setPrenom("");
        setNom("");
        setTelephone("");
        setObjet("");
        setMessage("");
    }


    return (
        <div>
            <Helmet>
                <title>Contactez-nous sur facturesimpayees.com</title>
                <meta name="description"
                      content="Besoin d'aide pour résoudre vos factures impayées? Utilisez notre formulaire de contact pour poser toutes vos questions ou demandes sur notre plateforme."/>
                <meta name="keywords"
                      content="contact, aide, factures impayées, plateforme, demande, question, formulaire"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container py-5 px-5"}>
                    <form className={"mt-4 form-default"} id={"formContact"} onSubmit={submitForm} name={"formContact"}>
                        <h1 className={"text-center mb-4"}>Contactez nous</h1>
                        <div className={"texte-gros mb-3"}>Vous pouvez contacter Factures impayées depuis le formulaire
                            de contact ci-dessous.
                        </div>
                        <div className={"row g-3"}>
                            <div className={"col-12 gap-2 d-flex flex-column"}>
                                <div className="form-group">
                                    <input type={"email"} className={"form-control"} id={"email*"}
                                           placeholder={"Adresse email"} value={email} onChange={(event) => {
                                        setEmail(event.target.value)
                                    }} required/>
                                </div>
                                <div className="form-group">
                                    <input type={"text"} className={"form-control"} id={"prenom*"}
                                           placeholder={"Prénom"} value={prenom} onChange={(event) => {
                                        setPrenom(event.target.value)
                                    }} required/>
                                </div>
                                <div className="form-group">
                                    <input type={"text"} className={"form-control"} id={"nom*"}
                                           placeholder={"Nom"} value={nom} onChange={(event) => {
                                        setNom(event.target.value)
                                    }} required/>
                                </div>
                                <div className="form-group">
                                    <input type={"text"} className={"form-control"} id={"telephone"}
                                           placeholder={"Téléphone"} value={telephone} onChange={(event) => {
                                        setTelephone(event.target.value)
                                    }}/>
                                </div>
                                <div className="form-group">
                                    <select className={"form-control"} value={objet} id={"sujet"} onChange={(event) => {
                                        setObjet(event.target.value)
                                    }} required>
                                        <option className={"select_placeholder"} value={""}>Choisissez l'objet de votre
                                            message
                                        </option>
                                        <option value={"Problème rencontré"}>Problème rencontré</option>
                                        <option value={"Contact commercial"}>Contact commercial</option>
                                        <option value={"Besoin d'aide"}>Besoin d'aide</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <textarea className={"form-control"} onChange={(event) => {
                                        setMessage(event.target.value)
                                    }} placeholder={"Mon message"} rows="10" value={message}></textarea>
                                </div>
                                <div className="form-group">
                                    <Btn texte={"Envoyer le message"} iconeDroite={"fleche"} type={"submit"}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <Modal id={"message_envoye"} titre={"Message envoyé"}
                           body={<div>Votre message a bien été envoyé</div>}>
                        <Btn texte={"OK"} onClick={() => {
                            closeModal(document.getElementById("message_envoye"));
                        }}/>
                    </Modal>

                </div>
            </main>
            <Footer/>
        </div>
    )
}
