import React, {useEffect, useRef, useState} from "react";
import {
    useParams
} from "react-router-dom";
import {doc, onSnapshot, Timestamp} from "firebase/firestore";
import {Helmet} from "react-helmet";
import Btn from "../../components/Btn";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {firebaseApp} from "../../components/Firebase";
import {openModal} from "../../components/Modal";
import {addAccuseReception, getRequete, updateRequete} from "../../class/Requete";
import {Alert} from "react-bootstrap";
import {getCurrentUser} from "../../class/User";
import {sendEmail} from "../../class/Email";

export default function Refus() {

    const {id} = useParams();
    const [refusMessage, setRefusMessage] = useState("");
    const [refusFile, setRefusFile] = useState({});


    const [loading, setLoading] = useState(true);
    const [dejaEnvoye, setDejaEnvoye] = useState(false);
    const [elementsRefus, setElementRefus] = useState({});
    const getData = async () => {
        let user = await getCurrentUser();
        getRequete(id).then((requete) => {
            if (requete.uid !== user.uid) {
                document.location.href = "/dashboard";
            }
            if (requete.refus) {
                setDejaEnvoye(true);
                setElementRefus(requete.refus);
            }
            setLoading(false);
        }).catch((error) => {
                document.location.href = "/dashboard";
                console.log(error);
            }
        );
    }
    useEffect(() => {

        getData();
    }, [])


    const submitRefus = async (e) => {
        let form = document.getElementById("formEmail");
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
        } else {
            form.classList.remove('was-validated');
            if (refusMessage !== "" && refusFile !== {}) {
                setLoading(true);
                uploadRefus();
            }
        }
    }

    const formatDate = (timestampSeconds) => {
        // Convertir le timestamp en millisecondes et créer un objet Date
        const date = new Date(timestampSeconds * 1000);

        // Extraire les composants de la date
        const day = date.getDate();
        const month = date.getMonth() + 1; // Les mois sont indexés à partir de 0
        const year = date.getFullYear();

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Formater la date en format JJ/MM/AAAA
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }


    const uploadRefus = () => {
        const uploader = document.getElementById('formFile');
        uploader.className = "opacity-100 container-fluid";
        const storage = getStorage(firebaseApp);
        const files = refusFile;
        let progress = [];
        let file = files[0];
        let type = file.type;
        let extension = "";
        if (type === "image/jpeg") {
            extension = "jpg";
        } else if (type === "image/png") {
            extension = "png";
        } else if (type === "application/pdf") {
            extension = "pdf";
        } else {
            //openModal(document.getElementById("modal_format_fichier"));
        }
        if (extension !== "") {
            const storageRef = ref(storage, 'refus/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    console.log("complete");
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        //await addAccuseReception(id, downloadURL, type);
                        await updateRequete(id, {
                            refus: {
                                message: refusMessage,
                                file: downloadURL,
                                type: type,
                                date: new Date().getTime(),
                                status: "en attente"
                            }
                        });
                        const message="<div>Une ordonnance de refus a été envoyée par un utilisateur.<br/>" +
                            "Message : "+refusMessage+"<br/>" +
                            "Date : "+formatDate(Timestamp.now().seconds)+"<br/>" +
                            "Fichier : <a href='"+downloadURL+"'>Télécharger</a></div>"+
                            "<br/><br/> Rendez vous <a href='https://adminfacturesimpayees.web.app/#/refus/"+id+"'>ici</a> pour effectuer une action. ";
                        await sendEmail("plateforme@facturesimpayees.com",process.env.REACT_APP_EMAIL,"Ordonnance de refus à traiter",message);
                        getData();
                        console.log(downloadURL);
                    });
                }
            );
        }
    }
    return (loading ? <div className={"container d-flex justify-content-center align-content-center"}>
            <div className="spinner-border" role="status"></div>
        </div> : <div className={"container-fluid justify-content-start align-items-center d-flex flex-column"}>
            <Helmet>
                <title>Refus de votre requête</title>
                <meta name="description"
                      content="Refus de votre requête"/>
                <meta name="keywords"
                      content="demandes de paiement, historique, factures impayées, plateforme, suivi, mises à jour, facturesimpayees.com"/>
            </Helmet>
            {dejaEnvoye ? <div className={"row align-content-center justify-content-center d-flex"}>
                <div className={"texte-gros mb-5 text-center"}>Votre refus a été envoyé aux équipes de
                    facturesimpayees.com.
                </div>
                <div className={"texte-standard mb-5 text-center"}>Message : {elementsRefus.message}</div>
                <div className={"texte-standard mb-5 text-center"}>Date d'envoi
                    : {formatDate(elementsRefus.date/1000)}</div>
                <div className={"texte-standard mb-5 text-center"}><a className={"btn-violet btn-custom"}
                                                                      href={elementsRefus.file} target={"_blank"}>Ordonnance
                    de refus</a></div>
                {elementsRefus.status === "en attente" ? <Alert variant={"warning"}>
                    Votre dossier est en cours d'analyse par nos équipes. Vous recevrez une notification par mail dès
                    que votre dossier sera traité.
                </Alert> : null}
                {elementsRefus.status === "valide" ? <Alert variant={"success"}>
                    Votre dossier a été validé par nos équipes. Vous allez être recrédité dans les prochains jours si
                    cela n'est pas déjà fait.
                </Alert> : null}
                {elementsRefus.status === "refuse" ? <Alert variant={"danger"}>
                    Votre dossier a été refusé par nos équipes. Vous ne serez pas recrédité. Pour plus d'information
                    contactez-nous via le formulaire de contact.
                </Alert> : null}
            </div> : <div className={"row justify-content-center align-items-center"}>
                <h2 className={"dashboard-title mb-3"}>J'ai reçu une ordonnance de refus</h2>
                <div className={"texte-standard mb-5"}>Vous avez reçu une notification de refus concernant votre
                    demande. Vous avez la possibilité de télécharger cette notification dans cette section. Si le motif
                    du refus est lié à facturesimpayees.com, votre demande sera recréditée.
                </div>
                <form className={"form-default needs-validation"} id={"formEmail"} name={"formEmail"} noValidate={true}>
                    <div className={"mb-3"}>
                        <label htmlFor="refus" className="form-label">Motif du refus</label>
                        <textarea className="form-control" id="refus" rows="3" value={refusMessage} onChange={(e) => {
                            setRefusMessage(e.target.value);
                        }}></textarea>
                    </div>
                    <div className={"mb-3"}>
                        <label htmlFor="refus" className="form-label">Pièce jointe</label>
                        <input className="form-control" type="file" id="formFile" required onChange={(e) => {
                            setRefusFile(e.target.files);
                        }}/>
                    </div>
                    <div className={"d-flex flex-column flex-md-row"}>
                        <Btn texte={"Envoyer"} iconeDroite={"fleche"} onClick={submitRefus}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}


