import React, {useEffect, useState} from "react";
import Menu from "../../components/Menu";
import Btn from "../../components/Btn";
import Footer from "../../components/Footer";
import {createUser, getCurrentUser} from "../../class/User";
import {Helmet} from 'react-helmet';
import {useParams} from "react-router-dom";
import {urlSite} from "../../class/Config";
import MenuCommissaireDeJustice from "../../components/MenuCommissaireDeJustice";

export default function InscriptionCommissaireDeJustice() {
    const [email, setEmail] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [identifiantAffiliation, setIdentifiantAffiliation] = useState('');
    const [emailParrainTmp, setEmailParrain] = useState('');
    const [loading, setLoading] = useState(false);
    const [canInscription, setCanInscription] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    let {emailParrain} = useParams();

    useEffect(() => {
        const getData = async () => {
            let user = await getCurrentUser();
            if (user) {
                setCanInscription(false);
                document.location.href='/dashboard';
            } else {
                setCanInscription(true);
            }
            setEmailParrain(emailParrain);
        }
        getData();
    }, [emailParrain]);

    useEffect(() => {
        const checkValidity2 = () => {
            let confirm_password = document.getElementById("passwordConfirm");
            let error = true;
            if (confirm_password) {
                if (password !== confirmPassword) {
                    confirm_password.setCustomValidity("Les mots de passe ne correspondent pas.");
                } else if (confirmPassword === "") {
                    confirm_password.setCustomValidity('Veuillez renseigner ce champs.');
                } else {
                    confirm_password.setCustomValidity('');
                    error = false;
                }
            }
            return error;
        }
        checkValidity2();
    }, [email, password, confirmPassword, identifiantAffiliation]);

    const checkValidity = () => {
        let confirm_password = document.getElementById("passwordConfirm");
        let error = true;
        if (confirm_password) {
            if (password !== confirmPassword) {
                confirm_password.setCustomValidity("Les mots de passe ne correspondent pas.");
            } else if (confirmPassword === "") {
                confirm_password.setCustomValidity('Veuillez renseigner ce champs.');
            } else {
                confirm_password.setCustomValidity('');
                error = false;
            }
        }
        if (!acceptTerms) {
            alert('Vous devez accepter les CGV et CGU pour vous inscrire.');
            error = true;
        }
        return error;
    }


    const submitForm = async (event) => {
        setLoading(true);
        event.preventDefault();
        let error = checkValidity();
        if (!error) {
            createUser(email, password, '','','Commissaire de justice'
            ).then((result) => {
                if (result.error) {
                    alert(result.error);
                    setLoading(false);
                } else {
                    // Suivi Google Ads
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-16499690018/jNBHCOm4-c0ZEKKU1bs9'
                    });

                    // Suivi Meta Pixel Facebook
                    window.fbq('track', 'Lead');

                    // Redirection vers le tableau de bord
                    document.location.href = "/dashboard";
                }
            });

        }

    }


    return (<div>
            <Helmet>
                <title>Créez votre compte sur facturesimpayees.com</title>
                <meta name="description"
                      content="Inscrivez-vous sur facturesimpayees.com pour accéder à notre plateforme de gestion de factures impayées. Créez votre compte en quelques minutes pour gérer vos paiements en toute simplicité."/>
                <meta name="keywords"
                      content="inscription, compte, factures impayées, plateforme, paiements, facturesimpayees.com"/>
            </Helmet>
            <MenuCommissaireDeJustice/>
            <main className={"Main"}>
                {canInscription ? <div className={"container py-5 px-5"}>
                    <h1 className={"titre justify-content-center align-items-center"}>Inscription
                    </h1>
                    <div className={"baseline"}>Créez un compte pour utiliser facturesimpayees.com</div>
                    <form className={"mt-4 form-default"} id={"formInscription"} onSubmit={submitForm}
                          name={"formInscription"}>
                        <div className={"row g-3"}>
                            <div className={"col-12"}>
                                <input type={"email"} className={"form-control"} id={"email"}
                                       placeholder={"Adresse email"} value={email}
                                       onChange={(e) => {
                                           setEmail(e.target.value);
                                       }} required/>
                            </div>
                            <div className={"col-12  d-flex"}>
                                <input type={showPassword ? "text" : "password"} className={"form-control flex-grow-1"} id={"password"}
                                       placeholder={"Mot de passe"} value={password}
                                       onChange={(e) => {
                                           setPassword(e.target.value);
                                       }} required minLength={5} maxLength={100}/>
                                <button
                                    type="button"
                                    className="btn btn-link ms-2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>}
                                </button>
                            </div>
                            <div className={"col-12  d-flex"}>
                                <input type={showPassword ? "text" : "password"} className={"form-control flex-grow-1"} id={"passwordConfirm"}
                                       placeholder={"Confirmation du mot de passe"} value={confirmPassword}
                                       onChange={(e) => {
                                           setConfirmPassword(e.target.value);
                                       }} minLength={5} maxLength={100}/>
                                <button
                                    type="button"
                                    className="btn btn-link ms-2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>}
                                </button>
                            </div>
                            <div className={"col-12"}>
                                <input className={"form-check-input"} type={"checkbox"} id={"terms"} checked={acceptTerms}
                                       onChange={(e) => {
                                           setAcceptTerms(e.target.checked);
                                       }} required/>
                                <label className={"form-check-label ms-2"} htmlFor={"terms"}>J'accepte les <a href="/cgv">CGV</a> et <a href="/cgu">CGU</a></label>
                            </div>
                            <div className={"col-12"}>
                                <div className={"d-flex flex-column flex-md-row"}>
                                    {loading ? <img alt={"Chargement en cours ..."} width={50} src={urlSite+"/assets/img/spinner_gris.svg"}/> :
                                        <Btn className={"btn-vert "} texte={"Inscription"} iconeDroite={"fleche"} type={"submit"}
                                             loading={true}/>}
                                    {loading ? null : <a href={"/connexion"}
                                                         className={"lien-secondaire d-flex justify-content-center align-items-center ps-0 ps-md-4 pt-3 pt-md-0"}>J'ai
                                        déjà un compte</a>}
                                </div>
                            </div>

                        </div>
                    </form>
                </div> : <div className={"d-flex justify-content-center align-items-center mt-15 mb-15"}><img alt={"Chargement en cours ..."} width={50}
                                                                                                              src={"./assets/img/spinner_gris.svg"}/>
                </div>}
            </main>
            <Footer/>
        </div>
    );
}
