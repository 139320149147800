import React, {useEffect, useState} from "react";
import {Helmet} from 'react-helmet';
import {urlSite} from "../../class/Config";
import {getCurrentUser} from "../../class/User";
import {FaRegCopy} from 'react-icons/fa';
export default function Parrainnage() {
    const [email,setEmail]=useState("");
    const [copySuccess, setCopySuccess] = useState('');
    useEffect(()=>{
        const getData=async ()=> {
            let user = await getCurrentUser();
            console.log(user.email);
            setEmail(user.email);
        }
        getData();
    },[])

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`${urlSite}/inscription/${email}`);
            setCopySuccess("Lien copié dans le presse-papier!");
        } catch (err) {
            setCopySuccess("Erreur lors de la copie du lien!");
        }
    };

    return (
        <div className={"container-fluid"}>
            <Helmet>
                <title>Parrainnage - facturesimpayees.com</title>
                <meta name="description"
                      content="Parrainnez des utilisateurs"/>
                <meta name="keywords"
                      content="informations société, factures impayées, plateforme, fonctionnalités, facturesimpayees.com"/>
            </Helmet>
            <h2 className={"dashboard-title"}>Parrainage</h2>
            <div>
                Pour parrainner un nouvel utilisateur, vous pouvez lui envoyer ce lien avant qu'il se crée un compte :
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    margin: '40px auto',
                    width: '80%',
                    maxWidth: '600px'
                }}>
                    <code style={{marginRight: '10px'}}>
                        {urlSite}/inscription/{email}
                    </code>
                    <button onClick={copyToClipboard} style={{border: 'none', background: 'none'}}>
                        <FaRegCopy />
                    </button>
                </div>
                {copySuccess &&
                    <p style={{color: copySuccess.includes('Erreur') ? 'red' : 'green', textAlign: 'center'}}>
                        {copySuccess}
                    </p>
                }
            </div>
        </div>

    );
}
