import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {Modal, openModal, closeModal} from "../../components/Modal";
import {Helmet} from 'react-helmet';
import {getRequete, updateRequete} from "../../class/Requete";
import {useParams} from "react-router-dom";

export default function Avocat({type}) {
    const [requete, setRequete] = useState([]);
    let {id} = useParams();
    useEffect(() => {


        const getData = async () => {
            let r = await getRequete(id);
            setRequete(r);
        }
        getData();
    }, []);

    const updateRequeteAvocat = async () => {
        await updateRequete(id, {
            statut_avocat: "ordonnance envoyee",
        })
        let r = await getRequete(id);
        setRequete(r);
        openModal(document.getElementById("modal_contact_avocat"))
    }


    return (
        <div className={"container-fluid"}>
            <Helmet>
                <title>Avocats facturesimpayees.com</title>
                <meta name="description"
                      content="Suivi de votre dossier par un avocat sur facturesimpayees.com."/>
                <meta name="keywords"
                      content="informations société, factures impayées, plateforme, fonctionnalités, facturesimpayees.com"/>
            </Helmet>
            <h2 className={"dashboard-title"}>Reprise de votre dossier par un avocat</h2>
            <div className={"align-items-center"}>
                <p>Votre dossier a été contesté par le débiteur. Vous avez la possibilité de le confier à un avocat
                    pour poursuivre la procédure, pour cela il vous suffit de cliquer sur le bouton ci dessous.</p>
                <p>En effectuant cette action, vous acceptez de transmettre vos informations à un avocat qui prendra
                    contact avec vous pour vous proposer ses services. Vous pourrez alors décider de lui confier
                    votre dossier ou non.</p>
                {!requete.statut_avocat ?
                    <Btn texte={"Prendre contact avec un avocat"} iconeDroite={"fleche"} onClick={async () => {
                        await updateRequeteAvocat();
                    }
                    }/> : null}
                {requete.statut_avocat === "ordonnance envoyee" ?
                    <div className={"alert alert-success"}>Votre dossier a été transmis, un avocat prendra contact
                        avec vous dans les plus brefs délais.</div> : null}

            </div>
            <Modal id={"modal_contact_avocat"} titre={"Votre dossier a été transmis"}
                   body={"Les informations de votre dossier ont été transmises, un avocat prendra contact avec vous dans les plus brefs délais."}>
                <Btn texte="OK" onClick={() => {
                    closeModal(document.getElementById("modal_contact_avocat"))
                }}/>
            </Modal>
        </div>

    );
}
