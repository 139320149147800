import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAIT7CIQOkru2GhcBsA6K5L1hADdWv2FrU",
    authDomain: "factures-impayees.firebaseapp.com",
    databaseURL: "https://factures-impayees-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "factures-impayees",
    storageBucket: "factures-impayees.appspot.com",
    messagingSenderId: "10460588244",
    appId: "1:10460588244:web:40b9566d1baa5563333341",
    measurementId: "G-NNB0EMS39R"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
/*const db = initializeFirestore(firebaseApp, {
    experimentalForceLongPolling: true,
    useFetchStreams: false,
})*/
const auth = getAuth(firebaseApp);

const analytics = getAnalytics(firebaseApp);

export {firebaseApp, db, auth, analytics};
