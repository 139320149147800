import React, {useEffect} from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet';
import {getIdEnvoiFromRequeteId, getSuiviCourrier} from "../class/Requete";
import {useParams} from "react-router-dom";
import {decode} from "html-entities";

export default function Suivi() {
    const [suivi, setSuivi] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    let {type,id} = useParams();
    useEffect(() => {
        const getSuiviCourrierData = async (id) => {
            //const url = window.location.href;
            const envoi=await getIdEnvoiFromRequeteId(id, type);
            if (envoi.result) {
                let envoi_id = envoi.result.envoi_id[0];
                if (envoi_id) {
                    let suivi = await getSuiviCourrier(envoi_id);
                    setSuivi(suivi);
                    console.log(suivi);
                    setLoading(false);
                }else{
                    setLoading(false);
                }
            }else{
                setLoading(false);
            }
        }
        getSuiviCourrierData(id);
    }, [id,type]);
    return (
        <div>
            <Helmet>
                <title>Suivi</title>
                <meta name="description"
                      content="Suivi - facturesimpayees.com"/>
                <meta name="keywords" content="Suivi"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container mt-10"}>
                    <h1 className="text-center mb-10">Suivi ({type==="requete"?"Requête":"Mise en demeure"})</h1>
                    <div className={"content-text-justify"}>
                        {loading ? <div className={"text-center"}>Chargement...</div> : <div>

                            <div>
                                {suivi && suivi?.suiviCourrier && suivi?.suiviCourrier.length>0 ?<table className={"table mt-3 table_tracking"}>
                                    <tbody>
                                    <tr>
                                        <td>Nom et/ou société du destinataire</td>
                                        <td>{decode(suivi?.suiviCourrier[0]?.dest)}</td>
                                    </tr>
                                    <tr>
                                        <td>Référence Merci facteur du courrier</td>
                                        <td>{decode(suivi?.suiviCourrier[0]?.refCourrier)}</td>
                                    </tr>
                                    <tr>
                                        <td>Référence de suivi postal</td>
                                        <td><a href={"https://www.laposte.fr/outils/suivre-vos-envois?code="+suivi?.suiviCourrier[0]?.refTracking}
                                               target={"_blank"} rel={"noreferrer"}>Suivre mon courrier sur la Poste ({decode(suivi?.suiviCourrier[0]?.refTracking)})</a> </td>
                                    </tr>
                                    <tr>
                                        <td>Mode d'envoi du courrier</td>
                                        <td>{decode(suivi?.suiviCourrier[0]?.modeEnvoi)}</td>
                                    </tr>
                                    <tr>
                                        <td>Dernier état connu de l'acheminement</td>
                                        <td>{decode(suivi?.suiviCourrier[0]?.lastState)}</td>
                                    </tr>
                                    <tr>
                                        <td>Historique complet de l'acheminement</td>
                                        <td dangerouslySetInnerHTML={{ __html: decode(suivi.suiviCourrier[0]?.historique) }}></td>
                                    </tr>
                                    {suivi?.suiviCourrier[0]?.refTracking?<tr>
                                        <td>Document de suivi</td>
                                        <td><a href={"/reception/"+suivi?.suiviCourrier[0]?.refTracking} rel="noreferrer" target={"_blank"}>Télécharger</a></td>
                                    </tr>:null}

                                    </tbody>
                                </table>:<div className={"text-center"}>Pas d'information de suivi disponible.</div>}
                            </div>

                        </div>
                        }
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
