import {urlFunction} from "./Config";

const getFicheIdentite = async (siren) => {
    return new Promise(function (resolve, reject) {
        fetch(urlFunction + "/FicheIdentite?siren="+siren)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            )
    });
}

const getRepresentants = async (siren) => {
    return new Promise(function (resolve, reject) {
        fetch(urlFunction + "/Representants?siren="+siren)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            )
    });
}


export {
    getFicheIdentite,
    getRepresentants
};
