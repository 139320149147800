import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {iaFactureFromURL} from "../../class/Requete";
import {firebaseApp} from "../../components/Firebase";
import PDFViewer from "../../components/PDFViewer";
import {Button, ButtonGroup, OverlayTrigger, Popover} from "react-bootstrap";
import {updateRelance, createRelance, getRelances, deleteRelance, creerDossierInjonction} from "../../class/Relances";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {getCurrentUser, isAbonne} from "../../class/User";

export default function ModuleRelance() {

    const [factures, setFactures] = useState([]);
    const [canAccess, setCanAccess] = useState(false);

    useEffect(() => {
        console.log("change factures");
        console.log(factures);
        factures.map((facture, index) => {
            if (facture.date_relance) {
                let date_relance = new Date(facture.date_relance);
                let date_today = new Date();
                if (date_relance <= date_today) {
                    factures[index].relance = true;
                } else {
                    factures[index].relance = false;
                }
            }
            if (facture.url) {
                if (facture.id) {
                    console.log("update relance");
                    updateRelance(facture, facture.id).then(r => console.log(r));
                } else {
                    createRelance(facture).then((result) => {
                        console.log("create relance");
                        let idFacture = result.id;
                        let facturesListe = [...factures];
                        facturesListe[index].id = idFacture;
                        //setFactures([...facturesListe]);
                    });
                }
            }
            return null;
        });
    }, [factures]);

    //import relances from firebase on page loading getRelances from uid
    useEffect(() => {

        const getData = async () => {
            let relances = await getRelances();
            setFactures(relances);
            let user = await getCurrentUser();
            let abonnement = await isAbonne(user.uid);
            console.log(abonnement);
            if (abonnement.status === "active") {
                let priceIdAllowedAccess = "price_1LsN2RK4135Q7fB4jGXIkkpf";
                let priceIdAllowedAccess_preprod = "price_1NVg8XK4135Q7fB40tqr4JcF";
                let currentPriceId = abonnement.plan.id;
                if (currentPriceId === priceIdAllowedAccess || currentPriceId === priceIdAllowedAccess_preprod) {
                    setCanAccess(true);
                } else {
                    setCanAccess(false);
                }
            }
        };
        getData().then(r => console.log(r));
    }, []);
    const uploadFactures = (e, index) => {
        let facturesListe = [...factures];
        let files = e.target.files;
        //check type du fichier unique [0]
        console.log(files);
        if (files.length === 1) {
            let file = files[0];
            if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf") {
                //check poid du fichier < 10Mo
                if (file.size < 10000000) {
                    console.log("Fichier valide");
                    const storage = getStorage(firebaseApp);
                    let progress = 0;
                    let extension = file.name.split('.').pop();
                    const storageRef = ref(storage, 'factures/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');

                            switch (snapshot.state) {
                                case 'paused':
                                    break;
                                case 'running':
                                    break;
                                default:

                            }
                        },
                        (error) => {
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    break;
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    break;
                                default:
                            }
                        },
                        async () => {
                            // Upload completed successfully, now we can get the download URL
                            await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                console.log('File available at', downloadURL);
                                iaFactureFromURL(downloadURL, file.type).then((result) => {
                                    console.log(result);
                                    //result.invoice_date format "2019-01-29"
                                    //date relance today + 1 mont
                                    let date_relance = new Date();
                                    date_relance.setMonth(date_relance.getMonth() + 1);
                                    let relance = false;
                                    if (result.invoice_date) {
                                        //date relance = invoice_date + 1 month
                                        let date_invoice = new Date(result.invoice_date);
                                        date_relance = date_invoice;
                                        date_relance.setMonth(date_invoice.getMonth() + 1);
                                        date_relance = date_relance.toISOString().split('T')[0];

                                        let date_relance_edit = new Date(date_relance);
                                        let date_today = new Date();
                                        if (date_relance_edit <= date_today) {
                                            relance = true;
                                        } else {
                                            relance = false;
                                        }
                                    }


                                    facturesListe[index] = {
                                        url: downloadURL,
                                        type: file.type,
                                        extension: extension,
                                        ia: result,
                                        date_relance: date_relance,
                                        relance: relance
                                    };
                                    console.log(facturesListe);
                                    setFactures([...facturesListe]);
                                });


                            });

                        }
                    );


                } else {
                    console.log("Fichier supérieur à 10 Mo");
                }
            } else {
                console.log("Fichier invalide");
            }
        } else {
            console.log("Fichier invalide");
        }
        //force update

    }

    const delete_facture = (index) => {
        deleteRelance(factures[index].id).then(r => console.log(r));
        let facturesListe = [...factures];
        facturesListe.splice(index, 1);
        setFactures([...facturesListe]);
    }

    return (
        <div className={"container-fluid justify-content-start align-items-center d-flex flex-column"}>
            <Helmet>
                <title>Module de relance sur facturesimpayees.com</title>
                <meta name="description" content="relancez automatiquement vos clients."/>
                <meta name="keywords"
                      content="demandes de paiement, historique, factures impayées, plateforme, suivi, mises à jour, facturesimpayees.com"/>
            </Helmet>
            <h1 className={"align-self-start dashboard-title"}>Module de relance</h1>
            {canAccess ? <>
                <Btn className={"align-self-start"} texte={"Ajouter une facture"} iconeDroite={"fleche"}
                     loading={false} onClick={() => {
                    setFactures([...factures, {}]);
                }}/>
                <table className={"table"}>
                    <tbody>
                    {factures.map((facture, index) => (
                        <tr key={index} className={""}>
                            <td className={facture.relance ? "bg-red td_center" : "td_center"}>Facture n°{index + 1}
                                {facture.chargement ?
                                    <span className={"spinner-border spinner-border-sm"} role="status"></span> : null}
                            </td>
                            <td className={"td_center"}>
                                {facture.url ?
                                    <>
                                        {facture.type === "application/pdf" ? (
                                            <a href={facture.url} rel="noreferrer" target={"_blank"}><PDFViewer
                                                src={facture.url} width="150" className={"page-pdf"}/></a>
                                        ) : (
                                            <a href={facture.url} rel="noreferrer" target={"_blank"}><img
                                                alt={"Facture"} src={facture.url}
                                                width="150" className={"page-pdf"}/></a>
                                        )
                                        }
                                    </>
                                    : null}
                            </td>
                            <td className={"td_center"}>
                                {facture.url ? <>Date de relance de la facture : <input value={facture.date_relance}
                                                                                        type={"date"}
                                                                                        className={"form-control"}
                                                                                        onChange={(e) => {
                                                                                            let facturesListe = [...factures];
                                                                                            console.log(e.target.value);
                                                                                            facturesListe[index].date_relance = e.target.value;
                                                                                            setFactures([...facturesListe]);
                                                                                        }}/></> : null}
                            </td>
                            <td className={"td_center"}>

                                <ButtonGroup size="sm">
                                    {!facture.url ? <OverlayTrigger trigger="focus" placement="top"
                                                                    overlay={<Popover id="popover-basic">
                                                                        <Popover.Header as="h3">Télécharger la
                                                                            facture</Popover.Header>
                                                                        <Popover.Body>
                                                                            Vous permet de télécharger la facture.
                                                                        </Popover.Body>
                                                                    </Popover>}>
                                        <Button title={"Telecharger"} onClick={() => {
                                            document.getElementById("facture_" + index).click();
                                        }}>
                                            Télécharger la facture
                                        </Button>
                                    </OverlayTrigger> : null}
                                    {facture.relance && !facture.requeteId ?
                                        <OverlayTrigger trigger="focus" placement="top"
                                                        overlay={<Popover id="popover-basic">
                                                            <Popover.Header as="h3">Créer une relance</Popover.Header>
                                                            <Popover.Body>
                                                                Vous permet de créer une relance.
                                                            </Popover.Body>
                                                        </Popover>}>
                                            <Button title={"Relance"} onClick={() => {
                                                openModal(document.getElementById("modal_creation_relance_" + facture.id));
                                            }}>
                                                Créer une relance
                                            </Button>
                                        </OverlayTrigger> : null}
                                    {facture.relance && !facture.requeteId ?
                                        <OverlayTrigger trigger="focus" placement="top"
                                                        overlay={<Popover id="popover-basic">
                                                            <Popover.Header as="h3">Créer une demande d'injonction de
                                                                payer</Popover.Header>
                                                            <Popover.Body>
                                                                Vous permet de créer une demande d'injonction de payer
                                                            </Popover.Body>
                                                        </Popover>}>
                                            <Button title={"Injonction"} onClick={() => {
                                                openModal(document.getElementById("modal_creation_injonction_" + facture.id));
                                            }}>
                                                Créer une demande d'injonction de payer
                                            </Button>
                                        </OverlayTrigger> : null}

                                    {facture.requeteId ? <OverlayTrigger trigger="focus" placement="top"
                                                                         overlay={<Popover id="popover-basic">
                                                                             <Popover.Header as="h3">Aller sur mon
                                                                                 dossier de demande
                                                                                 d'injonction</Popover.Header>
                                                                             <Popover.Body>
                                                                                 Vous permet de vous rendre sur votre
                                                                                 dossier de demande d'injonction de
                                                                                 payer.
                                                                             </Popover.Body>
                                                                         </Popover>}>
                                        <Button title={"Injonction"} onClick={() => {
                                            document.location.href = "/dashboard/requete/" + facture.requeteId;
                                        }}>
                                            Aller sur mon dossier de demande d'injonction
                                        </Button>
                                    </OverlayTrigger> : null}


                                    <Modal id={"modal_creation_injonction_" + facture.id}
                                           titre={"Création d'une demande d'injonction de payer"}
                                           body={"Vous êtes sur le point de créer un dossier de demande d'injonction de payer. Cela vous déduira un dossier de votre compteur."}>
                                        <Btn texte="Oui je veux créer un dossier" onClick={async () => {
                                            closeModal(document.getElementById("modal_creation_injonction_" + facture.id));
                                            let requeteId = await creerDossierInjonction(facture);
                                            document.location.href = "/dashboard/requete/" + requeteId;

                                        }}/>
                                        <Btn texte="Annuler" onClick={async () => {
                                            closeModal(document.getElementById("modal_creation_injonction_" + facture.id));
                                        }}/>
                                    </Modal>

                                    <Modal id={"modal_creation_relance_" + facture.id} titre={"Création d'une relance"}
                                           body={"Vous êtes sur le point de créer une relance, cela créera un dossier qui vous permettra d'aller jusqu'à la demande d'injonction de payer. Cela vous déduira un dossier de votre compteur."}>
                                        <Btn texte="Oui je veux créer un dossier" onClick={async () => {
                                            closeModal(document.getElementById("modal_creation_relance_" + facture.id));
                                            let requeteId = await creerDossierInjonction(facture);
                                            document.location.href = "/dashboard/requete/" + requeteId;

                                        }}/>
                                        <Btn texte="Annuler" onClick={async () => {
                                            closeModal(document.getElementById("modal_creation_relance_" + facture.id));
                                        }}/>
                                    </Modal>

                                    <OverlayTrigger trigger="focus" placement="top"
                                                    overlay={<Popover id="popover-basic">
                                                        <Popover.Header as="h3">Supprimer la facture</Popover.Header>
                                                        <Popover.Body>
                                                            Supprimer la facture
                                                        </Popover.Body>
                                                    </Popover>}>
                                        <Button variant={"danger"} title={"Supprimer"} onClick={() => {
                                            delete_facture(index);
                                        }}>
                                            Supprimer
                                        </Button>
                                    </OverlayTrigger>
                                </ButtonGroup>
                                <input accept="image/jpeg, image/png, application/pdf" type="file"
                                       id={"facture_" + index}
                                       className={"d-none"} multiple={false}
                                       onChange={(e) => {
                                           facture.chargement = true;
                                           factures[index] = facture;
                                           setFactures([...factures]);
                                           uploadFactures(e, index);
                                       }}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </> : <>
                <div className={"alert alert-danger"}>Vous n'êtes pas abonné à l'offre Privilège permettant d'accéder à
                    ce module
                </div>
                <Btn texte={"Je m'abonne"} onClick={() => {
                    document.location.href = "/dashboard/mon-abonnement";
                }
                }/>
            </>}
        </div>
    );
}


