import React, {useState} from "react";
import Menu from "../components/Menu";
import Btn from "../components/Btn";
import Footer from "../components/Footer";
import {firebaseApp} from "../components/Firebase";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {Helmet} from 'react-helmet';

const auth = getAuth(firebaseApp);

export default function Connexion() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const checkValidity = () => {
        return false;
    }

    const submitForm = (event) => {
        let isValid = checkValidity();
        if (!isValid) {
            signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                    setError(false);
                    document.location.href = "/dashboard";
                })
                .catch(() => {
                    setError(true);
                });
        }
        event.preventDefault();
    }

    return (
        <div>
            <Helmet>
                <title>Connexion à facturesimpayees.com</title>
                <meta name="description"
                      content="Accédez à votre compte sur facturesimpayees.com pour gérer vos factures impayées en toute simplicité. Utilisez notre formulaire de connexion sécurisé pour vous connecter."/>
                <meta name="keywords"
                      content="connexion, compte, factures impayées, plateforme, sécurisé, facturesimpayees.com"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container py-5 px-5"}>
                    <h1 className={"titre justify-content-center align-items-center"}><img alt={"Inscription"}
                                                                                           src={"assets/img/connexion.png"}
                                                                                           className={"icone-titre"}
                                                                                           height={60}/> Connexion</h1>
                    <div className={"baseline"}>Connectez vous à votre compte utilisateur pour pouvoir utiliser
                        facturesimpayees.com
                    </div>
                    {error && <div className={"alert alert-danger mt-2"}>Votre email ou votre mot de passe n'est pas
                        correct.</div>}
                    <form className={"mt-4 form-default"} onSubmit={submitForm}>
                        <div className={"row g-3"}>
                            <div className={"col-12"}>
                                <input
                                    type={"email"}
                                    className={"form-control"}
                                    id={"email"}
                                    placeholder={"Adresse email"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoComplete={"email"}
                                />
                            </div>
                            <div className={"col-12 d-flex"}>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={"form-control flex-grow-1"}
                                    id={"password"}
                                    placeholder={"Mot de passe"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required minLength={5}
                                    maxLength={100}
                                    autoComplete="current-password"
                                />
                                <button
                                    type="button"
                                    className="btn btn-link ms-2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                         fill="currentColor" className="bi bi-eye-fill"
                                                         viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path
                                            d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                  fill="currentColor" className="bi bi-eye-slash-fill"
                                                  viewBox="0 0 16 16">
                                        <path
                                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                        <path
                                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>}
                                </button>
                            </div>
                            <div className={"col-12"}>
                                <div className={"d-flex flex-column flex-md-row"}>
                                    <Btn texte={"Connexion"} iconeDroite={"fleche"} type={"submit"}/>
                                    <a href={"/mot-de-passe-oublie"}
                                       className={"lien-secondaire d-flex justify-content-center align-items-center ps-0 ps-md-4 pt-3 pt-md-0"}>Mot
                                        de passe oublié</a>
                                    <a href={"/inscription"}
                                       className={"lien-secondaire d-flex justify-content-center align-items-center ps-0 ps-md-4 pt-3 pt-md-0"}>Inscription</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
