import React, {useEffect} from "react";
import { Document,Page,pdfjs } from 'react-pdf'

export default function PDFViewer(props) {

    useEffect(() => {
        //https://www.npmjs.com/package/react-pdf
        pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/"+pdfjs.version+"/pdf.worker.js";
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        console.log(numPages);
    }

    return(
        <Document file={props.src} error={"Impossible de charger la prévisualisation du document PDF"} loading={"Chargement du PDF ..."} className={"d-flex"}  onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={1} width={props.width} className={"page-pdf"} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
    )
}
