import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {
    convertDemo,
    getIdEnvoiFromRequeteId,
    getRequetesFromUserId,
    getStatut,
    getSuiviCourrier,
    getTotal
} from "../../class/Requete";
import {getCurrentUser} from "../../class/User";
import DataTable from 'react-data-table-component';
import {DebounceInput} from "react-debounce-input";
import {closeModal, Modal, openModal} from "../../components/Modal";
import {Helmet} from 'react-helmet';
import {decode} from 'html-entities';
import {ButtonGroup, Button, OverlayTrigger, Popover} from "react-bootstrap";

export default function RequeteEnCours() {
    const [requetes, setRequetes] = useState([]);
    const [pending, setPending] = useState(true);

    const [filterText, setFilterText] = useState('');
    //const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const dateSort = (rowA, rowB) => {
        const a = rowA.dateCreation._seconds;
        const b = rowB.dateCreation._seconds;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const montantSort = (rowA, rowB) => {
        let a = 0;
        let b = 0;
        if (rowA.totaux) {
            a = rowA.totaux.totalRestant;
        }
        if (rowB.totaux) {
            b = rowB.totaux.totalRestant;
        }
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;

    };

    const convertDemoRequete = async (id) => {
        try {
            let result = await convertDemo(id);
            closeModal(document.getElementById("modal_demo" + id));
            document.location.href = "/dashboard/requete-en-cours/";
        } catch (error) {
            alert("Erreur lors de la conversion: " + error.message);
            // Ajouter ici toute autre logique de gestion des erreurs
        }
    }

    const popover_visualiser = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Visualiser</Popover.Header>
            <Popover.Body>
                Vous permet de revenir à votre dossier pour le modifier ou le visualiser.
            </Popover.Body>
        </Popover>
    );

    const popover_changedemo = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Convertir un dossier de démonstration</Popover.Header>
            <Popover.Body>
                Cliquez ici pour convertir un dossier de démonstration en dossier réel. Cela consommera un crédit.
            </Popover.Body>
        </Popover>
    );

    const popover_suivi = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Suivi du courrier : Requête</Popover.Header>
            <Popover.Body>
                Suivez le statut de votre courrier envoyé par Factures impayées.
            </Popover.Body>
        </Popover>
    );

    const popover_suivi_MED = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Suivi du courrier : Mise en demeure</Popover.Header>
            <Popover.Body>
                Suivez le statut de votre courrier envoyé par Factures impayées.
            </Popover.Body>
        </Popover>
    );

    const popover_suivi_recu = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">J'ai reçu mon ordonnance</Popover.Header>
            <Popover.Body>
                Découvrez les étapes à suivre une fois que vous avez reçu votre ordonnance.
            </Popover.Body>
        </Popover>
    );

    const popover_refus = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Ma requête a été refusé</Popover.Header>
            <Popover.Body>
                Découvrez les étapes à suivre si votre requête a été refusé.
            </Popover.Body>
        </Popover>
    );

    const popover_avocat = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">J'ai besoin d'un avocat</Popover.Header>
            <Popover.Body>
                Votre requête a été contestée, vous avez besoin d'un avocat pour faire valoir vos droits.
            </Popover.Body>
        </Popover>
    );



    const columns = [
        {
            name: 'Débiteur',
            selector: row => row.type ? (row.type === "entreprise" ? (row.raisonSocialeDebiteur ? row.raisonSocialeDebiteur : "-") : (row.PrenomDebiteur ? row.PrenomDebiteur : "-") + " " + (row.NomDebiteur ? row.NomDebiteur : "")) : "-",
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => {
                return row.type ? row.type.toUpperCase() : "-"
            },
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => row.status ? row.status : getStatut(row.etape, row.type),
            sortable: true,
            cell: row => {
                const statut = row.status ? row.status : getStatut(row.etape, row.type);
                return <div
                    className={statut === "Terminé" ? "status-green" : statut === "En cours" ? "status-orange" : statut === "Transmission" ? "status-green" : "status-default"}>{statut || ''}{row.test?" (Demo)":""}</div>;
            }
        },
        {
            name: 'Date de création',
            selector: row => ('0' + new Date(row.dateCreation._seconds * 1000).getDate()).slice(-2) + "/" + (('0' + (new Date(row.dateCreation._seconds * 1000).getMonth() + 1.0))).slice(-2) + "/" + new Date(row.dateCreation._seconds * 1000).getFullYear(),
            sortable: true,
            sortFunction: dateSort,
        },
        {
            name: 'Montant',
            selector: (row, i) => row.totaux && row.totaux.totalRestant ? row.totaux.totalRestant + " €" : "-",
            sortable: true,
            sortFunction: montantSort,
        },
        {
            name: 'Actions',
            selector: (row) => {
                return <div className={"d-flex flex-column my-2 widthBtnTable"}>

                    <ButtonGroup size="sm" className={"flex-wrap d-flex"}>
                        <OverlayTrigger trigger="hover" placement="top" overlay={popover_visualiser}>
                            <Button accessKey={"Visualiser"} title={"Visualiser"} onClick={() => {
                                document.location.href = "/dashboard/requete/" + row.id;
                            }}>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={20}>
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                                            stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path
                                            d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                                            stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                    </g>
                                </svg>
                            </Button>
                        </OverlayTrigger>
                        {row.test?<OverlayTrigger trigger="hover" placement="top" overlay={popover_changedemo}>
                            <Button variant={"info"} accessKey={"Convertir en dossier réel"} title={"Convertir en dossier réel"} onClick={() => {
                                openModal(document.getElementById("modal_demo" + row.id));
                            }}>
                                <svg  width={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M22 5.15V8.85C22 11.1 21.1 12 18.85 12H16.15C13.9 12 13 11.1 13 8.85V5.15C13 2.9 13.9 2 16.15 2H18.85C21.1 2 22 2.9 22 5.15Z"
                                            stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path
                                            d="M11 15.15V18.85C11 21.1 10.1 22 7.85 22H5.15C2.9 22 2 21.1 2 18.85V15.15C2 12.9 2.9 12 5.15 12H7.85C10.1 12 11 12.9 11 15.15Z"
                                            stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke="#ffffff"
                                              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75" stroke="#ffffff" strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                    </g>
                                </svg>
                            </Button>
                        </OverlayTrigger>:null}
                        {row.suivi ?
                            <OverlayTrigger trigger="hover" placement="top" overlay={popover_suivi}>
                                <Button onClick={() => {
                                    //openModal(document.getElementById("modal_suivi_requete" + row.id));
                                    window.open("/suivi/requete/" + row.id);
                                }}>
                                    <svg width={20} viewBox="-0.5 0 25 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                         stroke="#ffffff">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M21.2091 5.41992C15.5991 16.0599 8.39906 16.0499 2.78906 5.41992"
                                                  stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                            <path
                                                d="M1.99023 7.39001V17.39C1.99023 18.4509 2.41166 19.4682 3.1618 20.2184C3.91195 20.9685 4.92937 21.39 5.99023 21.39H17.9902C19.0511 21.39 20.0685 20.9685 20.8186 20.2184C21.5688 19.4682 21.9902 18.4509 21.9902 17.39V7.39001C21.9902 6.32915 21.5688 5.31167 20.8186 4.56152C20.0685 3.81138 19.0511 3.39001 17.9902 3.39001H5.99023C4.92937 3.39001 3.91195 3.81138 3.1618 4.56152C2.41166 5.31167 1.99023 6.32915 1.99023 7.39001Z"
                                                stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </g>
                                    </svg>
                                </Button>
                            </OverlayTrigger> : null}

                        {row.suivi_MED ?
                            <OverlayTrigger trigger="hover" placement="top" overlay={popover_suivi_MED}>
                                <Button onClick={() => {
                                    //openModal(document.getElementById("modal_suivi_requete" + row.id));
                                    window.open("/suivi/miseendemeure/" + row.id);
                                }}>
                                    <svg width={20} viewBox="-0.5 0 25 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                         stroke="#ffffff">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M21.2091 5.41992C15.5991 16.0599 8.39906 16.0499 2.78906 5.41992"
                                                  stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                            <path
                                                d="M1.99023 7.39001V17.39C1.99023 18.4509 2.41166 19.4682 3.1618 20.2184C3.91195 20.9685 4.92937 21.39 5.99023 21.39H17.9902C19.0511 21.39 20.0685 20.9685 20.8186 20.2184C21.5688 19.4682 21.9902 18.4509 21.9902 17.39V7.39001C21.9902 6.32915 21.5688 5.31167 20.8186 4.56152C20.0685 3.81138 19.0511 3.39001 17.9902 3.39001H5.99023C4.92937 3.39001 3.91195 3.81138 3.1618 4.56152C2.41166 5.31167 1.99023 6.32915 1.99023 7.39001Z"
                                                stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </g>
                                    </svg>
                                </Button>
                            </OverlayTrigger> : null}
                        {row.etape === 11 || row.etape === 10 ?
                            <OverlayTrigger trigger="hover" placement="top" overlay={popover_suivi_recu}>
                                <Button variant={"success"} onClick={() => {
                                    document.location.href = "/requete-recu/";
                                }}>
                                    <svg width={20} viewBox="-0.5 0 25 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M10.5605 8.41992L13.6205 11.29C13.779 11.4326 13.9056 11.6068 13.9924 11.8015C14.0791 11.9962 14.1239 12.2068 14.1239 12.4199C14.1239 12.633 14.0791 12.8439 13.9924 13.0386C13.9056 13.2332 13.779 13.4075 13.6205 13.55L10.5605 16.4199"
                                                stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                            <path
                                                d="M17 3.41992H7C4.79086 3.41992 3 5.21078 3 7.41992V17.4199C3 19.6291 4.79086 21.4199 7 21.4199H17C19.2091 21.4199 21 19.6291 21 17.4199V7.41992C21 5.21078 19.2091 3.41992 17 3.41992Z"
                                                stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </g>
                                    </svg>
                                </Button>
                            </OverlayTrigger> : null}

                        {/*row.etape === 11 || row.etape === 10 ?
                            <OverlayTrigger trigger="hover" placement="top" overlay={popover_avocat}>
                                <Button variant={"warning"} onClick={() => {
                                    document.location.href = "/dashboard/avocats/" + row.id;
                                }}>
                                    <svg width={20} fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                         stroke="#ffffff">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path fillRule="evenodd"
                                                  d="M12.75 2.75a.75.75 0 00-1.5 0V4.5H9.276a1.75 1.75 0 00-.985.303L6.596 5.957A.25.25 0 016.455 6H2.353a.75.75 0 100 1.5H3.93L.563 15.18a.762.762 0 00.21.88c.08.064.161.125.309.221.186.121.452.278.792.433.68.311 1.662.62 2.876.62a6.919 6.919 0 002.876-.62c.34-.155.606-.312.792-.433.15-.097.23-.158.31-.223a.75.75 0 00.209-.878L5.569 7.5h.886c.351 0 .694-.106.984-.303l1.696-1.154A.25.25 0 019.275 6h1.975v14.5H6.763a.75.75 0 000 1.5h10.474a.75.75 0 000-1.5H12.75V6h1.974c.05 0 .1.015.14.043l1.697 1.154c.29.197.633.303.984.303h.886l-3.368 7.68a.75.75 0 00.23.896c.012.009 0 0 .002 0a3.154 3.154 0 00.31.206c.185.112.45.256.79.4a7.343 7.343 0 002.855.568 7.343 7.343 0 002.856-.569c.338-.143.604-.287.79-.399a3.5 3.5 0 00.31-.206.75.75 0 00.23-.896L20.07 7.5h1.578a.75.75 0 000-1.5h-4.102a.25.25 0 01-.14-.043l-1.697-1.154a1.75 1.75 0 00-.984-.303H12.75V2.75zM2.193 15.198a5.418 5.418 0 002.557.635 5.418 5.418 0 002.557-.635L4.75 9.368l-2.557 5.83zm14.51-.024c.082.04.174.083.275.126.53.223 1.305.45 2.272.45a5.846 5.846 0 002.547-.576L19.25 9.367l-2.547 5.807z"></path>
                                        </g>
                                    </svg>
                                </Button>
                            </OverlayTrigger> : null*/}

                        {row.etape === 11 || row.etape === 10 ?
                            <OverlayTrigger trigger="hover" placement="top" overlay={popover_refus}>
                                <Button variant={"danger"} onClick={() => {
                                    document.location.href = "/dashboard/refus/" + row.id;
                                }}>
                                    <svg width={20} viewBox="-0.5 0 25 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M18.2202 21.25H5.78015C5.14217 21.2775 4.50834 21.1347 3.94373 20.8364C3.37911 20.5381 2.90402 20.095 2.56714 19.5526C2.23026 19.0101 2.04372 18.3877 2.02667 17.7494C2.00963 17.111 2.1627 16.4797 2.47015 15.92L8.69013 5.10999C9.03495 4.54078 9.52077 4.07013 10.1006 3.74347C10.6804 3.41681 11.3346 3.24518 12.0001 3.24518C12.6656 3.24518 13.3199 3.41681 13.8997 3.74347C14.4795 4.07013 14.9654 4.54078 15.3102 5.10999L21.5302 15.92C21.8376 16.4797 21.9907 17.111 21.9736 17.7494C21.9566 18.3877 21.7701 19.0101 21.4332 19.5526C21.0963 20.095 20.6211 20.5381 20.0565 20.8364C19.4919 21.1347 18.8581 21.2775 18.2202 21.25V21.25Z"
                                                stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                            <path
                                                d="M10.8809 17.15C10.8809 17.0021 10.9102 16.8556 10.9671 16.7191C11.024 16.5825 11.1074 16.4586 11.2125 16.3545C11.3175 16.2504 11.4422 16.1681 11.5792 16.1124C11.7163 16.0567 11.8629 16.0287 12.0109 16.03C12.2291 16.034 12.4413 16.1021 12.621 16.226C12.8006 16.3499 12.9398 16.5241 13.0211 16.7266C13.1023 16.9292 13.122 17.1512 13.0778 17.3649C13.0335 17.5786 12.9272 17.7745 12.7722 17.9282C12.6172 18.0818 12.4203 18.1863 12.2062 18.2287C11.9921 18.2711 11.7703 18.2494 11.5685 18.1663C11.3666 18.0833 11.1938 17.9426 11.0715 17.7618C10.9492 17.5811 10.8829 17.3683 10.8809 17.15ZM11.2409 14.42L11.1009 9.20001C11.0876 9.07453 11.1008 8.94766 11.1398 8.82764C11.1787 8.70761 11.2424 8.5971 11.3268 8.5033C11.4112 8.40949 11.5144 8.33449 11.6296 8.28314C11.7449 8.2318 11.8697 8.20526 11.9959 8.20526C12.1221 8.20526 12.2469 8.2318 12.3621 8.28314C12.4774 8.33449 12.5805 8.40949 12.6649 8.5033C12.7493 8.5971 12.8131 8.70761 12.852 8.82764C12.8909 8.94766 12.9042 9.07453 12.8909 9.20001L12.7609 14.42C12.7609 14.6215 12.6808 14.8149 12.5383 14.9574C12.3957 15.0999 12.2024 15.18 12.0009 15.18C11.7993 15.18 11.606 15.0999 11.4635 14.9574C11.321 14.8149 11.2409 14.6215 11.2409 14.42Z"
                                                fill="#ffffff"></path>
                                        </g>
                                    </svg>
                                </Button>
                            </OverlayTrigger> : null}
                    </ButtonGroup>

                    <Modal id={"modal_demo" + row.id} titre={"Convertir un dossier de démonstration"}
                            body={<div>
                                <p>Vous êtes sur le point de convertir un dossier de<br /> démonstration en dossier réel.<br /><br /> Cela consommera un crédit.</p>

                            </div>}>
                        <Btn texte={"Confirmer"} onClick={async () => {
                            await convertDemoRequete(row.id);
                        }
                        }/>
                        <Btn texte={"Annuler"} onClick={() => {
                            closeModal(document.getElementById("modal_demo" + row.id));
                        }} />
                    </Modal>


                    {<Modal id={"modal_suivi_requete" + row.id} titre={"Suivi courrier requête"}
                            body={<div>
                                Statut : {row.status}
                                {row.suivi && row.suivi?.suiviCourrier && row.suivi?.suiviCourrier.length > 0 ?
                                    <table className={"table mt-3 table_tracking"}>
                                        <tbody>
                                        <tr>
                                            <td>Nom et/ou société du destinataire</td>
                                            <td>{decode(row.suivi?.suiviCourrier[0]?.dest)}</td>
                                        </tr>
                                        <tr>
                                            <td>Référence Merci facteur du courrier</td>
                                            <td>{decode(row.suivi?.suiviCourrier[0]?.refCourrier)}</td>
                                        </tr>
                                        <tr>
                                            <td>Référence de suivi postal</td>
                                            <td>{decode(row.suivi?.suiviCourrier[0]?.refTracking)}</td>
                                        </tr>
                                        <tr>
                                            <td>Mode d'envoi du courrier</td>
                                            <td>{decode(row.suivi?.suiviCourrier[0]?.modeEnvoi)}</td>
                                        </tr>
                                        <tr>
                                            <td>Dernier état connu de l'acheminement</td>
                                            <td>{decode(row.suivi?.suiviCourrier[0]?.lastState)}</td>
                                        </tr>
                                        <tr>
                                            <td>Historique complet de l'acheminement</td>
                                            <td dangerouslySetInnerHTML={{__html: decode(row.suivi.suiviCourrier[0]?.historique)}}></td>
                                        </tr>
                                        {row.suivi?.suiviCourrier[0]?.refTracking ? <tr>
                                            <td>Document de suivi</td>
                                            <td><a href={"/reception/" + row.suivi?.suiviCourrier[0]?.refTracking}
                                                   rel="noreferrer" target={"_blank"}>Télécharger</a></td>
                                        </tr> : null}
                                        </tbody>
                                    </table> : null}
                            </div>}>
                    </Modal>}
                </div>
            }
        }
    ];

    const customStyles = {
        table: {
            style: {
                padding: 0,
                margin: 0
            },
        },
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                font: 'bold 1.7em Inter',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                font: 'normal 1.3em Inter',
            },
        },
    };

    const decodeHTMLEntities = (text) => {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    useEffect(() => {

        const getData = async () => {
            let user = await getCurrentUser();
            let data = await getRequetesFromUserId(user.uid);
            if (data.length > 0) {
                await Promise.all(data.map(async (items, i) => {
                    let envoi = await getIdEnvoiFromRequeteId(items.id, "requete");
                    if (envoi.result) {
                        //let envoi_id = envoi.result.envoi_id[0];
                        let envoi_id = envoi && envoi.result && envoi.result.envoi_id ? envoi.result.envoi_id[0] : null;
                        if (envoi_id) {
                            let suivi = await getSuiviCourrier(envoi_id);
                            //console.log(suivi);
                            /*if(suivi.suiviCourrier) {
                                data[i].proof = proof;
                                let proof = await getProof(suivi.suiviCourrier.refTracking);
                            }*/
                            data[i].suivi = suivi;

                            data[i].status = decodeHTMLEntities(suivi.statutPrintEnvoi);
                        } else {
                            data[i].status = getStatut(items.etape);
                        }
                    }

                    let envoi_MED = await getIdEnvoiFromRequeteId(items.id, "miseendemeure");
                    if (envoi_MED.result) {
                        //let envoi_MED_id = envoi.result.envoi_id[0];
                        let envoi_MED_id = envoi && envoi.result && envoi.result.envoi_id ? envoi.result.envoi_id[0] : null;
                        if (envoi_MED_id) {
                            let suivi_MED = await getSuiviCourrier(envoi_MED_id);
                            //console.log(suivi);
                            /*if(suivi.suiviCourrier) {
                                data[i].proof = proof;
                                let proof = await getProof(suivi.suiviCourrier.refTracking);
                            }*/
                            data[i].suivi_MED = suivi_MED;

                            data[i].status_MED = decodeHTMLEntities(suivi_MED.statutPrintEnvoi);
                        } else {
                            data[i].status_MED = getStatut(items.etape);
                        }
                    }

                    let totaux = await getTotal(items.id);
                    data[i].totaux = totaux;
                    return totaux;
                }));

                if (filterText !== "") {
                    setRequetes(data.filter(
                        item => ((item.raisonSocialeDebiteur && item.raisonSocialeDebiteur.toLowerCase().includes(filterText.toLowerCase())) || (item.PrenomDebiteur && item.PrenomDebiteur.toLowerCase().includes(filterText.toLowerCase())) || (item.NomDebiteur && item.NomDebiteur.toLowerCase().includes(filterText.toLowerCase()))),
                    ));
                } else {
                    setRequetes(data);
                }
                setPending(false);
            } else {
                setPending(false);
            }
        }

        getData();


    }, [filterText]);

    /*const ExpandedComponent = ({ data }) => <ul>
            <li>SIREN : {data.SIRENDebiteur}</li>
        </ul>;*/

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className={"texte-standard input-search-tabs"}>Débiteur : <DebounceInput debounceTimeout={1000}
                                                                                          className={""}
                                                                                          type={"search"}
                                                                                          onChange={e => setFilterText(e.target.value)}
                                                                                          value={filterText}/></div>
        );
    }, [filterText]);


    return (
        <div className={"container-fluid justify-content-start align-items-center d-flex flex-column"}>
            <Helmet>
                <title>Suivez vos demandes de paiement sur facturesimpayees.com</title>
                <meta name="description"
                      content="Accédez à l'historique de vos demandes de paiement sur facturesimpayees.com. Suivez l'évolution de vos requêtes et obtenez des mises à jour en temps réel pour gérer efficacement vos factures impayées."/>
                <meta name="keywords"
                      content="demandes de paiement, historique, factures impayées, plateforme, suivi, mises à jour, facturesimpayees.com"/>
            </Helmet>
            <Btn className={"align-self-start"} texte={"Créer un nouveau dossier"} iconeDroite={"fleche"}
                 loading={false} onClick={() => {
                document.location.href = "/dashboard/nouvelle-requete";
            }}/>
            <DataTable
                columns={columns}
                noDataComponent="Vous n'avez pas créé de dossier"
                data={requetes}
                defaultSortFieldId={1}
                customStyles={customStyles}
                pagination
                progressPending={pending}
                progressComponent={<div className="spinner-border" role="status">
                </div>}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                /*expandableRows
                expandableRowsComponent={ExpandedComponent}*/
            />
        </div>

    );

}
