import React, {useEffect, useState} from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import MaSociete from "./Dashboard/MaSociete";
import MonCompte from "./Dashboard/MonCompte";
import NouvelleRequete from "./Dashboard/NouvelleRequete";
import Requete from "./Dashboard/Requete";
import RequeteEnCours from "./Dashboard/RequeteEnCours";
import {getCurrentUser, getUserFromUid} from "../class/User";
import {getIdEnvoiFromRequeteId, getRequetesFromUserId, getSuiviCourrier} from "../class/Requete";
import MonAbonnement from "./Dashboard/MonAbonnement";
import {getAbonnementPersonnaliseFromUid, getPortalURL} from "../class/Abonnement";
import {urlSite} from "../class/Config";
import {Helmet} from 'react-helmet';
import Btn from "../components/Btn";
import {useParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {BsFillFileArrowDownFill} from "react-icons/bs";
import {ProgressBar} from "react-bootstrap";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../components/Firebase";
import Parrainnage from "./Dashboard/Parrainnage";
import Refus from "./Dashboard/Refus";
import MonAbonnementPersonnalise from "./Dashboard/MonAbonnementPersonnalise";
import ModuleRelance from "./Dashboard/ModuleRelance";
import Avocat from "./Dashboard/Avocat";


export default function Dashboard(props) {
    const [requetesNbr, setRequetesNbr] = useState([]);
    const [showRequete, setshowRequete] = useState(false);
    const [societe, setSociete] = useState({});
    const [requete, setRequete] = useState();
    const [now, setNow] = useState(0);
    const [suiviMED, setSuiviMED] = useState(null);
    const [suiviRequete, setSuiviRequete] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [envoiMED, setEnvoiMED] = useState(null);
    const [envoiRequete, setEnvoiRequete] = useState(null);
    const [abonnementPersonnalise, setAbonnementPersonnalise] = useState([]);
    let {id} = useParams();
    useEffect(() => {
        const getData = async () => {

            let user = await getCurrentUser();
            if (user !== null) {
                getAbonnementPersonnaliseFromUid(user.uid).then((data) => {
                    setAbonnementPersonnalise(data);
                    //console.log(data);
                });

                let test=await getUserFromUid(user.uid)
                console.log(test);
                setSociete(await getUserFromUid(user.uid));
                let data = await getRequetesFromUserId(user.uid);
                if (data.length) {
                    setRequetesNbr(data.length);
                } else {
                    setRequetesNbr("0");
                }
                setshowRequete(true);
                setIsConnected(true);
            } else {

                setIsConnected(false);
                document.location.href = "/connexion";
            }
        }
        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            if (id) {
                onSnapshot(doc(db, "requetes", id), async (doc) => {
                    let requeteTmp = doc.data();


                    setRequete(requeteTmp);
                    let envoi = await getIdEnvoiFromRequeteId(id, "miseendemeure");
                    console.log('envoi');
                    console.log(envoi);
                    setEnvoiMED(envoi);
                    if (envoi?.result?.envoi_id) {
                        let suivi = await getSuiviCourrier(envoi.result.envoi_id);
                        setSuiviMED(suivi);
                    }

                    let envoiRequete = await getIdEnvoiFromRequeteId(id, "requete");
                    setEnvoiRequete(envoiRequete);
                    if (envoiRequete?.result?.envoi_id) {
                        let suivi = await getSuiviCourrier(envoiRequete.result.envoi_id);
                        setSuiviRequete(suivi);
                    }


                    let etape = requeteTmp.etape;
                    if (requeteTmp.type === "entreprise") {
                        if (etape === 1) {
                            setNow(0);
                        }
                        if (etape === 12) {
                            setNow(10);
                        }
                        if (etape === 3) {
                            setNow(20);
                        }
                        if (etape === 4) {
                            setNow(30);
                        }
                        if (etape === 5) {
                            setNow(40);
                        }
                        if (etape === 15) {
                            setNow(50);
                        }
                        if (etape === 13) {
                            setNow(60);
                        }
                        if (etape === 14) {
                            setNow(65);
                        }
                        if (etape === 6) {
                            setNow(70);
                        }
                        if (etape === 8) {
                            setNow(80);
                        }
                        if (etape === 9) {
                            setNow(90);
                        }
                        if (etape === 10) {
                            setNow(100);
                        }
                    }
                    if (requeteTmp.type === "particulier") {
                        if (etape === 1) {
                            setNow(0);
                        }
                        if (etape === 2) {
                            setNow(10);
                        }
                        if (etape === 3) {
                            setNow(20);
                        }
                        if (etape === 4) {
                            setNow(30);
                        }
                        if (etape === 5) {
                            setNow(40);
                        }
                        if (etape === 15) {
                            setNow(50);
                        }
                        if (etape === 13) {
                            setNow(60);
                        }
                        if (etape === 14) {
                            setNow(65);
                        }
                        if (etape === 6) {
                            setNow(70);
                        }
                        if (etape === 8) {
                            setNow(80);
                        }
                        if (etape === 9) {
                            setNow(90);
                        }
                        if (etape === 10) {
                            setNow(100);
                        }
                        if (etape === 11) {
                            setNow(100);
                        }
                    }


                });
            }
        }
        getData();

    }, [id]);
    return (
        <div>
            <Helmet>
                <title>Tableau de bord sur facturesimpayees.com</title>
                <meta name="description"
                      content="Accédez à toutes les fonctionnalités de facturesimpayees.com depuis votre tableau de bord. Gérez vos factures impayées en toute simplicité et suivez l'évolution de vos paiements."/>
                <meta name="keywords"
                      content="tableau de bord, fonctionnalités, factures impayées, plateforme, paiements, facturesimpayees.com"/>
            </Helmet>
            <Menu dashboard={true}/>
            <main className={"Main dashboard-zone"}>
                <div className={"d-flex flex-lg-row flex-column dashboard-main container-fluid"}>
                    <nav className={"nav-dashboard"}>
                        <ul className={"gap-3 d-flex flex-lg-column flex-md-row ul-dashboard-menu"}>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/nouvelle-requete"}
                                                                     className={"lienMenuDashboard " + (props.page === "nouvelle-requete" ? " active-lien" : null)}>Créer
                                un nouveau dossier</a></li>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/requete-en-cours"}
                                                                     className={"lienMenuDashboard " + (props.page === "requete-en-cours" ? " active-lien" : null)}>Mes
                                dossiers en cours {showRequete ? <span
                                    className={"badge-dashboard"}>{showRequete ? requetesNbr : ""}</span> : null}</a>
                            </li>
                            {<li className={"lienMenuDashboardLi"}><a href={"/dashboard/module-de-relance"}
                                                                     className={"lienMenuDashboard " + (props.page === "module-de-relance" ? " active-lien" : null)}>Module de relance</a>
                            </li>}
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/ma-societe"}
                                                                     className={"lienMenuDashboard " + (props.page === "ma-societe" ? " active-lien" : null)}>Ma
                                société</a></li>
                            <li className={"lienMenuDashboardLi"}><a href={"/dashboard/mon-compte"}
                                                                     className={"lienMenuDashboard " + (props.page === "mon-compte" ? " active-lien" : null)}>Mon
                                compte</a></li>

                            {abonnementPersonnalise?.error && !societe?.affiliation_user ?
                                <li className={"lienMenuDashboardLi"}><a href={"/dashboard/mon-abonnement"}
                                                                         className={"lienMenuDashboard"}>Mon
                                    abonnement</a></li> : null}


                            {!abonnementPersonnalise?.error ?
                                <li className={"lienMenuDashboardLi"}><a href={"/dashboard/mon-abonnement-personnalise"}
                                                                         className={"lienMenuDashboard"}>Mon
                                    abonnement personnalisé</a></li> : null}
                            {societe.stripe_customer_id && !societe?.affiliation_user ?
                                <li className={"lienMenuDashboardLi"}><span onClick={() => {
                                    getPortalURL(societe.stripe_customer_id, urlSite + "/dashboard/");
                                }}
                                                                            className={"lienMenuDashboard"}>Mes moyens de paiement</span>
                                </li> : null}
                            {societe.stripe_customer_id && !societe?.affiliation_user ?
                                <li className={"lienMenuDashboardLi"}><span onClick={() => {
                                    getPortalURL(societe.stripe_customer_id, urlSite + "/dashboard/");
                                }}
                                                                            className={"lienMenuDashboard"}>Mes factures</span>
                                </li> : null}
                            {!societe?.affiliation_user ?
                                <li className={"lienMenuDashboardLi"}><a href={"/dashboard/parrainnage"}
                                                                         className={"lienMenuDashboard"}>Parrainage</a>
                                </li> : null}
                            {id && requete ?
                                <div className={"documents"}>
                                    <div className={"mb-2"}>Suivi</div>
                                    <IconContext.Provider value={{color: 'white', size: '20px'}}>
                                        {requete.miseendemeuregenerate ? <div><a rel="noreferrer" target={"_blank"}
                                                                                 href={requete.miseendemeuregenerate}><BsFillFileArrowDownFill/> Mise
                                            en demeure <br/><small
                                                className={"fs-10"}>({envoiMED?.result?.success ? "Envoyé par la plateforme" : "Non envoyé par la plateforme"})</small></a>
                                        </div> : null}
                                        {requete.requete_pdf ? <div><a rel="noreferrer" target={"_blank"}
                                                                       href={requete.requete_pdf}><BsFillFileArrowDownFill/> Requête <br/><small
                                            className={"fs-10"}>({envoiRequete?.result?.success ? "Envoyé par la plateforme" : "Non envoyé par la plateforme"})</small></a>
                                        </div> : null}
                                        {suiviMED?.suiviCourrier && suiviMED?.suiviCourrier.length > 0 && suiviMED?.suiviCourrier[0]?.refTracking ?
                                            <div><a rel="noreferrer" target="_blank"
                                                    href={"/reception/" + suiviMED?.suiviCourrier[0]?.refTracking}>
                                                <BsFillFileArrowDownFill/> Preuve de récéption (Mise en demeure)</a>
                                            </div> : null}
                                        {suiviRequete?.suiviCourrier && suiviRequete?.suiviCourrier.length > 0 && suiviRequete?.suiviCourrier[0]?.refTracking ?
                                            <div><a rel="noreferrer" target="_blank"
                                                    href={"/reception/" + suiviRequete?.suiviCourrier[0]?.refTracking}>
                                                <BsFillFileArrowDownFill/> Preuve de récéption (Requête)</a>
                                            </div> : null}
                                    </IconContext.Provider>
                                    <div className={"pt-2"}>
                                        <ProgressBar animated now={now} label={`${now}%`}/>
                                    </div>
                                </div> : null}


                            {/*<li className={"lienMenuDashboardLi"}><a href={"/"} className={"lienMenuDashboard"}>Mes
                                moyens de paiement</a></li>*/}
                        </ul>
                    </nav>
                    <div className={"py-2 px-2 py-lg-5 px-lg-5 d-flex container-fluid max_width_dashboard"}>
                        {isConnected && props.page === "ma-societe" ? <MaSociete/> : null}
                        {isConnected && props.page === "mon-compte" ? <MonCompte/> : null}
                        {isConnected && props.page === "nouvelle-requete" ? <NouvelleRequete/> : null}
                        {isConnected && props.page === "requete" ? <Requete/> : null}
                        {isConnected && props.page === "requete-en-cours" ? <RequeteEnCours/> : null}
                        {isConnected && props.page === "refus" ? <Refus/> : null}
                        {isConnected && props.page === "mon-abonnement" ? <MonAbonnement/> : null}
                        {isConnected && props.page === "mon-abonnement-personnalise" ? <MonAbonnementPersonnalise/> : null}
                        {isConnected && props.page === "parrainnage" ? <Parrainnage/> : null}
                        {isConnected && props.page === "module-de-relance" ? <ModuleRelance/> : null}
                        {isConnected && props.page === "avocats" ? <Avocat/> : null}
                        {isConnected && !props.page ? <div
                            className={"container-fluid justify-content-center align-items-center d-flex flex-column"}>
                            <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 mb-14 align-items-center">
                                <div className="col-lg-7 order-lg-2">
                                    <figure><img className="img-auto" src="/assets/img/home/01.svg" alt=""/></figure>
                                </div>
                                <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                                    <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">Factures impayées <br
                                        className="d-md-none"/>simplifie <br className="d-md-none"/><span
                                        className="rotator-fade text-violet">vos démarches</span></h1><p
                                    className="lead fs-lg mb-7">Obtenez dès maintenant une ordonnance de payer.
                                    Cliquez sur démarrer pour vous lancer !</p>
                                    <Btn iconeDroite={"fleche"} texte={"Démarrer"} onClick={() => {
                                        document.location.href = "/dashboard/nouvelle-requete";
                                    }}/>

                                    {/*<Btn texte={"Test send mail"} onClick={() => {
                                        sendEmailTest("raphael@atlantismultimedia.fr","raphael@atlantismultimedia.fr","test","test","Raphaël","test",true,"https://google.com");
                                    }}/>*/}
                                </div>
                            </div>
                        </div> : null}
                        {/*props.page === "mes-moyens-de-paiement" ? <MesMoyensDePaiement/> : null*/}
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
