import React from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet';

export default function CGV() {
    return (
        <div>
            <Helmet>
                <title>CGV - facturesimpayees.com</title>
                <meta name="description"
                      content="CGV - facturesimpayees.com."/>
                <meta name="keywords" content="CGV, facturesimpayees.com"/>
            </Helmet>
            <Menu/>
            <main className={"Main"}>
                <div className={"container mt-10"}>
                    <h1 className="text-center mb-10">Conditions générales de vente de factures impayées</h1>
                    <div className={"content-text-justify"}>
                        <h2>Article 1. Qui sommes-nous et que proposons-nous ?</h2>
                        <p>La société MAC2 (ci-après MAC2) propose à des professionnels personnes physiques ou morales (ci-après, l’« Utilisateur » et/ou « Vous ») les services suivants (ci-après les « Services ») :</p>
                        <ul>
                            <li>mise à disposition d’un système automatisé vous permettant de générer et d’expédier à vos débiteurs une mise en demeure de payer vos factures ;</li>
                            <li>mise à disposition d’un service automatisé vous permettant de générer et d’adresser vous-même votre requête en injonction de payer au tribunal compétent ;</li>
                            <li>mise en relation, à votre demande, avec un Huissier de justice pour signifier l’ordonnance d’injonction de payer obtenue ;</li>
                            <li>mise en relation, à votre demande, avec un avocat proche de chez Vous susceptible de vous assister pour toute demande de votre choix.</li>
                        </ul>
                        <p>Ces services sont proposés via une plateforme Web accessible à l’adresse URL : facturesimpayees.com (ou tout autre adresse qui la remplacerait) (ci-après le «Site»).</p>
                        <p>Le propriétaire de la plateforme est :</p>
                        <p>SAS MAC2<br />
                            Société par actions simplifiée<br />
                            RCS Aix en Provence N° 918 961 509.<br />
                            Siège social 3 Avenue de Mouliero, 13770 Venelles</p>
                        <p>Ces Services sont proposés exclusivement sous forme d’abonnements.</p>
                        <h2>Article 2. Objet et champ d'application des présentes conditions générales de vente (« CGV »)</h2>
                        <p>Les présentes conditions générales de vente ont pour objet de définir les conditions dans lesquelles MAC2 met les Services à disposition des Utilisateurs ainsi que les obligations réciproques de MAC2 et des Utilisateurs.</p>
                        <p>Les présentes conditions générales de vente s’appliquent, sans restriction ni réserve, à tout Utilisateur du Site.</p>
                        <p>Les CGV sont mises à disposition des Utilisateurs et directement consultables à tout moment sur le Site. Vous pouvez conserver les présentes CGUV sur support durable en procédant à leur impression. En cas d’ouverture d’un Compte et de souscription d’un Abonnement sur le Site, les CGV seront jointes au courriel de confirmation de l’ouverture de votre Compte et de la souscription de votre Abonnement qui vous sera adressé.</p>
                        <p>L’utilisation du Site constitue votre acceptation pleine et entière des présentes CGV. De plus, toute souscription à un Abonnement implique l’acceptation préalable des CGV qui sont mises à votre disposition avant de finaliser la commande via un lien hypertexte renvoyant auxdites CGV. MAC2 vous invite à lire attentivement les CGV avant chaque commande, celles-ci ayant pu être modifiées conformément à l’article 13 ci-après.</p>

                        <h2>Article 3. Conditions d’accès au Service</h2>
                        <p>Les Services sont accessibles à :</p>
                        <ul>
                            <li>toute personne physique (majeure et jouissant de sa pleine capacité juridique) agissant, sur le territoire national, pour des besoins entrant dans le cadre de son activité commerciale, libéral, industrielle, artisanale ou agricole ;</li>
                            <li>toute personne morale légalement constituée, ne faisant l’objet d’aucune procédure de mise sous sauvegarde de justice, de redressement ou de liquidation judiciaire et agissant, sur le territoire national en qualité de professionnel, par l’intermédiaire d’un représentant personne physique dûment habilité, lui permettant d’agir au nom et pour le compte de cette dernière, pour les actes utiles à la réalisation de son objet social et aux actes qui leur sont accessoires, dans le respect des règles qui lui sont applicables.</li>
                        </ul>
                        <h2>Article 4. Contenu des Services</h2>
                        <p>4.1 Description générale des Services</p>
                        <p>Les Services permettent à tout professionnel (commerçants, artisans, professions libérales etc. ou encore sociétés) de générer et d’adresser, au Tribunal compétent et de manière totalement automatisée, une requête en injonction de payer.</p>
                        <p>La requête en injonction de payer, accompagnée des pièces justificatives (factures impayées, mise en demeure, accusé de réception etc.) fournies par l’Utilisateur, est vérifiée, validée et signée par l’Utilisateur.</p>
                        <p>La requête en injonction de payer et ses pièces justificatives sont ensuite adressées par l’Utilisateur au Tribunal compétent par lettre recommandée avec demande d’avis de réception soit directement par le Site, lorsque l’envoi en ligne est possible, soit sous format papier lorsque l’envoi en ligne est impossible.</p>
                        <p>L’Utilisateur reçoit un accusé de réception soit par e-mail soit par la poste.</p>
                        <p>Le Tribunal analyse la demande et examine les pièces justificatives produites. Il rend sa décision sur les seuls éléments fournis, sous sa responsabilité par l’Utilisateur. Le débiteur de l’Utilisateur n’est pas informé de cette démarche. Il s’agit d’une procédure non contradictoire.</p>
                        <p>Le Tribunal prend alors sa décision.</p>
                        <p>Cette décision prend la forme d’une ordonnance au terme de laquelle le Tribunal peut ;</p>
                        <ul>
                            <li>soit déclarer la requête irrecevable, c’est-à-dire qu’il considère que la requête est mal présentée et ne peut être traitée (par exemple : erreur de tribunal, erreur sur le débiteur, requête incomplète etc…);</li>
                            <li>soit rejeter la demande de condamnation du débiteur ; le Tribunal considère que la requête est recevable mais que la demande n’est pas justifiée ou encore nécessite un débat contradictoire ;</li>
                            <li>soit condamner le débiteur à payer tout ou partie de la créance de l’Utilisateur et, le cas échéant, tout ou partie des frais et accessoires également réclamés. Il rend alors une ordonnance portant injonction de payer.</li>
                        </ul>
                        <p>L’Utilisateur, titulaire d’une ordonnance portant injonction de payer, doit saisir, sans délai, un huissier pour faire notifier la décision obtenue à son débiteur.</p>
                        <p>L’Utilisateur peut saisir cet huissier par ses propres moyens ou utiliser, dans les conditions ci-après définies, le service de mise en relation avec huissier disponible sur le Site.</p>
                        <p>Une fois l’ordonnance d’injonction notifiée par voie d’huissier, le débiteur est informé qu’il est condamné par un tribunal à payer l’Utilisateur. Il dispose d’un délai d’un mois pour contester sa condamnation.</p>
                        <p>Si le débiteur ne fait rien dans le délai imparti, l’ordonnance d’injonction de payer est définitive et constitue un véritable titre exécutoire, à l’instar de n’importe quel autre jugement définitif.</p>

                        <p>L’Utilisateur peut alors engager toute démarche d’exécution forcée : saisie bancaire, saisie-vente etc…</p>
                        <p>Si le débiteur veut contester sa condamnation, il doit, toujours dans le délai d’un mois, former opposition directement auprès du Tribunal qui a rendu l’ordonnance d’injonction de payer. A réception de cette opposition, le Tribunal convoquera alors le débiteur et l’Utilisateur à une audience pour qu’ils exposent leur litige. Dans cette hypothèse, l’Utilisateur peut, à son choix :</p>
                        <ul>
                            <li>se défendre lui-même,</li>
                            <li>choisir l’avocat de son choix pour le représenter,</li>
                            <li>utiliser, dans les conditions définies ci-après, le service de mise en relation avec un avocat exerçant dans le ressort du tribunal saisi, proposé par le Site.</li>
                        </ul>
                        <p>Le Site est donc destiné à faciliter la saisine directe par l’Utilisateur du tribunal compétent pour condamner son débiteur à lui payer ses factures demeurées impayées malgré l’envoi une mise en demeure de payer.</p>
                        <p>Le Site est également destiné à faciliter la mise en relation de l’Utilisateur avec un huissier compétent pour exécuter les décisions de condamnation de ses débiteurs.</p>
                        <p>Le Site est également destiné à faciliter la mise en relation de l’Utilisateur avec un avocat compétent en cas de contestation de sa condamnation par son débiteur.</p>
                        <p>Le Site n'est pas destiné à fournir des conseils juridiques. Aucune relation de type avocat-client n'est créée, et les données publiées sur le Site ne sont pas considérées comme confidentielles.</p>
                        <p>Vous reconnaissez que vous créez vous-mêmes vos documents, sans le conseil d’un professionnel du droit.</p>
                        <p>Pour toute consultation juridique, vous reconnaissez qu’il est obligatoire de se rapprocher (par l’intermédiaire du Site ou par tout autre moyen) d’un avocat ou de toute autre personne habilitée par la loi à effectuer des prestations de conseil juridique. Le Site et/ou les Services proposés sur le Site ne sauraient en aucun cas se substituer à une consultation d’avocat.</p>
                        <h3>4.2 Service de lettre recommandée en ligne</h3>
                        <p>L’Utilisateur peut, grâce au Site, générer automatiquement une mise en demeure de payer ses factures impayées et l’adresser automatiquement à son débiteur par le biais d’une lettre recommandée électronique avec demande d’avis de réception.</p>
                        <p>Votre pli est remis au destinataire contre signature, en délai standard et vous recevez un accusé de réception par courriel.</p>
                        <p>La lettre recommandée bénéficie du service de suivi : vous pouvez suivre toutes les étapes d’acheminement de votre pli sur votre compte client.</p>
                        <p>Cette lettre recommandée en ligne a la même valeur qu’un courrier recommandé papier.</p>
                        <h3>4.3 Service d’édition automatisée de requête en injonction de payer en ligne</h3>
                        <p>L’Utilisateur peut, grâce au Site, générer automatiquement un formulaire de requête en injonction de payer et l’envoyer au tribunal compétent.</p>
                        <p>Ce formulaire est prérempli avec les coordonnées complètes de l’Utilisateur telles que renseignées par ce dernier lors de la souscription de son abonnement et le cas échéant, actualisées par ses soins.</p>
                        <p>Il comporte les coordonnées complètes du débiteur. Celles-ci sont soit générées automatiquement par le Site, après saisie par l’Utilisateur du numéro RCS du débiteur, soit renseignées directement en ligne par l’Utilisateur.</p>
                        <p>Il comporte la mention exhaustive des factures dont le recouvrement est réclamé.</p>
                        <p>Il comporte systématiquement en pièces jointes :</p>
                        <ul>
                            <li>copie de la mise en demeure de payer adressée au débiteur ;</li>
                            <li>copie de l’accusé de réception de ladite mise en demeure ;</li>
                            <li>copie des factures dont le recouvrement est réclamé ;</li>
                            <li>le montant total des sommes réclamées.</li>
                        </ul>
                        <p>L’Utilisateur fournit, sous sa seule responsabilité, l’intégralité des informations nécessaires pour compléter le formulaire de requête en injonction de payer.</p>
                        <p>L’Utilisateur fournit sous sa seule responsabilité, la copie de la mise en demeure de payer adressée à son débiteur et l’accusé de réception de cette mise en demeure.</p>
                        <p>L’Utilisateur fournit, sous sa seule responsabilité, la copie de l’intégralité des factures dont il entend réclamer le paiement en justice.</p>
                        <p>L’Utilisateur est seul responsable de la validité, de l’exactitude et de la fiabilité des informations et pièces fournies par ses soins pour compléter le formulaire de requête en injonction de payer. </p>
                        <p>L’Utilisateur édite le formulaire final de requête en injonction de payer, il en vérifie scrupuleusement le contenu et les pièces y annexées. Il valide et signe de sa main le formulaire.</p>
                        <p>L’Utilisateur expédie en recommandé avec demande d’avis de réception le formulaire validé et signé par ses soins, accompagné des pièces justificatives, soit directement via le service d’envoi en ligne du Site, lorsque l’envoi en ligne est possible, soit sous format papier après impression du tout par ses soins.</p>

                        <h3>4.4 Service de mise en relation avec un huissier</h3>
                        <p>Ainsi qu’il a été exposé à l’article 4.1 ci-dessus, lorsque l’Utilisateur reçoit du tribunal l’Ordonnance d’injonction de payer condamnant son débiteur à lui payer les sommes réclamées, il lui appartient de signifier cette décision à son débiteur.</p>
                        <p>Cette signification est obligatoire ; elle fait courir le délai d’un mois au terme duquel, l’ordonnance devient, sauf opposition du débiteur, un titre définitif et exécutoire. </p>
                        <p>Cette signification doit être faite par voie d’huissier.</p>
                        <p>Si l’Utilisateur le souhaite, il peut être mis en relation gratuitement avec un huissier compétent via la plateforme facturesimpayees.com.</p>
                        <p>En cas de mise en relation via la plateforme facturesimpayees.com, la relation contractuelle sera totalement autonome, c’est-à-dire établie directement entre l’huissier et Vous sans aucune intervention du Site ou de MAC2.</p>
                        <h3>4.5 Service de mise en relation avec un avocat</h3>
                        <p>Ainsi qu’il a été exposé à l’article 4.1 ci-dessus, lorsque l’Utilisateur notifie à son débiteur par voie d’huissier l’ordonnance d’injonction de payer obtenue, le débiteur dispose d’un délai d’un mois pour contester cette condamnation.</p>
                        <p>Pour ce faire, la débiteur forme opposition à l’ordonnance qui lui est notifiée auprès du greffe du tribunal qui a rendu cette décision.</p>
                        <p>Le tribunal informe alors l’Utilisateur de cette opposition et le convoque, ainsi que le débiteur à une audience afin que le litige soit débattu contradictoirement.</p>
                        <p>Si l’Utilisateur le souhaite, il peut être mis en relation gratuitement, via l’application dédiée de la plateforme facturesimpayees.com, avec un avocat compétent dans le ressort du tribunal saisi.</p>
                        <p>En cas de mise en relation via la plateforme facturesimpayees.com, la relation contractuelle sera totalement autonome, c’est-à-dire établie directement entre l’avocat et Vous sans aucune intervention du Site ou de MAC2.</p>
                        <p>En outre, MAC2 ne peut garantir l’intervention d’un Avocat à la suite d’une mise en relation, ce dernier étant totalement libre de vous assister ou non (notamment en cas de conflit d’intérêt). L’Avocat fixe librement le montant de ses honoraires qu’il propose à l’Utilisateur qui est libre d’accepter ou non.</p>
                        <h2>Article 5. Ouverture et fermeture du Compte Utilisateur</h2>
                        <p>Pour accéder aux Services proposés par le Site, l’Utilisateur doit obligatoirement créer un compte (le « Compte »).</p>
                        <h3>5.1 Création de Compte</h3>
                        <p>La création d’un Compte est gratuite.</p>
                        <p>Pour créer un Compte, Vous devez renseigner les mentions obligatoires suivantes :</p>
                        <ul>
                            <li>Pour les personnes morales :</li>
                            <ul>
                                <li>Votre dénomination sociale ;</li>
                                <li>Votre numéro d’inscription au registre du commerce et des sociétés dont vous dépendez,</li>
                                <li>Le nom, prénom, date de naissance et qualité du représentant légal</li>
                                <li>Votre siège social</li>
                                <li>Le numéro de téléphone et l’adresse e-mail du contact représentant l’Utilisateur dans le cadre de l’utilisation de la plateforme facturesimpayees.com</li>
                                <li>Un mot de passe secret, personnel et confidentiel.</li>
                            </ul>
                            <li>Pour les personnes physiques :</li>
                            <ul>
                                <li>Vos nom, prénom et date de naissance ;</li>
                                <li>Votre numéro d’inscription au registre du commerce et des sociétés dont vous dépendez,</li>
                                <li>Votre adresse postale</li>
                                <li>Votre numéro de téléphone et votre adresse e-mail</li>
                                <li>Un mot de passe secret, personnel et confidentiel.</li>
                            </ul>
                        </ul>
                        <p>En outre, l’Utilisateur doit fournir une copie de sa pièce d’identité (ou de celle de son représentant légal) en cours de validité.</p>
                        <p>Vous êtes responsable de l’exactitude et de la mise à jour des informations que vous fournissez. MAC2 décline toute responsabilité si les informations fournies sont incomplètes ou contiennent des erreurs et si, de ce fait, le Site est dans l’impossibilité de générer les documents souhaités et/ou si lesdits documents recèlent des erreurs les rendant totalement ou partiellement inefficients. </p>
                        <p>Vous devez immédiatement informer MAC2 de tout changement concernant ces informations, et confirmer sous quinze jours à MAC2 l’exactitude des informations si MAC2 ou l’un de ses prestataires en fait la demande. Vous pouvez à tout moment accéder à vos informations et les modifier dans votre espace « mon Compte ».</p>
                        <p>Toute personne physique ou morale utilisatrice du Site ne peut disposer que d’un seul compte. En conséquence, vous vous engagez à ne pas créer plusieurs Comptes et/ou à clore tout Compte supplémentaire à la demande de MAC2.</p>
                        <p>Ne communiquez pas l’identifiant et le mot de passe de votre Compte à des tiers. Vous êtes responsable du maintien de la confidentialité de vos identifiant et mot de passe, des restrictions d’accès à votre ordinateur et de toutes les activités effectuées depuis de votre Compte ou avec vos identifiant et mot de passe. </p>
                        <p>Si vous découvrez ou avez des raisons de croire que votre mot de passe est connu de quelqu’un d’autre, ou qu’il est utilisé ou susceptible d’être utilisé de manière non autorisée, vous devez impérativement en informer MAC2 immédiatement en envoyant un e-mail à l’adresse suivante : contact@facturesimpayees.com et procéder sans délai au changement de votre mot de passe.</p>

                        <p>MAC2 décline toute responsabilité pour tout préjudice subi à la suite d’une utilisation illicite, frauduleuse ou abusive de vos identifiant et mot de passe et de l’utilisation des Services par une personne autre que l’Utilisateur.</p>
                        <p>MAC2 se réserve le droit de vous envoyer des e-mails personnalisés à l’adresse e-mail que vous communiquez pour vous avertir de l’arrivée d’un message dans votre espace « mon Compte ».</p>
                        <h2>5.2 Fermeture du Compte par l’Utilisateur</h2>

                        <p>Vous pouvez suspendre temporairement ou fermer définitivement votre Compte à tout moment, de plein droit et sans formalité judiciaire en envoyant un e-mail à l’adresse suivante : <a href="mailto:contact@facturesimpayees.com">contact@facturesimpayees.com</a>.</p>

                        <p>La suspension de votre Compte entrera en vigueur le dernier jour du mois en cours et entraînera de plein droit la suspension, à même date, de l’Abonnement souscrit.</p>

                        <p>En cas de suspension de votre Abonnement, les requêtes capitalisées sur votre Compte et non encore utilisées seront conservées jusqu’à la réactivation de votre Compte.</p>

                        <p>Elles seront de nouveau utilisables dès la réactivation de votre Compte.</p>

                        <p>La fermeture de votre Compte entrera en vigueur le dernier jour du mois en cours et entraînera de plein droit la résiliation, à même date, de l’Abonnement souscrit.</p>

                        <p>En cas de fermeture définitive de votre Compte, les requêtes capitalisées seront définitivement perdues et ne donneront lieu à aucun remboursement de la part de MAC2.</p>


                        <h2>5.3 Fermeture du Compte par MAC2</h2>

                        <p>En cas de non-respect des présentes CGV, MAC2 aura la faculté de suspendre ou clore votre Compte (ou limiter votre capacité à utiliser tout ou partie du Site et des Services), à tout moment, de plein droit et sans formalité judiciaire, après l’envoi par e-mail (ou par voie postale) d’une mise en demeure restée sans effet pendant une durée de cinq (5) jours calendaires à compter de sa réception (ou de sa première présentation le cas échéant).</p>

                        <p>En cas de fraude ou de tentative de fraude, MAC2 pourra suspendre ou clore votre Compte, à tout moment, de plein droit et sans aucune formalité, notamment si MAC2 peut légitimement penser que :</p>

                        <ul>
                            <li>vous n’êtes pas la personne que vous prétendez être ou</li>
                            <li>votre n’avez pas ou plus les pouvoir pour représenter et/ou engager la personne titulaire du Compte ou encore</li>
                            <li>vous adoptez un comportement inopportun ou répréhensible sur le Site ou dans le cadre de l’utilisation des Services.</li>
                        </ul>


                        <h2>5.4 Conséquences de la fermeture du Compte</h2>

                        <p>Toute fermeture de votre Compte entraîne la suppression définitive des informations communiquées à MAC2 lors de l’ouverture ou de la mise à jour de votre Compte, de tous les documents et autres éléments stockés sur le Site dans votre espace « mon Compte » ainsi que, le cas échéant, de tout document ou contenu que vous auriez publié, téléchargé ou mis à disposition sur le Site. Il vous est donc fortement recommandé de sauvegarder, sur votre ordinateur ou sur un disque dur externe, tous les documents, informations et autres éléments stockés sur le Site dans votre espace « mon Compte ». </p>

                        <p>Nonobstant ce qui précède, MAC2 se réserve le droit de conserver les documents, informations et autres éléments susmentionnés sur ses serveurs pendant un délai raisonnable.</p>
                        <p>En cas de fermeture de votre Compte à votre initiative, aucun remboursement, ni total ni partiel, des sommes déjà versées pour l’achat de tout Abonnement alors en cours ne sera effectué.</p>
                        <p>En cas de suspension temporaire de votre Compte par MAC2 pour non-respect des présentes CGV dans les conditions prévues à l’article 5.3, les requêtes capitalisées et non encore utilisées seront conservées jusqu’à la réactivation de votre Compte.</p>
                        <p>En cas de fermeture de votre Compte par MAC2 pour non-respect des présentes CGV dans les conditions prévues à l’article 5.3, aucun remboursement, ni total ni partiel, des sommes déjà versées pour l’achat de tout Abonnement alors en cours ne sera effectué. Les requêtes capitalisées et non encore utilisées seront définitivement perdues.</p>
                        <p>De plus, il vous est formellement et strictement interdit d’ouvrir un nouveau Compte, avec la même adresse e-mail ou une adresse différente ou par personne interposée sans l’autorisation préalable et écrite de MAC2. Dans ce cas, contactez MAC2 à l’adresse e-mail suivante pour solliciter la création d’un nouveau Compte : <a href="mailto:contact@facturesimpayees.com">contact@facturesimpayees.com</a>.</p>
                        <h2>Article 6. Souscription d’un Abonnement</h2>
                        <p>Pour accéder aux Services tout Utilisateur doit être titulaire d’un Compte et souscrire un abonnement (l’ « Abonnement »).</p>
                        <p>La souscription à un Abonnement permet à l’Utilisateur d’utiliser tous les Services proposés par le Site.</p>
                        <h3>6.1 Description des Abonnements</h3>
                        <p>Le Site propose trois abonnements différents.</p>
                        <ul>
                            <li>L’abonnement standard : 2 requêtes par mois</li>
                            <li>L’abonnement medium : 5 requêtes par mois</li>
                            <li>L’abonnement prémium : 12 requêtes par mois.</li>
                        </ul>
                        <p>La requête est l’acte l’Utilisateur saisit le tribunal pour lui demander de condamner son débiteur à lui payer les factures qu’il lui doit. Une requête ne peut concerner qu’un seul et même débiteur. En revanche, une requête peut porter sur plusieurs factures concernant le même débiteur et ces factures peuvent concerner des prestations, des contrats différents.</p>
                        <p>Les requêtes acquises et non-utilisées au cours d’un mois considéré sont conservées par l’Utilisateur à la condition que son Abonnement souscrit soit toujours en cours.</p>
                        <p>L’Utilisateur pourra utiliser ses requêtes comme il l’entend sans restriction de nombre indépendamment de l’abonnement souscrit.</p>
                        <p>A titre d’exemple, si un Utilisateur titulaire d’un Abonnement standard (2 requêtes par mois) n’a pas utilisé les Services pendant deux mois, il disposera de la faculté d’utiliser ses 4 requêtes acquises et non utilisées comme il l’entend en plus des deux requêtes du mois en cours.</p>

                        <p>L’Utilisateur peut modifier indifféremment son Abonnement en fonction de ses besoins. Cette modification devra intervenir obligatoirement en cours de mois et pour le mois suivant. La modification de l’Abonnement n’entraîne pas la perte des requêtes acquises et non utilisées.</p>
                        <h3>6.2 Durée des Abonnements</h3>
                        <p>Les abonnements sont souscrits pour une durée minimum d’un mois. Ils sont renouvelés automatiquement et tacitement à chaque échéance et pour la même durée.</p>
                        <h3>6.3 Suspension, réactivation et résiliation des Abonnements</h3>
                        <p>Les Abonnements peuvent être suspendus ou résiliés à tout moment par l’Utilisateur et par MAC2. Tout mois entamé est dû.</p>
                        <p>Pour résilier ou suspendre son abonnement, il suffit à l’Utilisateur de suspendre ou de fermer définitivement son Compte dans les conditions stipulées aux articles 5.1 à 5.4 ci-dessus.</p>
                        <p>Absence de droit de rétractation. Dans la mesure où les Services sont destinés à des acheteurs professionnels, et non à des consommateurs au sens de l’article préliminaire du Code de la consommation, Vous ne bénéficiez pas d’un droit de rétractation.</p>
                        <h2>Article 7. Conditions tarifaires</h2>
                        <p>Le prix des Abonnements sur le Site est indiqué en Euros, soit hors taxe (HT) soit hors taxe et toutes taxes françaises comprises (TTC).</p>
                        <p>Le prix des Abonnements est celui en vigueur au jour de la souscription.</p>
                        <p>MAC2 se réserve le droit de modifier le prix des Abonnements indiqués sur le Site dans les conditions spécifiées à l’article 13 (Modifications) ci-après.</p>
                        <p>La souscription à un Abonnement permet à l’Utilisateur d’utiliser tous les Services proposés par le Site.</p>
                        <p>Les frais d’enrôlement des requêtes déposées devant les tribunaux de commerce sont systématiquement à la charge de l’Utilisateur suivant les tarifs indiqués sur le site.</p>
                        <p>Les frais d’acheminement postaux des mises en demeure et/ou des requêtes sont systématiquement à la charge de l’Utilisateur suivant les tarifs en vigueur pratiqués les greffes des tribunaux de commerce.</p>
                        <h2>Article 8. Modalités de paiement et de facturation</h2>
                        <h3>8.1. Paiements</h3>
                        <p>Le paiement des abonnements intervient lors de la souscription de l’Abonnement et à chaque échéance de celui-ci conformément aux durées définies à l’article 6.2 Durée des Abonnements.</p>
                        <p>Le paiement est effectué via le service de paiement sécurisé de la plateforme web Stripe:</p>
                        <ul>
                            <li>par prélèvement automatique sur la carte bancaire renseignée par l’Utilisateur lors de l’ouverture de son Compte ;</li>
                            <li>par prélèvement SEPA. Si vous pouvez opter pour ce moyen de règlement des échéances de votre Abonnement vous devez, lors de l’ouverture de votre Compte, renseigner votre numéro IBAN et accepter le mandat de prélèvement SEPA.</li>
                        </ul>
                        <p>Les frais d’envoi du dossier au Tribunal sont facturés en sus de l’abonnement et donne lieu à une facture spécifique. TVA</p>
                        <p>Les frais de greffe sont réglés par l’Utilisateur directement auprès des services concernés. TVA</p>
                        <h3>8.2 Facturation</h3>
                        <p>Le prix de souscription d’un Abonnement est facturé au moment de la souscription du puis au début de chaque mois.</p>
                        <p>Chaque facture est accessible dans votre espace sécurisé « mon Compte », sur la page « mes Factures ».</p>
                        <p>Il vous est conseillé d’imprimer et/ou d’archiver sur un support fiable et durable toute facture, à titre de preuve.</p>
                        <h2>Article 9. Vos engagements</h2>
                        <p>Vous vous engagez à toujours fournir des informations sincères et véritables au jour de leur saisie et notamment à ne pas utiliser de faux noms ou adresses, ou encore des noms ou adresses sans y être autorisé.</p>
                        <p>Vous vous engagez par ailleurs à adresser à MAC2 toutes les informations et tous les documents requis en vue de l’exécution des Services.</p>
                        <p>Vous êtes seul garant du caractère certain, liquide et exigible de votre créance, c’est-à-dire, notamment, que les factures dont vous entendez obtenir le paiement n’ont jamais fait l’objet de la moindre contestation par votre débiteur.</p>
                        <p>Vous êtes seul garant de l’authenticité de toutes les pièces justificatives prouvant le bien-fondé de la demande (bon de commande, contrat, facture impayée, lettres de relances, etc.).</p>
                        <p>Il est rappelé que votre responsabilité civile et pénale pourrait être engagée en cas de production de faux documents et/ou de communication d’informations ou d’éléments inexacts en vue du dépôt de documents administratifs ou judiciaires.</p>
                        <h2>Article 10. Nos engagements</h2>
                        <h3>10.1 Satisfaction client</h3>
                        <p>Nous mettons tout en œuvre afin de vous fournir, en toutes circonstances, un service de qualité. Si vous n’étiez toutefois pas satisfait de nos Services, n’hésitez pas à nous contacter immédiatement en nous indiquant les raisons et détails de votre insatisfaction.</p>

                        <h3>10.2 Gestion des réclamations</h3>
                        <p>Toute réclamation de votre part devra être adressée à MAC2, Services Réclamations, 3 Avenue de Mouliero, 13770 Venelles et  à l’adresse e-mail suivante : contact@facturesimpayees.com.</p>
                        <p>MAC2 ne prendra en compte que les réclamations portant sur les Services.</p>
                        <p>En conséquence, MAC2 ne pourra accueillir favorablement les réclamations portant notamment sur :</p>
                        <ul>
                            <li>le rejet de la demande d’injonction de payer</li>
                            <li>les délais de réponse de la juridiction saisie</li>
                        </ul>
                        <p>Nous nous engageons à répondre à toute réclamation dans un délai maximum de 8 jours ouvrés à compter de sa réception.</p>
                        <h2>Article 11. Prospection commerciale</h2>
                        <h3>11.1 Prospection par courrier électronique, SMS et téléphone</h3>
                        <p>Lors de la création de votre Compte, vous avez la possibilité de choisir de ne pas recevoir nos offres promotionnelles et nos newsletters en cochant la case prévue à cet effet au moment de la création de votre Compte. Vous êtes libres de modifier votre choix, à tout moment, en accédant à ces préférences dans « Mon Compte » ou en envoyant un email à notre service client à l'adresse suivante : contact@facturesimpayees.com.</p>
                        <h3>11.2 Prospection partenaires</h3>
                        <p>Conformément à la législation applicable, toute transmission de vos données personnelles (numéro de téléphone et adresse email) à des partenaires est systématiquement subordonnée à l'obtention de votre accord préalable.</p>
                        <h2>Article 12. Responsabilité de MAC2</h2>
                        <p>MAC2 fera de son mieux pour assurer l’accès au Site, la disponibilité des Services. Toutefois, du fait de la nature d'Internet, ceci ne peut être garanti. De plus, votre accès au Site ou aux Services peut occasionnellement être suspendu ou limité pour permettre des réparations, la maintenance du Site, ou ajouter une nouvelle fonctionnalité ou un nouveau service. MAC2 s’efforcera de limiter la fréquence et la durée de ces suspensions ou limitations.</p>
                        <p>Dans tous les cas, la responsabilité de MAC2 est limitée aux sommes versées par le Client (hors frais administratifs obligatoires) et encaissées par MAC2 pour le Service ayant été à l’origine du dommage du Client.</p>
                        <p>La responsabilité de MAC2 ne saurait non plus être engagée en cas de survenance d’un événement de force majeure, tel que défini à l’article 1218 du Code civil et par la jurisprudence des tribunaux français, qui empêche MAC2 de remplir ses obligations contractuelles. MAC2 informera le Client de la survenance de cet évènement dans les plus brefs délais.</p>
                        <p>Si la durée de l’événement de force majeure est supérieure à trente (30) jours consécutifs, et/ou si cet évènement, dès sa survenance, empêche définitivement MAC2 de remplir ses obligations contractuelles au titre de l’exécution du Service concerné, MAC2 et le Client seront chacune en droit de résilier l’Abonnement souscrit par voie de notification écrite adressée à l’autre partie par lettre recommandée avec accusé de réception. La résiliation prendra effet dès réception de la lettre recommandée sans engager la responsabilité de MAC2 à quelque titre que ce soit.</p>

                        <h2>Article 13. Modifications</h2>
                        <p>Nous nous réservons le droit d’apporter des changements au Site, aux Services et/ou aux présentes CGV à tout moment. Vous êtes soumis aux CGV en vigueur au moment où vous utilisez le Site et/ou vous souscrivez un Abonnement.</p>
                        <p>MAC2 vous notifiera par tout moyen et dans les meilleurs délais toute modification de la loi et/ou de la réglementation applicable affectant le Service fournit par le Site.</p>
                        <p>Si ladite modification entraîne l’impossibilité pour MAC2 de fournir le Service dans les conditions prévues dans la commande, MAC2 pourra procéder à la résiliation de ladite commande.</p>
                        <p>En pareille hypothèse, MAC2 remboursera le montant de l’Abonnement en cours au moment de la résiliation à l’exclusion des requêtes éventuellement acquises antérieurement et non encore utilisées.</p>
                        <h3>13.1 Modification des Abonnements</h3>
                        <p>Nous vous informerons en cas de modifications d'un Abonnement avec un préavis raisonnable. Vous serez en droit de ne pas accepter ces modifications et conservez la possibilité de résilier votre Abonnement avant l’entrée en vigueur de ladite modification.</p>
                        <p>En toute hypothèse, la résiliation ne pourra intervenir qu’à l’expiration du mois en cours et ne pourra ainsi donner lieu à aucun remboursement, notamment au titre des requêtes éventuellement acquises précédemment et non encore utilisées au moment de la résiliation.</p>
                        <h3>13.2 Modification du coût des Abonnements</h3>
                        <p>MAC2 se réserve le droit de modifier le coût des Abonnements indiqués sur le Site à tout moment.</p>
                        <p>Le nouveaux tarif des Abonnements sera indiqué sur le Site et notifié par e-mail à tout Utilisateur titulaire d’un Compte ouvert que celui -ci soit actif ou simplement suspendu.</p>
                        <p>Le nouveau tarif ne sera appliqué qu’aux Abonnements souscrits postérieurement à la modification tarifaire, ainsi qu’aux Abonnements renouvelés tacitement après notification par e-mail de ladite modification à l’Utilisateur.</p>
                        <h3>13.3 Modification du Site et des Services</h3>
                        <p>MAC2 se réserve la possibilité d’ajouter ou de supprimer des fonctionnalités ou des fonctions présentes sur le Site.</p>
                        <p>Ces modifications seront annoncées sur le Site et notifiées aux Utilisateurs titulaires d’un Compte ouvert que celui-ci soit actif ou suspendu.</p>
                        <p>Si lesdites modifications ont pour conséquences de modifier le contenu des Services fournis par le Site, tout Utilisateur pourra solliciter la résiliation de l’Abonnement sans frais ni pénalité.</p>

                        <p>Il est ici rappelé qu’en tout hypothèse, la résiliation :</p>
                        <ul>
                            <li>ne peut intervenir que pour le mois à venir car tout mois commencé est dû ;</li>
                            <li>ne peut donner lieu aux remboursement du coût d’acquisition des requêtes non utilisées à la date de résiliation de l’Abonnement.</li>
                        </ul>
                        <h2>Article 14. Confidentialité et protection des données personnelles</h2>
                        <p>Pour davantage d’informations concernant l’utilisation de données à caractère personnel par MAC2, veuillez lire attentivement nos conditions générales d’utilisation du Site.</p>
                        <h2>Article 15. Propriété intellectuelle et industrielle</h2>
                        <p>La marque « Facturesimpayees.com », ainsi que l'ensemble des marques figuratives ou non et plus généralement toutes les autres marques, illustrations, images et logotypes (ci-après les « Marques ») figurant sur le Site, qu'ils soient déposés ou non sont et demeurent la propriété exclusive de MAC2.</p>
                        <p>Le Site et tous les éléments graphiques, textuels, visuels et photographiques y figurant, à savoir notamment toutes les illustrations, images, dessins, photographies, caractères, textes, décors, modes de présentation, graphisme, Contenu, Modèles ou tout autre élément du Site (ci-après le »Contenu du Site ») sont et demeurent la propriété exclusive de MAC2 et sont protégés notamment par des droits de propriété intellectuelle. </p>
                        <p>Les Marques, le Site et/ou le Contenu du Site ne peuvent en aucun cas être en tout ou partie modifiés, reproduits, représentés, distribués, affichés, commercialisés, intégrés dans une œuvre dérivée ou autre, et ce sur quelque support que ce soit. De manière générale, les Marques, le Site et/ou le Contenu du Site ne peuvent être utilisés que dans le cadre d’une navigation sur le Site et, le cas échéant, de l’utilisation de nos Services. </p>
                        <p>L’usage de tout ou partie des Marques, le Site et/ou le Contenu du Site, notamment par téléchargement, reproduction, transmission ou représentation à d’autres fins que celles prévues aux présentes CGV est strictement interdit.</p>
                        <h2>Article 16. Divisibilité des clauses</h2>
                        <p>La nullité d'une des clauses des présentes n'entraîne pas la nullité des présentes CGV, les autres clauses continueront à produire leurs effets.</p>
                        <h2>Article 17. Renonciation</h2>
                        <p>Le fait, pour MAC2, de ne pas se prévaloir à titre temporaire ou permanent d’une ou plusieurs clauses des présentes CGV, n’emportera en aucun cas renonciation à se prévaloir du reste des dites CGV.</p>
                        <h2>Article 18. Langue</h2>
                        <p>Les présentes CGV sont rédigées ainsi que l'ensemble des informations contractuelles mentionnées sur le site en langue française.</p>
                        <h2>Article 21. Loi applicable et juridiction compétente.</h2>
                        <p>Les présentes CGUV sont soumises au droit français.</p>
                        <p>En cas de contestation, litige ou action relative à ou trouvant son origine dans l’interprétation, l’exécution ou la résiliation des présentes CGV et/ou la conclusion, l’exécution ou la résiliation d’un Abonnement ou encore plus généralement de l’utilisation du Site et/ou d’un Service qu’il propose, les parties s'engagent à tenter de régler leur différend à l'amiable avant toute action judiciaire. </p>

                        <p>En conséquence, à peine d’irrecevabilité, aucune action judiciaire ne pourra être engagée par une partie,  sans avoir été précédée d’une mise en demeure adressée sous pli recommandé avec demande d’avis de réception à l’autre partie de faire cesser la situation dont elle estime être victime et/ou de la rétablir dans ses droits, demeurée sans réponse à l’expiration d’un délai qui ne saurait être inférieur à vingt jours ouvrés.</p>
                        <p>Si aucune solution amiable n'était trouvée dans le délai imparti, les parties peuvent proroger ledit délai ou saisir la justice pour voir régler leur différend.</p>
                        <p>Dans cette dernière hypothèse, les parties conviennent que le litige, lorsqu’il opposera MAC2 à une entreprise commerciale,  sera porté exclusivement devant le Tribunal de commerce de Paris.</p>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
}
