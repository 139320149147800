import React from "react";

class Btn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }


    render() {

        if (this.props.type === "submit") {
            return (
                <input value={this.props.texte} type={"submit"}
                       className={(this.props.theme === "dark" ? "btn-gris" : "btn-violet") + " btn-custom d-flex justify-content-center align-item-center " + (this.props.className ? this.props.className : "") + (this.props.iconeDroite ? this.props.iconeDroite : "")}/>
            );
        } else {
            return (
                <a onClick={() => {
                    if (this.props.loading) {
                        if (!this.state.loading) {
                            this.setState({loading: true});
                            if (this.props.onClick) {
                                this.props.onClick(() => {
                                    this.setState({loading: false});
                                });
                            }
                        }
                    } else {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }
                    }

                }} target={this.props.target} href={this.props.href}
                   className={(this.props.theme === "dark" ? "btn-gris" : "btn-violet") + " btn-custom d-flex justify-content-center align-item-center text-center " + (this.props.className ? this.props.className : "") + (this.state.loading ? " btn-loading" : "")}>
                    {this.props.iconeGauche && !this.state.loading ?
                        <div className={"me-3 d-flex justify-content-end align-items-center icone-gauche"}><img
                            alt="icone fleche" src={"/assets/img/" + this.props.iconeGauche + ".png"} width={"18"}/>
                        </div> : null}
                    {this.props.texte}
                    {this.props.iconeDroite && !this.state.loading ?
                        <div className={"ms-3 d-flex justify-content-end align-items-center icone-droite"}><img
                            alt="icone fleche" src={"/assets/img/" + this.props.iconeDroite + ".png"} width={"18"}/>
                        </div> : null}
                </a>
            );
        }
    }
}

export default Btn;

