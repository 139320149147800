import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getProof} from "../class/Requete";

export default function PdfAvisReception() {
    let {id} = useParams();
    const [imgBase64, setImgBase64] = useState("");
    //let [urlPdf, setUrlPdf] = useState("");
    useEffect(() => {
        let getData = async () => {
            //if(id!="undefined"){
            let proof = await getProof(id);
            console.log(proof.document);

            let b64Data=proof.document;
            setImgBase64(b64Data);
            /*const byteCharacters = atob(b64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            proof.document = "";

            let blob = new Blob([byteArray], {type: "image/jpeg"});
            let urlBlob = URL.createObjectURL(blob);
            setUrlPdf(urlBlob);*/
            //}
        }
        getData();
    }, [id])

    return (
        <div className={"container-fluid d-flex justify-content-center align-content-center p-0 m-0 flex-column"}>
            {imgBase64?<img src={`data:image/jpeg;base64,`+imgBase64} alt="Chargement en cours ..." />:<div className={"text-center mt-2 p-4"}>Nous n'avons toujours pas reçu votre accusé de réception.<br />
                <br />
                En règle générale, l’accusé de réception de vos envois sera disponible dans un délai variant de 5 à 15 jours. Cela peut parfois prendre un peu plus de temps.<br />
                <br />
                Toutefois, si vous n’avez toujours rien reçu au-delà d’un délai de 20 jours, contactez-nous. Nous solliciterons pour vous votre accusé de réception auprès de La Poste.<br />
            </div>}
        </div>
    )
}
